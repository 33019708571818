import { Col, Form, FormInstance, Input, Row } from "antd";
import { PropertyDeviceDetail, PropertyDeviceInput } from "src/types";

const NumberProperty: React.FC<{
  view?: boolean;
  property?: PropertyDeviceDetail;
  edit?: PropertyDeviceInput;
  form?: FormInstance<any> | undefined;
}> = (props) => {
  if (props.view) {
    return (
      <>
        {props.property?.value_n}
      </>
    );
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Form.Item
          name="key"
          label="Key"
          style={{ margin: 0 }}
          rules={[
            { required: true, message: "Please input key!" },
            {
              min: 2,
              message: "Length min of 2 characters",
            },
            {
              max: 30,
              message: "Length max of 30 characters",
            },
            {
              whitespace: true,
              message: "Cannot enter spaces",
            },
            {
              type: "regexp",
              pattern: new RegExp("([a-zA-Z]{3,30}\\s*)+"),
              message: "Key using not space and symbol",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="value"
          label="Value"
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please input value!" }]}
        >
          <Input type={"number"} size="large" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NumberProperty;

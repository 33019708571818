import { Routes, Route } from 'react-router-dom'
import React from "react";
import MainLayout from '../../components/layout/main-layout';
import ListNamespacePage from './list-namespace.page';

type P = {

}

export function NamespaceRouter(props: P) {
    return (
        <MainLayout>
            <Routes>
                <Route path={'/'} element={<ListNamespacePage />} />
                <Route path={'/:id'} element={<ListNamespacePage />} />
            </Routes>
        </MainLayout>
    )
}

export default NamespaceRouter
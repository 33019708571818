import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { QueryDataShowWidget } from "src/modules/widget/components/widget";
import { WidgetDetail } from "src/types";
import "./widget-box.css";
import { useElementSize } from 'usehooks-ts'

const WidgetBoxGrid: React.FC<{
  widget?: WidgetDetail;
  refWidget?: (callback: (detail: WidgetDetail) => void) => void;
  label: string;
  children?: any;
}> = (props) => {
  const [squareRef, ref ] = useElementSize()
  const [widgetData, setWidget] = useState<WidgetDetail | null>(null);
  const { refWidget, widget } = props;

  useEffect(() => {
    if (widget) {
      setWidget(widget);
    }
  }, [widget]);

  useEffect(() => {
    if (refWidget) {
      refWidget((detail) => {
        setWidget(detail);
      });
    }
  }, [refWidget]);

  return (
    <div ref={squareRef} style={{ width: "100%", height: "100%" }}>
      <Card className={"box"}>
        <h4 className="title"> {props.label || ""}</h4>
        <div className={"chart"}>
          {ref.height && widgetData && (
            <QueryDataShowWidget
              widget={widgetData}
              height={(ref.height? ref.height - 70 : 0)}
              width={(ref.width ? ref.width - 60 : 0) }
            />
          )}
        </div>
        {props.children}
      </Card>
    </div>
  );
};

export default WidgetBoxGrid;

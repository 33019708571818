import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { PropertyDeviceDetail } from 'src/types'
import { getPropertyDeviceByIdThunkAction } from './thunk'

export const PropertyDeviceSlice = createSlice({
    name: 'property_device',
    initialState: {
        loading: false,
        message: "",
        list: [] as PropertyDeviceDetail[],
    },
    reducers: {
      
    },
    extraReducers: builder => {
        builder.addCase(getPropertyDeviceByIdThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
            state.list = []
        })
        builder.addCase(getPropertyDeviceByIdThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getPropertyDeviceByIdThunkAction.fulfilled, (state, action) => {
            if (action.payload.getPropertyDeviceById != null) {
                let list =  action.payload.getPropertyDeviceById?.data as  PropertyDeviceDetail[]
                state.list = list
            } else {
                state.list = []
            }
            state.loading = false
        })
    }
})

export default PropertyDeviceSlice.reducer
// export const { } = PropertyDeviceSlice.actions


// select
export const selectPropertyDevice = (state: RootState) => state.property_device
export const selectLoading = createSelector(selectPropertyDevice,(state) => state.loading )
export const selectMessage = createSelector(selectPropertyDevice,(state) => state.message )
export const selectListPropertyDevice = createSelector(selectPropertyDevice,(state) => state.list )
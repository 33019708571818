import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"
import { InputRuleChan, ListPaginationRuleChanInput } from 'src/types'
import { RuleChanDocument, RuleChanQuery, RuleChanQueryVariables } from "./graph/list_rule_chan.generated";
import { GetRuleChanDocument, GetRuleChanQuery, GetRuleChanQueryVariables } from "./graph/get_rule_chan.generated";
import { CreateRuleChanDocument, CreateRuleChanMutation, CreateRuleChanMutationVariables } from "./graph/create_rule_chan.generated";
import { UpdateRuleChanDocument, UpdateRuleChanMutation, UpdateRuleChanMutationVariables } from "./graph/update_rule_chan.generated";
import { UpdateRuleChanNodeDocument, UpdateRuleChanNodeMutation, UpdateRuleChanNodeMutationVariables } from "./graph/update_rule_node.generated";
import { DeleteRuleChanDocument, DeleteRuleChanMutation, DeleteRuleChanMutationVariables } from "./graph/delete_rule_chan.generated";
import { UpdateRuleChanNodesDocument, UpdateRuleChanNodesMutation, UpdateRuleChanNodesMutationVariables } from "./graph/updateRuleChanNodes.generated";

export const getListPaginationRuleChanThunkAction = createAsyncThunk<
    RuleChanQuery,
    ListPaginationRuleChanInput,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/getListPaginationRuleChanThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<RuleChanQueryVariables, RuleChanQuery>(RuleChanDocument, { input: data })
        return res;
    }
)

export const getDetailRuleChanThunkAction = createAsyncThunk<
    GetRuleChanQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/getDetailRuleChanThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetRuleChanQueryVariables, GetRuleChanQuery>(GetRuleChanDocument, { id: id})
        return res;
    }
)

export const createRuleChanThunkAction = createAsyncThunk<
    CreateRuleChanMutation,
    InputRuleChan,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/createRuleChanThunkAction',
    async (input, { extra }) => {
        const res = await extra.client.mutate<CreateRuleChanMutationVariables, CreateRuleChanMutation>(CreateRuleChanDocument, { input: input })
        return res;
    }
)

export const updateRuleChanThunkAction = createAsyncThunk<
    UpdateRuleChanMutation,
    UpdateRuleChanMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/updateRuleChanThunkAction',
    async (input, { extra }) => {
        const res = await extra.client.mutate<UpdateRuleChanMutationVariables, UpdateRuleChanMutation>(UpdateRuleChanDocument, input)
        return res;
    }
)

export const updateRuleNodeThunkAction = createAsyncThunk<
    UpdateRuleChanNodeMutation,
    UpdateRuleChanNodeMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/updateRuleChanThunkAction',
    async (input, { extra }) => {
        const res = await extra.client.mutate<UpdateRuleChanNodeMutationVariables, UpdateRuleChanNodeMutation>(UpdateRuleChanNodeDocument, input)
        return res;
    }
)

export const updateRuleNodesThunkAction = createAsyncThunk<
    UpdateRuleChanNodesMutation,
    UpdateRuleChanNodesMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/updateRuleChanThunkAction',
    async (input, { extra }) => {
        const res = await extra.client.mutate<UpdateRuleChanNodesMutationVariables, UpdateRuleChanNodesMutation>(UpdateRuleChanNodesDocument, input)
        return res;
    }
)

export const deleteRuleChanThunkAction = createAsyncThunk<
    DeleteRuleChanMutation,
    Number,
    { extra: { client: GraphQLClient } }
>(
    'rule_chan/deleteRuleChanThunkAction',
    async (input, { extra }) => {
        const res = await extra.client.mutate<DeleteRuleChanMutationVariables, DeleteRuleChanMutation>(DeleteRuleChanDocument, { chanId: input })
        return res;
    }
)






// export const getModelLogLastTimeThunkAction = createAsyncThunk<
//     GetModelLogLastTimeQuery,
//     LastTimeModelInput,
//     { extra: { client: GraphQLClient } }
// >(
//     'model_log/getModelLogLastTimeThunkAction',
//     async (data, { extra }) => {
//         const res = await extra.client.mutate<GetModelLogLastTimeQueryVariables, GetModelLogLastTimeQuery>(GetModelLogLastTimeDocument, { input: data })
//         return res;
//     }
// )
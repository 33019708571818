import { Routes, Route, useLocation } from "react-router-dom";
import React from "react";
import MainLayout from "../../components/layout/main-layout";
import ListUiPage from "./list-ui.page";
import WidgetPage from "./widget.page";
import EditUiPage from "./edit-ui.page";
import ShowUiPage from "./show-ui.page";
import NewUiPage from "./new-ui.page";
import PreviewWidget from "./preview-widget.page";

type P = {};

export function UiRouter(props: P) {
  let localtion = useLocation();
  
  return (
    <MainLayout hide_footer={localtion.pathname.includes("widgets")}>
      <Routes>
        <Route path={"/"} element={<ListUiPage />} />
        <Route path={"/widgets"} element={<WidgetPage />} />
        <Route path={"/widgets/edit"} element={<PreviewWidget />} />
        <Route path={"/:id/edit"} element={<EditUiPage />} />
        <Route path={"/:id"} element={<ShowUiPage />} />
        <Route path={"/new"} element={<NewUiPage />} />
      </Routes>
    </MainLayout>
  );
}

export default UiRouter;

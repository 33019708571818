import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { ListPaginationModelInput, ModelInput } from 'src/types'

import { GetListPaginationModelDocument, GetListPaginationModelQuery, GetListPaginationModelQueryVariables } from './graph/getListPaginationModel.generated'
import { GetModelByIdDocument, GetModelByIdQuery, GetModelByIdQueryVariables } from './graph/getModelById.generated'
import { CreateModelDocument, CreateModelMutation, CreateModelMutationVariables } from './graph/createModel.generated'
import { UpdateModelDocument, UpdateModelMutation, UpdateModelMutationVariables } from './graph/updateModel.generated'
import { DeleteModelDocument, DeleteModelMutation, DeleteModelMutationVariables } from './graph/deleteModel.generated'

export const getListPaginationModelThunkAction = createAsyncThunk<
    GetListPaginationModelQuery,
    ListPaginationModelInput,
    { extra: { client: GraphQLClient } }
>(
    'namespace/getListPaginationModelThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationModelQueryVariables, GetListPaginationModelQuery>(GetListPaginationModelDocument, { input: data })
        return res;
    }
)

export const getSearchModelThunkAction = createAsyncThunk<
    GetListPaginationModelQuery,
    ListPaginationModelInput,
    { extra: { client: GraphQLClient } }
>(
    'namespace/getSearchModelThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationModelQueryVariables, GetListPaginationModelQuery>(GetListPaginationModelDocument, { input: data })
        return res;
    }
)


export const getModelByIdThunkAction = createAsyncThunk<
    GetModelByIdQuery,
    any,
    { extra: { client: GraphQLClient } }
>(
    'namespace/getModelByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetModelByIdQueryVariables, GetModelByIdQuery>(GetModelByIdDocument, { id: id })
        return res;
    }
)


export const createModelThunkAction = createAsyncThunk<
    CreateModelMutation,
    ModelInput,
    { extra: { client: GraphQLClient } }
>(
    'namespace/createModelThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateModelMutationVariables, CreateModelMutation>(CreateModelDocument, { input: data })
        return res;
    }
)

export const updateModelThunkAction = createAsyncThunk<
    UpdateModelMutation,
    UpdateModelMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'namespace/updateModelThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateModelMutationVariables, UpdateModelMutation>(UpdateModelDocument, data)
        return res;
    }
)

export const deleteModelThunkAction = createAsyncThunk<
    DeleteModelMutation,
    number,
    { extra: { client: GraphQLClient } }
>(
    'namespace/deleteModelThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<DeleteModelMutationVariables, DeleteModelMutation>(DeleteModelDocument, { id: data })
        return res;
    }
)


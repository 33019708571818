import { Badge, Button, Col, Dropdown, Row, Space } from "antd";
import Title from "antd/lib/typography/Title";
import Logo from "../logo";
import { AppstoreOutlined, BellOutlined } from "@ant-design/icons";
import MenuAppStore from "./menu-app-store";
import { useState } from "react";


type P = {

};

const ToolbarApp: React.FC<P> = (props: P) => {

  const [store, setStore] = useState(false)


  return (
    <div id="toolbarApp">
      <div className="toool-left">
        <Space id="box-logo" align="center" direction="horizontal" size={0} >
          <Logo className="logo-icon" height="40px" width="40px"></Logo>
          <Title id="title-main">Overchall Hub</Title>
        </Space>
      </div>
      <div className="tool-right">
        <Space size={0} align="center" >
          <Row gutter={[15, 0]}>
            <Col>
              <Badge dot={true} size="default">
                <Button type="default" color="#1976d2" size="large" shape="circle">
                  <BellOutlined />
                </Button>
              </Badge>
            </Col>
            <Col>
              <Dropdown overlay={<MenuAppStore close={() => {setStore(false)}} />} trigger={['click']} visible={store} onVisibleChange={(val) => { setStore(val) }}>
                <Button type="default" color="#1976d2" size="large" shape="circle" onClick={e => e.preventDefault()}>
                  <AppstoreOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>

        </Space>
      </div>
    </div>
  );
};

export default ToolbarApp;

import { Col, DatePicker, Form, Row } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import { selectFilterLog, setFilterLog } from "../slice";
import SelectModel from "src/modules/model/components/select-model";
import SelectDevice2 from "src/modules/device/components/select-device2";
import SelectAlert from "./select-alert";

const { RangePicker } = DatePicker;

const FilterLog: React.FC<{
  filterDevice?: boolean
  filterModel?: boolean
  filterAlert?: boolean
}> = (props) => {
  const [formData] = Form.useForm<any>();
  const filter = useSelector(selectFilterLog);
  const dispatch = useAppDispatch();

  const onOk = (value: RangePickerProps["value"]) => {
    if (value != null) {
      const [startTime,endTime] = value;
      if (startTime && endTime ) {
        dispatch<any>(
          setFilterLog({
            ...filter,
            start_time: startTime,
            end_time: endTime,
          })
        );
      }
    
    }
  };

  useEffect(() => {
    formData.setFields([
      {
        name: "device_id",
        value: filter.device_id,
      },
      {
        name: "model_id",
        value: filter.model_id,
      },
      {
        name: "alert_id",
        value: filter.alert_id,
      },
    ]);
  }, [filter, formData])

  // const onChangeDevice = (deviceId: string) => {
  //   dispatch(
  //     setFilterLog({
  //       ...filter,
  //       device_id: Number(deviceId),
  //     })
  //   );
  // };

  return (
    <Form form={formData} style={{ marginBottom: "20px" }}>
      <Row gutter={[10, 10]}>

        {
          (props.filterDevice) && (
            <Col span={12}>
              <SelectDevice2
                name={"device_id"}
                disabled={false}
              />
            </Col>
          )
        }
        {
          (props.filterModel) && (
            <Col span={12}>
              <SelectModel
                name={"model_id"}
                disabled={false}
              />
            </Col>
          )
        }
        {
          (props.filterAlert) && (
            <Col span={12}>
              <SelectAlert
                name={"alert_id"}
                disabled={false}
              />
            </Col>
          )
        }
        
        <Col span={12}>
          <RangePicker
            value={[filter.start_time, filter.end_time]}
            size="large"
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            onOk={onOk}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FilterLog;

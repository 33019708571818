import { Typography, Alert, Button, Col, Form, Input, Row, Spin } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import SelectModel from "src/modules/model/components/select-model";
import { RuleInfo, RuleOptionProps } from "../rule";
import { useEffect } from "react";
import Title from "antd/lib/typography/Title";


const { Text } = Typography;

export const GetLastModelRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
              <Handle
                type="target"
                position={Position.Left}
                style={{ background: 'red' }}
                id="input"
                title="input"
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Get Last Model</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                title="output"
                style={{ background: 'green' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const GetLastModelRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        let modelId = ""
        let key = ""
        if (props.data && props.data.model_id) {
            modelId = props.data.model_id
        } 
        if (props.data && props.data.key) {
            key = props.data.key
        } 
        form.setFields([
            {
              name: "type",
              value: "Input Model",
            },
            {
                name: "name",
                value: props.label,
            },
            {
                name: "model_id",
                value: modelId,
            },
            {
                name: "key",
                value: key,
            },
          ])
    }, [props, form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {
                model_id: values.model_id,
                key: values.key,
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <SelectModel name="model_id" disabled={false} />
            </Col>
            <Col span={24}>
                <Form.Item name="key" label="Key Output" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const GetLastModelRuleInfo: RuleInfo = {
    id: "get-last-model",
    name: "Get Last Model",
    rule: GetLastModelRule,
    option: GetLastModelRuleOption,
    infinite: false,
    option_default: {
        model_id: "",
        key: ""
    },
}

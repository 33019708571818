import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { ListPaginationNamespace, ListPaginationNamespaceInput, Namespace } from 'src/types'
import { deleteNamespaceThunkAction, getListPaginationNamespaceThunkAction, getSearchNamespaceThunkAction, getNamespaceByIdThunkAction } from './thunk'

export const NamespaceSlice = createSlice({
    name: 'namespace',
    initialState: {
        loading: false,
        message: "",
        list: {
            data: [] as any[]
        } as ListPaginationNamespace,
        search: {
            data: [] as any[]
        } as ListPaginationNamespace,
        detail: null as Namespace | null,
        filter: {
            limit: 50,
            skip: 0,
        } as ListPaginationNamespaceInput
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationNamespaceInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(getListPaginationNamespaceThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(getListPaginationNamespaceThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationNamespaceThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.list = action.payload.getListPaginationNamespace as ListPaginationNamespace
        })
        builder.addCase(deleteNamespaceThunkAction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getNamespaceByIdThunkAction.fulfilled, (state, action) => {
            state.detail = action.payload.getNamespaceById as Namespace
        })
        builder.addCase(deleteNamespaceThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(deleteNamespaceThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getSearchNamespaceThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.search = action.payload.getListPaginationNamespace as ListPaginationNamespace
        })
    }
})

export default NamespaceSlice.reducer
export const { setFilter } = NamespaceSlice.actions


// select
export const selectNamespace = (state: RootState) => state.namespace
export const selectLoading = createSelector(selectNamespace,(state) => state.loading )
export const selectMessage = createSelector(selectNamespace,(state) => state.message )
export const selectFilter = createSelector(selectNamespace,(state) => state.filter )
export const selectListNamespace = createSelector(selectNamespace,(state) => state.list )
export const selectListNamespaceSearch = createSelector(selectNamespace,(state) => state.search.data )
export const selectDetail = createSelector(selectNamespace,(state) => state.detail )
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRuleChanMutationVariables = Types.Exact<{
  chanId: Types.Scalars['Uint64'];
}>;


export type DeleteRuleChanMutation = { __typename?: 'Mutation', deleteRuleChan: boolean };


export const DeleteRuleChanDocument = gql`
    mutation deleteRuleChan($chanId: Uint64!) {
  deleteRuleChan(id: $chanId)
}
    `;
export type DeleteRuleChanMutationFn = Apollo.MutationFunction<DeleteRuleChanMutation, DeleteRuleChanMutationVariables>;

/**
 * __useDeleteRuleChanMutation__
 *
 * To run a mutation, you first call `useDeleteRuleChanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRuleChanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRuleChanMutation, { data, loading, error }] = useDeleteRuleChanMutation({
 *   variables: {
 *      chanId: // value for 'chanId'
 *   },
 * });
 */
export function useDeleteRuleChanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRuleChanMutation, DeleteRuleChanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRuleChanMutation, DeleteRuleChanMutationVariables>(DeleteRuleChanDocument, options);
      }
export type DeleteRuleChanMutationHookResult = ReturnType<typeof useDeleteRuleChanMutation>;
export type DeleteRuleChanMutationResult = Apollo.MutationResult<DeleteRuleChanMutation>;
export type DeleteRuleChanMutationOptions = Apollo.BaseMutationOptions<DeleteRuleChanMutation, DeleteRuleChanMutationVariables>;
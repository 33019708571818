import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Tag } from "antd";
import { isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectFilter, setFilter } from "../slice";

const FilterDevice: React.FC<{}> = (props) => {
  const filter = useAppSelector(selectFilter);
  const dispatch = useAppDispatch();
  const [formData] = Form.useForm<any>();
  const [styleInput, setStyleInput] = useState<any>({});
  const [showAdd, setShowAdd] = useState(false);

  const onSearch: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const search = formData.getFieldValue("search");
    if (event.keyCode === 13) {
      dispatch(setFilter({ ...filter, search }));
    }
    getStyle(search);
  };

  const getStyle = (search: string) => {
    if (!(isUndefined(filter.search) && search === "")) {
      if (filter.search !== search) {
        setStyleInput({ background: "#ffb6b6" });
        return;
      }
    }
    setStyleInput({});
  };

  useEffect(() => {
    getStyle(formData.getFieldValue("search"));

    formData.setFields([
      {
        name: "namespaces",
        value: "",
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleOkFilter = () => {
    const namespaces = formData.getFieldValue("namespaces");
    const change: any = {};
    if (namespaces !== "") {
      change["namespaces"] = namespaces;
    }
    dispatch(setFilter({ ...filter, ...change }));
    setShowAdd(false);
  };

  const clearFilter = (name: string) => {
    dispatch(setFilter({ ...filter, [name]: undefined }));
  };

  return (
    <Form form={formData}>
      <Row gutter={10}>
        <Col hidden={!filter.namespaces}>
          {filter.namespaces && (
            <Tag color={"green"} key="namespaces">
              Namespaces: {filter.namespaces}
              <Button
                type="link"
                style={{ height: 30}}
                icon={<CloseOutlined size={1} />}
                onClick={() => {
                  clearFilter("namespaces");
                }}
              ></Button>
            </Tag>
          )}
        </Col>
        <Col>
          <Form.Item name="search" style={{ margin: 0 }}>
            <Input placeholder="Search" onKeyUp={onSearch} style={styleInput} />
          </Form.Item>
        </Col>
        <Col>
          <Button
            type="default"
            onClick={() => {
              setShowAdd(true);
            }}
          >
            Add Filter
          </Button>
        </Col>
      </Row>
      <Modal
        key={"add-filter-device"}
        title="Add Filter"
        visible={showAdd}
        width={300}
        onOk={handleOkFilter}
        onCancel={() => {
          setShowAdd(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item name={"namespaces"}>
              <Select placeholder={"Namespaces"}>
                <Select.Option key="default" value={"default"}>
                  default
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default FilterDevice;

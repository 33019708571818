import { NodeProps } from "reactflow"


export interface RuleOptionProps  {
    id: String
    label: String
    data: any
    save: (id: String, data: { option: any, name: string }) => void
    cancel: () => void
}

export interface RuleSelect {
    data: any
    id: String
    type: String
    position : {
        x: Number,
        y: Number,
    }
    links: LinkNode[]
}

export interface LinkNode {
    id: string
    node: string
    type_node?: string
    type: string
    key: string
}

export interface TabOption {
    data: any
    id: String
    type: String
}

export interface RuleInfo {
    id: string
    name: string
    infinite: boolean,
    option_default: any,
    rule: React.FC<NodeProps>
    option: React.FC<RuleOptionProps>
    tabs?: {
        tab_name: string,
        tab:  React.FC<TabOption>
    }[]
}

export function makeNodeId() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 10) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export interface NodePosition {
    position: Position
}
export interface Position {
    x: Number,
    y: Number,
}


export const getNewPosition = (list: NodePosition[]) => {
    
}

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationUiQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListPaginationUiInput>;
}>;


export type GetListPaginationUiQuery = { __typename?: 'Query', getListPaginationUi: { __typename?: 'ListPaginationUi', limit: number, skip: number, total: number, data?: Array<{ __typename?: 'Ui', id: any, name: string, type: string, is_home: boolean, created_at: any, updated_at: any }> | null } };


export const GetListPaginationUiDocument = gql`
    query getListPaginationUi($input: ListPaginationUiInput) {
  getListPaginationUi(input: $input) {
    limit
    skip
    total
    data {
      id
      name
      type
      is_home
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useGetListPaginationUiQuery__
 *
 * To run a query within a React component, call `useGetListPaginationUiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationUiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationUiQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationUiQuery(baseOptions?: Apollo.QueryHookOptions<GetListPaginationUiQuery, GetListPaginationUiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationUiQuery, GetListPaginationUiQueryVariables>(GetListPaginationUiDocument, options);
      }
export function useGetListPaginationUiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationUiQuery, GetListPaginationUiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationUiQuery, GetListPaginationUiQueryVariables>(GetListPaginationUiDocument, options);
        }
export type GetListPaginationUiQueryHookResult = ReturnType<typeof useGetListPaginationUiQuery>;
export type GetListPaginationUiLazyQueryHookResult = ReturnType<typeof useGetListPaginationUiLazyQuery>;
export type GetListPaginationUiQueryResult = Apollo.QueryResult<GetListPaginationUiQuery, GetListPaginationUiQueryVariables>;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { PropertyDeviceInput } from 'src/types'

import { GetPropertyDeviceByIdDocument, GetPropertyDeviceByIdQuery, GetPropertyDeviceByIdQueryVariables } from './graph/getPropertyDeviceById.generated'
import { CreatePropertyDeviceDocument, CreatePropertyDeviceMutation, CreatePropertyDeviceMutationVariables } from './graph/createPropertyDevice.generated'
import { UpdatePropertyDeviceDocument, UpdatePropertyDeviceMutation, UpdatePropertyDeviceMutationVariables } from './graph/updatePropertyDevice.generated'
import { DeletePropertyDeviceDocument, DeletePropertyDeviceMutation, DeletePropertyDeviceMutationVariables } from './graph/deletePropertyDevice.generated'


export const getPropertyDeviceByIdThunkAction = createAsyncThunk<
    GetPropertyDeviceByIdQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'PropertyDevice/getPropertyDeviceByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetPropertyDeviceByIdQueryVariables, GetPropertyDeviceByIdQuery>(GetPropertyDeviceByIdDocument, { id: id })
        return res;
    }
)


export const createPropertyDeviceThunkAction = createAsyncThunk<
    CreatePropertyDeviceMutation,
    PropertyDeviceInput,
    { extra: { client: GraphQLClient } }
>(
    'PropertyDevice/createPropertyDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreatePropertyDeviceMutationVariables, CreatePropertyDeviceMutation>(CreatePropertyDeviceDocument, { input: data })
        return res;
    }
)

export const updatePropertyDeviceThunkAction = createAsyncThunk<
    UpdatePropertyDeviceMutation,
    UpdatePropertyDeviceMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'PropertyDevice/updatePropertyDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdatePropertyDeviceMutationVariables, UpdatePropertyDeviceMutation>(UpdatePropertyDeviceDocument, data)
        return res;
    }
)

export const deletePropertyDeviceThunkAction = createAsyncThunk<
    DeletePropertyDeviceMutation,
    { id: string, deviceId: string},
    { extra: { client: GraphQLClient } }
>(
    'PropertyDevice/deletePropertyDeviceThunkAction',
    async ({ id,  deviceId }, { extra }) => {
        const res = await extra.client.mutate<DeletePropertyDeviceMutationVariables, DeletePropertyDeviceMutation>(DeletePropertyDeviceDocument, { id: id, deviceId: deviceId })
        return res;
    }
)


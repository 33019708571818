import { ApolloClient, DocumentNode, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { DeviceType } from "src/types";

type Query = <QV, RT>(query: DocumentNode, variables?: QV) => Promise<RT>;
type Mutate = <MV, RT>(mutation: DocumentNode, variables?: MV) => Promise<RT>;

export type GraphQLClient = {
  query: Query;
  mutate: Mutate;
  client: ApolloClient<NormalizedCacheObject>;
};


const cache = new InMemoryCache({
  addTypename: false,
  resultCaching: false,
});

const { REACT_APP_API_ENDPOINT, NODE_ENV  } = process.env;

let ENDPOINT = REACT_APP_API_ENDPOINT;
if (NODE_ENV === "production") {
  ENDPOINT = "";
}

export const setToken = (token: string, deviceId: string) => {

  localStorage.setItem("token", token)

  client = new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    uri: `${ENDPOINT}/v1/gateway-graphql/graphql`,
    headers: {
      Authorization: token,
      device_id: deviceId,
      device: DeviceType.Web,
    },
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  });

}

var client = new ApolloClient({ cache: cache })

export const createGQLClient = (): GraphQLClient => {
  
  let token = localStorage.getItem("token")
  if (token == null) {
    token = ""
  }

  let deviceId = localStorage.getItem("device_id")
  if (token == null) {
    deviceId =  ""
  }
  
  client = new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    uri: `${ENDPOINT}/v1/gateway-graphql/graphql`,
    headers: {
      Authorization: token,
      device_id: deviceId as string,
      device: DeviceType.Web,
    },
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  const query: Query = (query, variables: any) => {
    return client
      .query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => data);
  };

  const mutate: Mutate = (mutation, variables: any) => {
    return client
      .mutate({
        mutation,
        variables,
      })
      .then(({ data }) => data);
  };

  return { query, mutate, client };
};

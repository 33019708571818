export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Int64: any;
  Map: any;
  Time: any;
  Timestamp: any;
  Uint64: any;
};

export type Action = {
  __typename?: 'Action';
  action: Scalars['String'];
  example?: Maybe<Scalars['String']>;
  params?: Maybe<Array<Maybe<Param>>>;
};

export type ActionDeviceInput = {
  name: Scalars['String'];
  params: Scalars['Map'];
};

export type Alert = {
  __typename?: 'Alert';
  action: Scalars['String'];
  created_at: Scalars['Time'];
  created_by_user?: Maybe<UserBaseView>;
  description?: Maybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  id: Scalars['Uint64'];
  message_template: Scalars['String'];
  model?: Maybe<Model>;
  model_id: Scalars['Uint64'];
  name: Scalars['String'];
  rules?: Maybe<Array<Scalars['String']>>;
  updated_at: Scalars['Time'];
  user_id?: Maybe<Scalars['Uint64']>;
};

export type AlertLog = {
  __typename?: 'AlertLog';
  action_status: Scalars['String'];
  alert?: Maybe<Alert>;
  alert_id: Scalars['Uint64'];
  device?: Maybe<Device>;
  device_id: Scalars['Uint64'];
  id: Scalars['Uint64'];
  message: Scalars['String'];
  model?: Maybe<Model>;
  model_id: Scalars['Uint64'];
  source: Scalars['String'];
  ts: Scalars['Time'];
};

export type AllWidget = {
  __typename?: 'AllWidget';
  data?: Maybe<Array<Widget>>;
};

export type AssignModelDevice = {
  __typename?: 'AssignModelDevice';
  assign_id: Scalars['Uint64'];
  created_at: Scalars['Time'];
  created_by?: Maybe<Scalars['Uint64']>;
  device?: Maybe<Device>;
  device_id: Scalars['Uint64'];
  model?: Maybe<Model>;
  model_id: Scalars['Uint64'];
  status?: Maybe<StatusAssign>;
  updated_at: Scalars['Time'];
  updated_by?: Maybe<Scalars['Uint64']>;
};

export type AssignModelInput = {
  device_id: Scalars['Uint64'];
  model_id: Scalars['Uint64'];
  status?: InputMaybe<StatusAssign>;
};

export type AssignNamespace = {
  __typename?: 'AssignNamespace';
  created_at: Scalars['Time'];
  id: Scalars['Uint64'];
  namespace?: Maybe<Namespace>;
  namespace_id?: Maybe<Scalars['Uint64']>;
  owner_id?: Maybe<Scalars['Uint64']>;
  user_id?: Maybe<Scalars['Uint64']>;
};

export type AssignNamespaceInput = {
  namespace_id: Scalars['Uint64'];
  user_id: Scalars['Uint64'];
};

export type Chan = {
  __typename?: 'Chan';
  created_at: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uint64'];
  infinite?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  owner?: Maybe<UserBaseView>;
  root_node?: Maybe<Scalars['String']>;
  status?: Maybe<ChanStatus>;
  updated_at: Scalars['Time'];
  user_id: Scalars['Uint64'];
};

export enum ChanStatus {
  Activated = 'Activated',
  Deactivated = 'Deactivated'
}

export type DeleteAssignModelInput = {
  device_id: Scalars['Uint64'];
  model_id: Scalars['Uint64'];
};

export type DeleteDeviceModelFieldInput = {
  assign_id: Scalars['Uint64'];
  id: Scalars['Uint64'];
};

export type DetailChan = {
  __typename?: 'DetailChan';
  chan: Chan;
  nodes?: Maybe<Array<Maybe<RuleNode>>>;
};

export type Device = {
  __typename?: 'Device';
  created_at: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uint64'];
  is_create_device: Scalars['Boolean'];
  is_gateway: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  namespace?: Maybe<Namespace>;
  namespace_id: Scalars['Uint64'];
  updated_at: Scalars['Time'];
};

export type DeviceCertificate = {
  __typename?: 'DeviceCertificate';
  client_id?: Maybe<Scalars['String']>;
  gateway_id?: Maybe<Scalars['Uint64']>;
  id: Scalars['Uint64'];
  password?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type: TypeCertificate;
  username?: Maybe<Scalars['String']>;
};

export type DeviceCertificateInput = {
  client_id?: InputMaybe<Scalars['String']>;
  gateway_id?: InputMaybe<Scalars['Uint64']>;
  id?: InputMaybe<Scalars['Uint64']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  type: TypeCertificate;
  username?: InputMaybe<Scalars['String']>;
};

export type DeviceDetail = {
  __typename?: 'DeviceDetail';
  certificate: DeviceCertificate;
  created_at: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uint64'];
  is_create_device: Scalars['Boolean'];
  is_gateway: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  namespace?: Maybe<Namespace>;
  namespace_id: Scalars['Uint64'];
  updated_at: Scalars['Time'];
};

export type DeviceInput = {
  certificate: DeviceCertificateInput;
  description?: InputMaybe<Scalars['String']>;
  is_create_device?: InputMaybe<Scalars['Boolean']>;
  is_gateway?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  namespace_id: Scalars['Uint64'];
};

export type DeviceLog = {
  __typename?: 'DeviceLog';
  device?: Maybe<Device>;
  device_id: Scalars['Uint64'];
  is_number: Scalars['Boolean'];
  key: Scalars['String'];
  time: Scalars['Time'];
  ts: Scalars['Int64'];
  type: TypeDeviceLog;
  value: Scalars['String'];
};

export type DeviceModelDetail = {
  __typename?: 'DeviceModelDetail';
  assign_id: Scalars['Uint64'];
  created_at: Scalars['Time'];
  created_by?: Maybe<Scalars['Uint64']>;
  device?: Maybe<Device>;
  device_id: Scalars['Uint64'];
  fields?: Maybe<Array<DeviceModelField>>;
  model?: Maybe<ModelDetail>;
  model_id: Scalars['Uint64'];
  status?: Maybe<StatusAssign>;
  updated_at: Scalars['Time'];
  updated_by?: Maybe<Scalars['Uint64']>;
};

export type DeviceModelField = {
  __typename?: 'DeviceModelField';
  assign_id: Scalars['Uint64'];
  description?: Maybe<Scalars['String']>;
  field_id: Scalars['Uint64'];
  id?: Maybe<Scalars['Uint64']>;
  key: Scalars['String'];
  source_input: SourceInput;
};

export type DeviceModelFieldInput = {
  assign_id: Scalars['Uint64'];
  description?: InputMaybe<Scalars['String']>;
  field_id: Scalars['Uint64'];
  id?: InputMaybe<Scalars['Uint64']>;
  key: Scalars['String'];
  source_input: SourceInput;
};

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Linux = 'LINUX',
  Unknown = 'UNKNOWN',
  Web = 'WEB',
  Windows = 'WINDOWS'
}

export type FieldType = {
  __typename?: 'FieldType';
  created_at: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uint64'];
  is_public: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeField;
  updated_at: Scalars['Time'];
};

export type FieldTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  is_public: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeField;
};

export type GetFieldInput = {
  device_id: Scalars['Uint64'];
  type: TypeDeviceLog;
};

export type InputAlert = {
  action: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  message_template: Scalars['String'];
  model_id: Scalars['Uint64'];
  name: Scalars['String'];
  rules?: InputMaybe<Array<Scalars['String']>>;
};

export type InputRuleChan = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: ChanStatus;
};

export type InputRuleNode = {
  connect_to?: InputMaybe<Array<Scalars['String']>>;
  debug: Scalars['Boolean'];
  end: Scalars['Boolean'];
  infinite: Scalars['Boolean'];
  name: Scalars['String'];
  node_id: Scalars['String'];
  option: Scalars['Map'];
  rule_id: Scalars['String'];
};

export type LastTimeInput = {
  device_id: Scalars['Uint64'];
  type?: InputMaybe<TypeDeviceLog>;
};

export type LastTimeModelInput = {
  device_id: Scalars['Uint64'];
  keys?: InputMaybe<Scalars['String']>;
  model_id: Scalars['Uint64'];
};

export type LayoutUi = {
  __typename?: 'LayoutUi';
  h: Scalars['Int'];
  i: Scalars['String'];
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type LayoutUiInput = {
  h: Scalars['Int'];
  i: Scalars['String'];
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ListAssignNamespaceInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type ListChan = {
  __typename?: 'ListChan';
  list?: Maybe<Array<Maybe<Chan>>>;
  total: Scalars['Int'];
};

export type ListDeviceLog = {
  __typename?: 'ListDeviceLog';
  data?: Maybe<Array<DeviceLog>>;
};

export type ListModelLog = {
  __typename?: 'ListModelLog';
  data?: Maybe<Array<ModelLog>>;
};

export type ListPaginationActionResponse = {
  __typename?: 'ListPaginationActionResponse';
  data?: Maybe<Array<Action>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationAlert = {
  __typename?: 'ListPaginationAlert';
  data?: Maybe<Array<ViewAlert>>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationAlertInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  model_id?: InputMaybe<Scalars['Uint64']>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
};

export type ListPaginationAlertLog = {
  __typename?: 'ListPaginationAlertLog';
  data?: Maybe<Array<AlertLog>>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationAlertLogInput = {
  alert_id?: InputMaybe<Scalars['Uint64']>;
  device_id?: InputMaybe<Scalars['Uint64']>;
  end_time: Scalars['Time'];
  limit: Scalars['Int'];
  model_id?: InputMaybe<Scalars['Uint64']>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  start_time: Scalars['Time'];
};

export type ListPaginationAssignNamespace = {
  __typename?: 'ListPaginationAssignNamespace';
  data?: Maybe<Array<AssignNamespace>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationDevice = {
  __typename?: 'ListPaginationDevice';
  data?: Maybe<Array<Device>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationDeviceActionInput = {
  limit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
};

export type ListPaginationDeviceInput = {
  is_gateway?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  namespaces?: InputMaybe<Array<Scalars['Uint64']>>;
  order_by?: InputMaybe<OrderByDevice>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
};

export type ListPaginationDeviceLog = {
  __typename?: 'ListPaginationDeviceLog';
  data?: Maybe<Array<DeviceLog>>;
  limit: Scalars['Int'];
};

export type ListPaginationDeviceLogInput = {
  device_id: Scalars['Uint64'];
  end_time: Scalars['String'];
  keys?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  start_time: Scalars['String'];
  type?: InputMaybe<TypeDeviceLog>;
};

export type ListPaginationDeviceModel = {
  __typename?: 'ListPaginationDeviceModel';
  data?: Maybe<Array<AssignModelDevice>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationDeviceModelInput = {
  limit: Scalars['Int'];
  model_id?: InputMaybe<Array<Scalars['Uint64']>>;
  order_by?: InputMaybe<OrderByDeviceModel>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
};

export type ListPaginationFieldType = {
  __typename?: 'ListPaginationFieldType';
  data?: Maybe<Array<FieldType>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationFieldTypeInput = {
  limit: Scalars['Int'];
  order_by?: InputMaybe<OrderByFieldType>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  type?: InputMaybe<TypeField>;
};

export type ListPaginationModel = {
  __typename?: 'ListPaginationModel';
  data?: Maybe<Array<Model>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationModelInput = {
  device_id?: InputMaybe<Scalars['Uint64']>;
  field_type_id?: InputMaybe<Scalars['Uint64']>;
  limit: Scalars['Int'];
  order_by?: InputMaybe<OrderByModel>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
};

export type ListPaginationModelLog = {
  __typename?: 'ListPaginationModelLog';
  data?: Maybe<Array<ModelLog>>;
  limit: Scalars['Int'];
};

export type ListPaginationModelLogInput = {
  device_id: Scalars['Uint64'];
  end_time: Scalars['String'];
  field_id?: InputMaybe<Scalars['Uint64']>;
  keys?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  model_id: Scalars['Uint64'];
  start_time: Scalars['String'];
  type?: InputMaybe<TypeDeviceLog>;
};

export type ListPaginationNamespace = {
  __typename?: 'ListPaginationNamespace';
  data?: Maybe<Array<Namespace>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationNamespaceInput = {
  limit: Scalars['Int'];
  order_by?: InputMaybe<OrderByNamespace>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  status?: InputMaybe<Status>;
};

export type ListPaginationRuleChanInput = {
  Search?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type ListPaginationUi = {
  __typename?: 'ListPaginationUi';
  data?: Maybe<Array<Ui>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationUiInput = {
  limit: Scalars['Int'];
  order_by?: InputMaybe<OrderByUi>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
};

export type ListPaginationWidget = {
  __typename?: 'ListPaginationWidget';
  data?: Maybe<Array<Widget>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListPaginationWidgetInput = {
  limit: Scalars['Int'];
  order_by?: InputMaybe<OrderByWidget>;
  order_type?: InputMaybe<OrderType>;
  search?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
};

export type ListPropertyDevice = {
  __typename?: 'ListPropertyDevice';
  data?: Maybe<Array<PropertyDeviceDetail>>;
};

export type Model = {
  __typename?: 'Model';
  created_at: Scalars['Time'];
  created_by?: Maybe<Scalars['Uint64']>;
  created_by_user?: Maybe<UserBaseView>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uint64'];
  name: Scalars['String'];
  status: StatusModel;
  updated_at: Scalars['Time'];
  updated_by?: Maybe<Scalars['Uint64']>;
  updated_by_user?: Maybe<UserBaseView>;
};

export type ModelDetail = {
  __typename?: 'ModelDetail';
  created_at: Scalars['Time'];
  created_by?: Maybe<Scalars['Uint64']>;
  created_by_user?: Maybe<UserBaseView>;
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<ModelField>>;
  id: Scalars['Uint64'];
  name: Scalars['String'];
  status?: Maybe<StatusModel>;
  updated_at: Scalars['Time'];
  updated_by?: Maybe<Scalars['Uint64']>;
  updated_by_user?: Maybe<UserBaseView>;
};

export type ModelField = {
  __typename?: 'ModelField';
  description?: Maybe<Scalars['String']>;
  field_type?: Maybe<FieldType>;
  field_type_id?: Maybe<Scalars['Uint64']>;
  id: Scalars['Uint64'];
  key: Scalars['String'];
  type: TypeField;
};

export type ModelFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  field_type_id?: InputMaybe<Scalars['Uint64']>;
  id?: InputMaybe<Scalars['Uint64']>;
  key: Scalars['String'];
  type: TypeField;
};

export type ModelInput = {
  description?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<ModelFieldInput>>;
  name: Scalars['String'];
  status?: InputMaybe<StatusModel>;
};

export type ModelLog = {
  __typename?: 'ModelLog';
  device?: Maybe<Device>;
  device_id: Scalars['Uint64'];
  field?: Maybe<FieldType>;
  field_id: Scalars['Uint64'];
  field_model?: Maybe<ModelField>;
  is_number: Scalars['Boolean'];
  key: Scalars['String'];
  model?: Maybe<Model>;
  model_id: Scalars['Uint64'];
  source_input: SourceInput;
  time: Scalars['Time'];
  ts: Scalars['Int64'];
  value: Scalars['String'];
  value_n: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  actionDevice: ResultActionDevice;
  actionDeviceString: ResultActionDevice;
  createAlert: Scalars['Boolean'];
  createAssignModelForDevice: Scalars['Boolean'];
  createAssignNamespace: Scalars['Boolean'];
  createDevice: Scalars['Boolean'];
  createFieldType: Scalars['Boolean'];
  createModel: Scalars['Boolean'];
  createNamespace: Scalars['Boolean'];
  createPropertyDevice: Scalars['Boolean'];
  createRuleChan: Scalars['Boolean'];
  createUi: Scalars['Boolean'];
  createWidget: Scalars['Boolean'];
  deleteAlert: Scalars['Boolean'];
  deleteAssignModelForDevice: Scalars['Boolean'];
  deleteAssignNamespace: Scalars['Boolean'];
  deleteDevice: Scalars['Boolean'];
  deleteFieldDeviceModel: Scalars['Boolean'];
  deleteFieldType: Scalars['Boolean'];
  deleteModel: Scalars['Boolean'];
  deleteNamespace: Scalars['Boolean'];
  deletePropertyDevice: Scalars['Boolean'];
  deleteRuleChan: Scalars['Boolean'];
  deleteRuleNode: Scalars['Boolean'];
  deleteUi: Scalars['Boolean'];
  deleteWidget: Scalars['Boolean'];
  getVersionActionDevice?: Maybe<Version>;
  getVersionAlert?: Maybe<Version>;
  getVersionDevice?: Maybe<Version>;
  getVersionLog?: Maybe<Version>;
  getVersionModel?: Maybe<Version>;
  getVersionNamespace?: Maybe<Version>;
  getVersionRuleChan: Version;
  getVersionUi?: Maybe<Version>;
  leaveAssignNamespace: Scalars['Boolean'];
  updateAlert: Scalars['Boolean'];
  updateDevice: Scalars['Boolean'];
  updateFieldDeviceModel: Scalars['Boolean'];
  updateFieldType: Scalars['Boolean'];
  updateModel: Scalars['Boolean'];
  updateNamespace: Scalars['Boolean'];
  updatePropertyDevice: Scalars['Boolean'];
  updateRootNodeChan: Scalars['Boolean'];
  updateRuleChan: Scalars['Boolean'];
  updateRuleNode: Scalars['Boolean'];
  updateRuleNodes: Scalars['Boolean'];
  updateStatusRuleChan: Scalars['Boolean'];
  updateUi: Scalars['Boolean'];
  updateWidget: Scalars['Boolean'];
};


export type MutationActionDeviceArgs = {
  input: ActionDeviceInput;
};


export type MutationActionDeviceStringArgs = {
  action: Scalars['String'];
};


export type MutationCreateAlertArgs = {
  input: InputAlert;
};


export type MutationCreateAssignModelForDeviceArgs = {
  input: AssignModelInput;
};


export type MutationCreateAssignNamespaceArgs = {
  input: AssignNamespaceInput;
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationCreateFieldTypeArgs = {
  input: FieldTypeInput;
};


export type MutationCreateModelArgs = {
  input: ModelInput;
};


export type MutationCreateNamespaceArgs = {
  input: NamespaceInput;
};


export type MutationCreatePropertyDeviceArgs = {
  input: PropertyDeviceInput;
};


export type MutationCreateRuleChanArgs = {
  input: InputRuleChan;
};


export type MutationCreateUiArgs = {
  input: UiInput;
};


export type MutationCreateWidgetArgs = {
  input: WidgetInput;
};


export type MutationDeleteAlertArgs = {
  alertId: Scalars['Uint64'];
};


export type MutationDeleteAssignModelForDeviceArgs = {
  input: DeleteAssignModelInput;
};


export type MutationDeleteAssignNamespaceArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeleteFieldDeviceModelArgs = {
  input?: InputMaybe<DeleteDeviceModelFieldInput>;
};


export type MutationDeleteFieldTypeArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeleteModelArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeleteNamespaceArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeletePropertyDeviceArgs = {
  deviceId: Scalars['Uint64'];
  id: Scalars['Uint64'];
};


export type MutationDeleteRuleChanArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeleteRuleNodeArgs = {
  chanId: Scalars['Uint64'];
  nodeId: Scalars['String'];
};


export type MutationDeleteUiArgs = {
  id: Scalars['Uint64'];
};


export type MutationDeleteWidgetArgs = {
  id: Scalars['Uint64'];
};


export type MutationLeaveAssignNamespaceArgs = {
  id: Scalars['Uint64'];
};


export type MutationUpdateAlertArgs = {
  alertId: Scalars['Uint64'];
  input: InputAlert;
};


export type MutationUpdateDeviceArgs = {
  id: Scalars['Uint64'];
  input: DeviceInput;
};


export type MutationUpdateFieldDeviceModelArgs = {
  input?: InputMaybe<DeviceModelFieldInput>;
};


export type MutationUpdateFieldTypeArgs = {
  id: Scalars['Uint64'];
  input: FieldTypeInput;
};


export type MutationUpdateModelArgs = {
  id: Scalars['Uint64'];
  input: ModelInput;
};


export type MutationUpdateNamespaceArgs = {
  id: Scalars['Uint64'];
  input: NamespaceInput;
};


export type MutationUpdatePropertyDeviceArgs = {
  id: Scalars['Uint64'];
  input: PropertyDeviceInput;
};


export type MutationUpdateRootNodeChanArgs = {
  id: Scalars['Uint64'];
  rootNode: Scalars['String'];
};


export type MutationUpdateRuleChanArgs = {
  id: Scalars['Uint64'];
  input: InputRuleChan;
};


export type MutationUpdateRuleNodeArgs = {
  chanId: Scalars['Uint64'];
  input: InputRuleNode;
};


export type MutationUpdateRuleNodesArgs = {
  chanId: Scalars['Uint64'];
  inputs?: InputMaybe<Array<InputRuleNode>>;
};


export type MutationUpdateStatusRuleChanArgs = {
  input: SetStatusChan;
};


export type MutationUpdateUiArgs = {
  id: Scalars['Uint64'];
  input: UiInput;
};


export type MutationUpdateWidgetArgs = {
  id: Scalars['Uint64'];
  input: WidgetInput;
};

export type Namespace = {
  __typename?: 'Namespace';
  count_device: Scalars['Int'];
  created_at: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uint64'];
  name: Scalars['String'];
  owner?: Maybe<UserBaseView>;
  owner_id: Scalars['Uint64'];
  status: Status;
  updated_at: Scalars['Time'];
};

export type NamespaceInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type Node = {
  id: Scalars['ID'];
};

export enum OrderByDevice {
  CreatedAt = 'CreatedAt',
  Key = 'Key',
  Name = 'Name',
  NamespaceId = 'NamespaceID',
  Status = 'Status',
  UpdatedAt = 'UpdatedAt'
}

export enum OrderByDeviceModel {
  CreatedAt = 'CreatedAt',
  DeviceId = 'DeviceId',
  Id = 'Id',
  Name = 'Name',
  UpdateAt = 'UpdateAt'
}

export enum OrderByFieldType {
  CreatedAt = 'CreatedAt',
  Name = 'Name',
  Type = 'Type',
  UpdateAt = 'UpdateAt'
}

export enum OrderByModel {
  CreatedAt = 'CreatedAt',
  Nane = 'Nane',
  Status = 'Status',
  UpdatedAt = 'UpdatedAt'
}

export enum OrderByNamespace {
  CreatedAt = 'CreatedAt',
  Name = 'Name',
  Status = 'Status',
  UpdatedAt = 'UpdatedAt'
}

export enum OrderByUi {
  CreatedAt = 'CreatedAt',
  IsHome = 'IsHome',
  Name = 'Name',
  Status = 'Status',
  Type = 'Type',
  UpdatedAt = 'UpdatedAt'
}

export enum OrderByWidget {
  CreatedAt = 'CreatedAt',
  Name = 'Name',
  Status = 'Status',
  Type = 'Type',
  UpdatedAt = 'UpdatedAt'
}

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Param = {
  __typename?: 'Param';
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  typeParam: TypeParam;
};

export type PropertyDeviceDetail = {
  __typename?: 'PropertyDeviceDetail';
  created_at: Scalars['Time'];
  device_id: Scalars['Uint64'];
  id: Scalars['Uint64'];
  key: Scalars['String'];
  type: TypeProperty;
  updated_at: Scalars['Time'];
  value?: Maybe<Scalars['String']>;
  value_n?: Maybe<Scalars['Float']>;
};

export type PropertyDeviceInput = {
  device_id: Scalars['Uint64'];
  key: Scalars['String'];
  type: TypeProperty;
  value?: InputMaybe<Scalars['String']>;
  value_n?: InputMaybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  configRuleChan?: Maybe<Array<Maybe<RuleConfig>>>;
  countDevice: Scalars['Int'];
  countModelAssignDevice: Scalars['Int'];
  countPropertyDevice: Scalars['Int'];
  geListModeIdHaveLogByDeviceId?: Maybe<Array<Model>>;
  getAlert: Alert;
  getDetailRuleChan: DetailChan;
  getDeviceById: DeviceDetail;
  getDeviceLogByTime: ListDeviceLog;
  getDeviceLogLastTime: ListDeviceLog;
  getDeviceModelByDeviceId?: Maybe<Array<DeviceModelDetail>>;
  getDeviceModelById: DeviceModelDetail;
  getFieldTypeById: FieldType;
  getFieldsDeviceLog?: Maybe<Array<Scalars['String']>>;
  getListAssignNamespace: ListPaginationAssignNamespace;
  getListPaginationAlert: ListPaginationAlert;
  getListPaginationAlertLog: ListPaginationAlertLog;
  getListPaginationDevice: ListPaginationDevice;
  getListPaginationDeviceLog: ListPaginationDeviceLog;
  getListPaginationDeviceModel: ListPaginationDeviceModel;
  getListPaginationFieldType: ListPaginationFieldType;
  getListPaginationModel: ListPaginationModel;
  getListPaginationModelLog: ListPaginationModelLog;
  getListPaginationNamespace: ListPaginationNamespace;
  getListPaginationRuleChan: ListChan;
  getListPaginationUi: ListPaginationUi;
  getListPaginationWidget: ListPaginationWidget;
  getModelById: ModelDetail;
  getModelLogByTime: ListModelLog;
  getModelLogLastTime: ListModelLog;
  getNamespaceById: Namespace;
  getPropertyDeviceById: ListPropertyDevice;
  getUiById: UiDetail;
  getUiHome?: Maybe<UiDetail>;
  getVersionActionDevice?: Maybe<Version>;
  getVersionAlert?: Maybe<Version>;
  getVersionDevice?: Maybe<Version>;
  getVersionLog?: Maybe<Version>;
  getVersionModel?: Maybe<Version>;
  getVersionNamespace?: Maybe<Version>;
  getVersionRuleChan: Version;
  getVersionUi?: Maybe<Version>;
  getWidgetAll: AllWidget;
  getWidgetById: WidgetDetail;
  listPaginationActionDevice: ListPaginationActionResponse;
  node?: Maybe<Node>;
};


export type QueryCountModelAssignDeviceArgs = {
  id: Scalars['Uint64'];
};


export type QueryCountPropertyDeviceArgs = {
  id: Scalars['Uint64'];
};


export type QueryGeListModeIdHaveLogByDeviceIdArgs = {
  DeviceId: Scalars['Uint64'];
};


export type QueryGetAlertArgs = {
  alertId: Scalars['Uint64'];
};


export type QueryGetDetailRuleChanArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetDeviceByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetDeviceLogByTimeArgs = {
  input: TimeInput;
};


export type QueryGetDeviceLogLastTimeArgs = {
  input: LastTimeInput;
};


export type QueryGetDeviceModelByDeviceIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetDeviceModelByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetFieldTypeByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetFieldsDeviceLogArgs = {
  input: GetFieldInput;
};


export type QueryGetListAssignNamespaceArgs = {
  input?: InputMaybe<ListAssignNamespaceInput>;
};


export type QueryGetListPaginationAlertArgs = {
  input: ListPaginationAlertInput;
};


export type QueryGetListPaginationAlertLogArgs = {
  input: ListPaginationAlertLogInput;
};


export type QueryGetListPaginationDeviceArgs = {
  input?: InputMaybe<ListPaginationDeviceInput>;
};


export type QueryGetListPaginationDeviceLogArgs = {
  input?: InputMaybe<ListPaginationDeviceLogInput>;
};


export type QueryGetListPaginationDeviceModelArgs = {
  input?: InputMaybe<ListPaginationDeviceModelInput>;
};


export type QueryGetListPaginationFieldTypeArgs = {
  input?: InputMaybe<ListPaginationFieldTypeInput>;
};


export type QueryGetListPaginationModelArgs = {
  input: ListPaginationModelInput;
};


export type QueryGetListPaginationModelLogArgs = {
  input?: InputMaybe<ListPaginationModelLogInput>;
};


export type QueryGetListPaginationNamespaceArgs = {
  input?: InputMaybe<ListPaginationNamespaceInput>;
};


export type QueryGetListPaginationRuleChanArgs = {
  input: ListPaginationRuleChanInput;
};


export type QueryGetListPaginationUiArgs = {
  input?: InputMaybe<ListPaginationUiInput>;
};


export type QueryGetListPaginationWidgetArgs = {
  input?: InputMaybe<ListPaginationWidgetInput>;
};


export type QueryGetModelByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetModelLogByTimeArgs = {
  input: TimeModelInput;
};


export type QueryGetModelLogLastTimeArgs = {
  input: LastTimeModelInput;
};


export type QueryGetNamespaceByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetPropertyDeviceByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetUiByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryGetWidgetByIdArgs = {
  id: Scalars['Uint64'];
};


export type QueryListPaginationActionDeviceArgs = {
  input: ListPaginationDeviceActionInput;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type ResultActionDevice = {
  __typename?: 'ResultActionDevice';
  reason?: Maybe<Scalars['String']>;
  result: Scalars['Map'];
  success: Scalars['Boolean'];
};

export enum RoleUser {
  Admin = 'ADMIN',
  User = 'USER'
}

export type RuleConfig = {
  __typename?: 'RuleConfig';
  description: Scalars['Boolean'];
  enable: Scalars['Boolean'];
  name: Scalars['String'];
  options: Scalars['Map'];
  rule_id: Scalars['String'];
};

export type RuleNode = {
  __typename?: 'RuleNode';
  chain_id: Scalars['Uint64'];
  connect_to?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at: Scalars['Time'];
  debug: Scalars['Boolean'];
  end: Scalars['Boolean'];
  id: Scalars['Uint64'];
  infinite: Scalars['Boolean'];
  name: Scalars['String'];
  node_id: Scalars['String'];
  option: Scalars['Map'];
  rule_id: Scalars['String'];
  updated_at?: Maybe<Scalars['Time']>;
};

export type SetStatusChan = {
  id: Scalars['Uint64'];
  status?: InputMaybe<ChanStatus>;
};

export enum SourceInput {
  Property = 'PROPERTY',
  ServerProperty = 'SERVER_PROPERTY',
  Telemetry = 'TELEMETRY'
}

export enum Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum StatusAssign {
  ActiveAssign = 'ACTIVE_ASSIGN',
  DeleteAssign = 'DELETE_ASSIGN',
  ModelInactive = 'MODEL_INACTIVE',
  UserInactive = 'USER_INACTIVE'
}

export enum StatusModel {
  Active = 'ACTIVE',
  Delete = 'DELETE',
  Inactive = 'INACTIVE'
}

export type TimeInput = {
  device_id: Scalars['Uint64'];
  time: Scalars['Time'];
  type?: InputMaybe<TypeDeviceLog>;
};

export type TimeModelInput = {
  device_id: Scalars['Uint64'];
  keys?: InputMaybe<Scalars['String']>;
  model_id: Scalars['Uint64'];
  time: Scalars['Time'];
};

export enum TypeCertificate {
  Certificate = 'CERTIFICATE',
  Gateway = 'GATEWAY',
  Token = 'TOKEN'
}

export enum TypeDeviceLog {
  Log = 'LOG',
  Property = 'PROPERTY'
}

export enum TypeField {
  Number = 'NUMBER',
  String = 'STRING'
}

export enum TypeParam {
  TypeDevice = 'TypeDevice',
  TypeNumber = 'TypeNumber',
  TypeProperty = 'TypeProperty',
  TypeString = 'TypeString'
}

export enum TypeProperty {
  Image = 'IMAGE',
  Number = 'NUMBER',
  String = 'STRING'
}

export type Ui = {
  __typename?: 'Ui';
  created_at: Scalars['Time'];
  id: Scalars['Uint64'];
  is_home: Scalars['Boolean'];
  name: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['Time'];
};

export type UiDetail = {
  __typename?: 'UiDetail';
  created_at: Scalars['Time'];
  id: Scalars['Uint64'];
  is_home: Scalars['Boolean'];
  labels: Scalars['Map'];
  layouts?: Maybe<Array<Maybe<LayoutUi>>>;
  name: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['Time'];
  widget_list?: Maybe<Array<WidgetDetail>>;
  widgets: Scalars['Map'];
};

export type UiInput = {
  filters: Scalars['String'];
  is_home: Scalars['Boolean'];
  labels: Scalars['Map'];
  layouts?: InputMaybe<Array<InputMaybe<LayoutUiInput>>>;
  name: Scalars['String'];
  type: Scalars['String'];
  widgets: Scalars['Map'];
};

export type UserBaseView = {
  __typename?: 'UserBaseView';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Version = {
  __typename?: 'Version';
  name: Scalars['String'];
  version: Scalars['String'];
};

export type ViewAlert = {
  __typename?: 'ViewAlert';
  created_at: Scalars['Time'];
  created_by_user?: Maybe<UserBaseView>;
  enable: Scalars['Boolean'];
  id: Scalars['Uint64'];
  model?: Maybe<Model>;
  model_id: Scalars['Uint64'];
  name: Scalars['String'];
  updated_at: Scalars['Time'];
  user_id?: Maybe<Scalars['Uint64']>;
};

export type Widget = {
  __typename?: 'Widget';
  created_at: Scalars['Time'];
  id: Scalars['Uint64'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type WidgetData = {
  __typename?: 'WidgetData';
  device?: Maybe<Device>;
  device_id: Scalars['Uint64'];
  fields?: Maybe<Array<Scalars['String']>>;
  model?: Maybe<Model>;
  model_id: Scalars['Uint64'];
  namespace?: Maybe<Namespace>;
  namespace_id: Scalars['Uint64'];
  source: Scalars['String'];
  time: Scalars['String'];
  type: Scalars['String'];
  window?: Maybe<Scalars['String']>;
};

export type WidgetDataInput = {
  device_id: Scalars['Uint64'];
  fields?: InputMaybe<Array<Scalars['String']>>;
  model_id: Scalars['Uint64'];
  namespace_id: Scalars['Uint64'];
  source: Scalars['String'];
  time: Scalars['String'];
  type: Scalars['String'];
  window: Scalars['String'];
};

export type WidgetDetail = {
  __typename?: 'WidgetDetail';
  created_at: Scalars['Time'];
  data: WidgetData;
  id: Scalars['Uint64'];
  name: Scalars['String'];
  option: Scalars['Map'];
  type: Scalars['String'];
  updated_at: Scalars['Time'];
};

export type WidgetInput = {
  data: WidgetDataInput;
  name: Scalars['String'];
  option: Scalars['Map'];
  type: Scalars['String'];
  type_widget: Scalars['String'];
};

import { Modal } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import EditChan from "src/modules/rule_chan/components/edit";
import EditRuleChan from "src/modules/rule_chan/components/edit-rule-chan";
import { Chan } from "src/types";

const EditRuleChanPage: React.FC<{}> = () => {

  const [isModal, setIsModal] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [chanInfo, ] = useState<Chan>()
  const nav = useNavigate();

  let { id } = useParams<{ id: string }>();

  return (
    <>
      <Helmet>
        <title>Dipper - Rule Chan</title>
      </Helmet>
      <EditRuleChan ruleChanId={id} onBack={() => {
        nav("/rule_chan")
      }}/>

      <Modal
          visible={isModal}
          title={
            !isEdit
              ? "Add Rule Chan"
              : "Edit Rule Chan"
          }
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
          <EditChan
            data={chanInfo}
            isEdit={true}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                
              }
            }}
            onEdit={() => {
              setEdit(true);
            }}
          />
        </Modal>

    </>
  );
};

export default EditRuleChanPage;

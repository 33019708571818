import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAlertQueryVariables = Types.Exact<{
  input: Types.Scalars['Uint64'];
}>;


export type GetAlertQuery = { __typename?: 'Query', getAlert: { __typename?: 'Alert', id: any, name: string, model_id: any, created_at: any, updated_at: any, message_template: string, rules?: Array<string> | null, description?: string | null, action: string, enable: boolean, model?: { __typename?: 'Model', id: any, name: string } | null } };


export const GetAlertDocument = gql`
    query getAlert($input: Uint64!) {
  getAlert(alertId: $input) {
    id
    name
    model {
      id
      name
    }
    model_id
    created_at
    updated_at
    message_template
    rules
    description
    action
    enable
  }
}
    `;

/**
 * __useGetAlertQuery__
 *
 * To run a query within a React component, call `useGetAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAlertQuery(baseOptions: Apollo.QueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, options);
      }
export function useGetAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, options);
        }
export type GetAlertQueryHookResult = ReturnType<typeof useGetAlertQuery>;
export type GetAlertLazyQueryHookResult = ReturnType<typeof useGetAlertLazyQuery>;
export type GetAlertQueryResult = Apollo.QueryResult<GetAlertQuery, GetAlertQueryVariables>;
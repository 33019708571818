import { Col, Form, Row, Select } from "antd";
import Input from "rc-input";
import { useEffect, useState } from "react";
import { WidgetDetail } from "src/types";
import { ChartType } from "../../models/widget";
import { GetWidgets } from "../widget/widget";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const GeneralEdit: React.FC<{
  widget: WidgetDetail;
  onChange?: (data: WidgetDetail) => void;
}> = (props) => {
  const [listChart, setListChart] = useState<ChartType[]>([]);
  const [form] = Form.useForm();
  const { widget } = props;
  const { name, type } = widget;

  useEffect(() => {
    form.setFields([
      {
        name: "name",
        value: name,
      },
      {
        name: "type",
        value: type,
      },
    ]);
  }, [name, type, form]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const widgets = GetWidgets();
    let list: ChartType[] = [];
    widgets.forEach((value) => {
      list.push({
        type: value.type,
        name: value.name,
      });
    });
    setListChart(list);
  }, [type]);

  const onChange = async () => {
    const values = await form.validateFields();

    if (!props.onChange) {
      return;
    }
    props.onChange({
      ...widget,
      name: values.name,
      type: values.type,
    });
  };

  return (
    <Form {...layout} form={form} >
      <Row gutter={[5, 5]}>
        <Col span={24}>
          <Form.Item name={"name"} label="Name" required>
            <Input onChange={onChange} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={"type"} label="Type Chart" required>
            <Select onChange={onChange}>
              {listChart.map((item) => {
                return (
                  <Select.Option key={item.type} value={item.type}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default GeneralEdit;

import { Col, Form, Input, Row } from "antd";
import React from "react";
import { WidgetOption } from "../../../models/widget";

const EditArea: React.FC<{
  option: WidgetOption;
  change: (option: WidgetOption) => void;
}> = (props) => {

  const onFinish = (values: any) => {
    props.change(values);
  };

  return (
    <Form onFinish={onFinish}>
      <Row style={{ paddingTop: "10px" }}>
        <Col span={24}>
          <Form.Item label="Source Data Key" name="data_key">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="X field" name="x_field">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditArea;

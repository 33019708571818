import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { WidgetDetail } from "src/types";
import { WidgetOption } from "../../models/widget";
import { GetWidgets, LineWidget } from "../widget/widget";
import GeneralEdit from "./general-edit";

const WidgetEdit: React.FC<{
  widget: WidgetDetail;
  change: (widget: WidgetDetail) => void;
  cancel: () => void;
  save: () => void;
}> = (props) => {
  const { widget } = props;
  const [option, setOption] = useState<WidgetOption>(widget.option || {});
  const mapChart = GetWidgets();

  const onChangeOption = (option: WidgetOption) => {
    console.log(option)
    setOption(option);
    props.change(Object.assign({}, widget, { option }));
  };

  const handleChangeWidget = (data: WidgetDetail) => {
    props.change({
      ...widget,
      name: data.name,
      type: data.type,
    });
  };

  let EditWidgetComponent: typeof React.Component;
  let chartType = mapChart.get(widget.type || LineWidget.type);
  if (chartType !== undefined) {
    EditWidgetComponent = chartType.edit as typeof React.Component;
  } else {
    return null;
  }

  return (
    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Row gutter={[5,5]}>
          <Col>
            <Button color="primary" onClick={(e) => props.save()}>
              Save
            </Button>
          </Col>
          <Col>
            <Button color="error" onClick={(e) => props.cancel()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Tabs>
          <Tabs.TabPane tab="General" key={"1"}>
            <GeneralEdit widget={props.widget} onChange={handleChangeWidget} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Option" key={"2"}>
            <EditWidgetComponent option={option} change={onChangeOption} />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default WidgetEdit;

import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectFilter, setFilter } from "../slice";
import SelectModel from "src/modules/model/components/select-model";

const FilterAlert: React.FC<{}> = (props) => {
  const filter = useAppSelector(selectFilter);
  const dispatch = useAppDispatch();
  const [formData] = Form.useForm<any>();
  const [styleInput, setStyleInput] = useState<any>({});
  const [showAdd, setShowAdd] = useState(false);

  const onSearch: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const search = formData.getFieldValue("search");
    if (event.keyCode === 13) {
      dispatch(setFilter({
        limit: 0,
        skip: 0
      }));
    }
    getStyle(search);
  };

  const getStyle = (search: string) => {
    // if (!(isUndefined(filter.search) && search === "")) {
    //   if (filter.search !== search) {
    //     setStyleInput({ background: "#ffb6b6" });
    //     return;
    //   }
    // }
    setStyleInput({});
  };

  useEffect(() => {
    getStyle(formData.getFieldValue("search"));

    formData.setFields([
      {
        name: "model",
        value: "",
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleOkFilter = () => {
    const model = formData.getFieldValue("model");
    const change: any = {};
    if (model !== "") {
      change["model"] = model;
    }
    dispatch(setFilter({ ...filter, ...change }));
    setShowAdd(false);
  };

  const clearFilter = (name: string) => {
    dispatch(setFilter({ ...filter, [name]: undefined }));
  };

  return (
    <Form form={formData}>
      <Row gutter={10}>
        <Col hidden={!filter.model_id}>
          {filter.model_id && (
            <Tag color={"green"} key="model">
              Model: {filter.model_id}
              <Button
                type="link"
                style={{ height: 30}}
                icon={<CloseOutlined size={1} />}
                onClick={() => {
                  clearFilter("model");
                }}
              ></Button>
            </Tag>
          )}
        </Col>
        <Col>
          <Form.Item name="search" style={{ margin: 0 }}>
            <Input placeholder="Search" onKeyUp={onSearch} style={styleInput} />
          </Form.Item>
        </Col>
        <Col>
          <Button
            type="default"
            onClick={() => {
              setShowAdd(true);
            }}
          >
            Add Filter
          </Button>
        </Col>
      </Row>
      <Modal
        key={"add-filter-model"}
        title="Add Filter"
        visible={showAdd}
        width={300}
        onOk={handleOkFilter}
        onCancel={() => {
          setShowAdd(false);
        }}
      >
        <Row>
          <Col span={24}>
            <SelectModel name="model_id" disabled={false}></SelectModel>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default FilterAlert;

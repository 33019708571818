import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFieldsDeviceLogQueryVariables = Types.Exact<{
  input: Types.GetFieldInput;
}>;


export type GetFieldsDeviceLogQuery = { __typename?: 'Query', getFieldsDeviceLog?: Array<string> | null };


export const GetFieldsDeviceLogDocument = gql`
    query getFieldsDeviceLog($input: GetFieldInput!) {
  getFieldsDeviceLog(input: $input)
}
    `;

/**
 * __useGetFieldsDeviceLogQuery__
 *
 * To run a query within a React component, call `useGetFieldsDeviceLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldsDeviceLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldsDeviceLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFieldsDeviceLogQuery(baseOptions: Apollo.QueryHookOptions<GetFieldsDeviceLogQuery, GetFieldsDeviceLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldsDeviceLogQuery, GetFieldsDeviceLogQueryVariables>(GetFieldsDeviceLogDocument, options);
      }
export function useGetFieldsDeviceLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldsDeviceLogQuery, GetFieldsDeviceLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldsDeviceLogQuery, GetFieldsDeviceLogQueryVariables>(GetFieldsDeviceLogDocument, options);
        }
export type GetFieldsDeviceLogQueryHookResult = ReturnType<typeof useGetFieldsDeviceLogQuery>;
export type GetFieldsDeviceLogLazyQueryHookResult = ReturnType<typeof useGetFieldsDeviceLogLazyQuery>;
export type GetFieldsDeviceLogQueryResult = Apollo.QueryResult<GetFieldsDeviceLogQuery, GetFieldsDeviceLogQueryVariables>;
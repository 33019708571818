import { Form, Select } from "antd";

const TimeData: React.FC<{
  onChange?: (data: string) => void;
}> = (props) => {
  return (
    <Form.Item
      name={"time"}
      label="Time"
      rules={[{ required: true }]}
    >
      <Select onChange={props.onChange}>
        <Select.Option value={"5m"}>5 m</Select.Option>
        <Select.Option value={"15m"}>15 m</Select.Option>
        <Select.Option value={"30m"}>30 m</Select.Option>
        <Select.Option value={"1h"}>1h</Select.Option>
        <Select.Option value={"3h"}>3h</Select.Option>
        <Select.Option value={"6h"}>6h</Select.Option>
        <Select.Option value={"12h"}>12h</Select.Option>
        <Select.Option value={"1d"}>1d</Select.Option>
        <Select.Option value={"2d"}>2d</Select.Option>
        <Select.Option value={"3d"}>3d</Select.Option>
        <Select.Option value={"5d"}>5d</Select.Option>
        <Select.Option value={"7d"}>7d</Select.Option>
        <Select.Option value={"30d"}>30d</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default TimeData;

import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteNamespaceMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type DeleteNamespaceMutation = { __typename?: 'Mutation', deleteNamespace: boolean };


export const DeleteNamespaceDocument = gql`
    mutation deleteNamespace($id: Uint64!) {
  deleteNamespace(id: $id)
}
    `;
export type DeleteNamespaceMutationFn = Apollo.MutationFunction<DeleteNamespaceMutation, DeleteNamespaceMutationVariables>;

/**
 * __useDeleteNamespaceMutation__
 *
 * To run a mutation, you first call `useDeleteNamespaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNamespaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNamespaceMutation, { data, loading, error }] = useDeleteNamespaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNamespaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNamespaceMutation, DeleteNamespaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNamespaceMutation, DeleteNamespaceMutationVariables>(DeleteNamespaceDocument, options);
      }
export type DeleteNamespaceMutationHookResult = ReturnType<typeof useDeleteNamespaceMutation>;
export type DeleteNamespaceMutationResult = Apollo.MutationResult<DeleteNamespaceMutation>;
export type DeleteNamespaceMutationOptions = Apollo.BaseMutationOptions<DeleteNamespaceMutation, DeleteNamespaceMutationVariables>;
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Drawer,
  Modal,
  PageHeader,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDetail } from "src/modules/device/slice";
import { getDeviceByIdThunkAction } from "src/modules/device/thunk";
import CreateAssign from "src/modules/device_model/components/create_assign";
import EditDeviceModel from "src/modules/device_model/components/edit/fields";
import ListDeviceModel from "src/modules/device_model/components/list";
import { deleteDeviceModelThunkAction } from "src/modules/device_model/thunk";
import { DeviceModelDetail } from "src/types";

const DeviceModelPage: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();
  const [assign, setSelectAssign] = useState<DeviceModelDetail | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);

  const detail = useAppSelector(selectDetail);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (id) {
      dispatch<any>(getDeviceByIdThunkAction(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!id) {
    return <></>
  }

  let callbackLoadData = () => {};

  const onAction = async (action: string, row: DeviceModelDetail) => {
    setSelectAssign(row);
    switch (action) {
      case "detail": {
        setIsModal(true);
        break;
      }
      case "edit": {
        setAdd(false);
        setIsModal(true);
        break;
      }
      case "delete": {
        await dispatch<any>(
          deleteDeviceModelThunkAction({
            assign_id: row.assign_id,
            id: row.device_id,
          })
        );
        callbackLoadData();
        break;
      }
    }
  };

  const onAdd = () => {
    setAdd(true);
  };

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - Alert</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Card size={"small"}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/device"}>Device</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detail?.name}</Breadcrumb.Item>
              <Breadcrumb.Item>Model</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              className="page-header-responsive"
              title="Devive Model"
              ghost={false}
              onBack={() => window.history.back()}
              extra={<Button onClick={onAdd}>Assign Model</Button>}
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Row>
              <Col span={24}>
                <ListDeviceModel
                  deviceId={id}
                  onAction={onAction}
                  refLoading={(callback) => {
                    callbackLoadData = callback;
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Drawer
        title={"Model Assign Device"}
        placement="right"
        width={600}
        closable={false}
        visible={isModal}
        onClose={() => {
          setIsModal(false);
        }}
      >
        {isModal && (
          <EditDeviceModel
            assignId={(assign == null) ? null : assign.assign_id}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                callbackLoadData();
              }
            }}
          ></EditDeviceModel>
        )}
      </Drawer>

      <Modal
        title="Add Assign Model"
        visible={add}
        footer={null}
        onCancel={() => {
          setAdd(false);
        }}
      >
        <CreateAssign
          deviceId={id}
          onClose={(loading) => {
            setAdd(false);
            if (loading) {
              callbackLoadData();
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default DeviceModelPage;

import React from "react";
import { Helmet } from "react-helmet";
import { Card } from "antd";
import Login from "src/modules/login/components/login";

interface P { }

const LoginPage: React.FC<P> = (props: P) => {

  return (
    <Card id="login-container" >
      <Helmet>
        <title>O-Hub - Login</title>
      </Helmet>
      <Login />
    </Card>
  );
};

export default LoginPage;

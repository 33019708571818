import { Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { WidgetOption } from "../../../../models/widget";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const Edit: React.FC<{
  option: WidgetOption;
  change: (option: WidgetOption) => void;
}> = (props) => {
  const [form] = Form.useForm<any>();
  const { option }= props

  const onChange = async () => {
    const values = await form.validateFields();
    props.change(values);
  };

  useEffect(() => {
    form.setFields([
      {
        name: "max",
        value: 100,
      },
      {
        name: "min",
        value: 0,
      },
    ]);
    form.setFieldsValue(option)
  },[option, form] )

  return (
    <Form {...layout} form={form} onChangeCapture={onChange}>
      <Row style={{ paddingTop: "10px" }}>
        <Col span={24}>
          <Form.Item label="Source Key" name="source_key">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Data Key" name="data_key">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Data Value" name="data_val">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Titel" name="text">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Unit" name="unit">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Color" name="color">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Max" name="max">
            <Input type={"number"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Min" name="min">
            <Input type={"number"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Format" name="format">
            <Select>
              <Select.Option key={"value"} value={"value"}>
                Value
              </Select.Option>
              <Select.Option key={"value"} value={"percent"}>
                Percent
              </Select.Option>
              <Select.Option key={"value"} value={"custom"}>
                Custom
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Edit;

import React from "react";
import QueryDataWidget from "src/modules/model_log/components/query-data-widget";
import { WidgetDetail } from "src/types";
import { GetWidgets } from "./widget";

const QueryDataShowWidget: React.FC<{
  widget: WidgetDetail;
  filter?: any;
  refLoading?: (callback: () => void) => void;
  onData?: (data: any) => void;
  height?: number;
  width?: number;
}> = (props) => {
  const widgets = GetWidgets();

  const { widget } = props;
  let ViewWidgetComponent: typeof React.Component;
  const w = widgets.get(widget.type);
  if (w?.view != null) {
    ViewWidgetComponent = w?.view as typeof React.Component;
  } else {
    return null;
  }
  return (
    <QueryDataWidget
      {...props}
      widgetComponent={ViewWidgetComponent}
    />
  );
};

export default QueryDataShowWidget;

import { Column } from "@ant-design/charts";
import React from "react";
import { isUndefined } from "lodash";
import { WidgetData } from "../../../../models/data";
import { WidgetOption } from "../../../../models/widget";

const View: React.FC<{
  data: WidgetData;
  option: WidgetOption;
}> = (props) => {
  let { option, data } = props;

  if (isUndefined(data.list) || data.list == null) {
    data.list = [];
  }
  console.log(data);
  var config = {
    data: data[option.data_key || "list"] || [],
    xField: option.x_field || "time",
    yField: option.y_field || "val",
    seriesField: option.series_field || "key",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
  };
  return <Column {...config} />;
};

export default View;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { GetFieldInput, LastTimeInput, ListPaginationDeviceLogInput, TimeInput } from 'src/types'

import { GetListPaginationDeviceLogDocument, GetListPaginationDeviceLogQuery, GetListPaginationDeviceLogQueryVariables } from './graph/getListPaginationDeviceLog.generated'
import { GetDeviceLogByTimeDocument, GetDeviceLogByTimeQueryVariables, GetDeviceLogByTimeQuery } from './graph/getDeviceLogByTime.generated'
import { GetDeviceLogLastTimeDocument, GetDeviceLogLastTimeQueryVariables, GetDeviceLogLastTimeQuery } from './graph/getDeviceLogLastTime.generated'
import { GetFieldsDeviceLogDocument, GetFieldsDeviceLogQueryVariables, GetFieldsDeviceLogQuery } from './graph/getFieldsDeviceLog.generated'

export const getListPaginationDeviceLogThunkAction = createAsyncThunk<
    GetListPaginationDeviceLogQuery,
    ListPaginationDeviceLogInput,
    { extra: { client: GraphQLClient } }
>(
    'device_log/getListPaginationDeviceLogThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationDeviceLogQueryVariables, GetListPaginationDeviceLogQuery>(GetListPaginationDeviceLogDocument, { input: data })
        return res;
    }
)

export const getDeviceLogByTimeThunkAction = createAsyncThunk<
    GetDeviceLogByTimeQuery,
    TimeInput,
    { extra: { client: GraphQLClient } }
>(
    'device_log/getDeviceLogByTimeThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetDeviceLogByTimeQueryVariables, GetDeviceLogByTimeQuery>(GetDeviceLogByTimeDocument, { input: data })
        return res;
    }
)


export const getDeviceLogLastTimeThunkAction = createAsyncThunk<
    GetDeviceLogLastTimeQuery,
    LastTimeInput,
    { extra: { client: GraphQLClient } }
>(
    'device_log/getDeviceLogLastTimeThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetDeviceLogLastTimeQueryVariables, GetDeviceLogLastTimeQuery>(GetDeviceLogLastTimeDocument, { input: data })
        return res;
    }
)

export const getFieldsDeviceLogThunkAction = createAsyncThunk<
    GetFieldsDeviceLogQuery,
    GetFieldInput,
    { extra: { client: GraphQLClient } }
>(
    'device_log/getFieldsDeviceLogThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetFieldsDeviceLogQueryVariables, GetFieldsDeviceLogQuery>(GetFieldsDeviceLogDocument, { input: data })
        return res;
    }
)

import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { ListPaginationNamespaceInput, NamespaceInput } from 'src/types'

import { GetListPaginationNamespaceDocument, GetListPaginationNamespaceQuery, GetListPaginationNamespaceQueryVariables } from './graph/getListPaginationNamespace.generated'
import { GetNamespaceByIdDocument, GetNamespaceByIdQuery, GetNamespaceByIdQueryVariables } from './graph/getNamespaceById.generated'
import { CreateNamespaceDocument, CreateNamespaceMutation, CreateNamespaceMutationVariables } from './graph/createNamespace.generated'
import { UpdateNamespaceDocument, UpdateNamespaceMutation, UpdateNamespaceMutationVariables } from './graph/updateNamespace.generated'
import { DeleteNamespaceDocument, DeleteNamespaceMutation, DeleteNamespaceMutationVariables } from './graph/deleteNamespace.generated'

export const getListPaginationNamespaceThunkAction = createAsyncThunk<
    GetListPaginationNamespaceQuery,
    ListPaginationNamespaceInput,
    { extra: { client: GraphQLClient } }
>(
    'namespace/getListPaginationNamespaceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationNamespaceQueryVariables, GetListPaginationNamespaceQuery>(GetListPaginationNamespaceDocument, { input: data })
        return res;
    }
)

export const getSearchNamespaceThunkAction = createAsyncThunk<
    GetListPaginationNamespaceQuery,
    ListPaginationNamespaceInput,
    { extra: { client: GraphQLClient } }
>(
    'namespace/getSearchNamespaceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationNamespaceQueryVariables, GetListPaginationNamespaceQuery>(GetListPaginationNamespaceDocument, { input: data })
        return res;
    }
)


export const getNamespaceByIdThunkAction = createAsyncThunk<
    GetNamespaceByIdQuery,
    any,
    { extra: { client: GraphQLClient } }
>(
    'namespace/getNamespaceByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetNamespaceByIdQueryVariables, GetNamespaceByIdQuery>(GetNamespaceByIdDocument, { id: id })
        return res;
    }
)


export const createNamespaceThunkAction = createAsyncThunk<
    CreateNamespaceMutation,
    NamespaceInput,
    { extra: { client: GraphQLClient } }
>(
    'namespace/createNamespaceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateNamespaceMutationVariables, CreateNamespaceMutation>(CreateNamespaceDocument, { input: data })
        return res;
    }
)

export const updateNamespaceThunkAction = createAsyncThunk<
    UpdateNamespaceMutation,
    UpdateNamespaceMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'namespace/updateNamespaceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateNamespaceMutationVariables, UpdateNamespaceMutation>(UpdateNamespaceDocument, data)
        return res;
    }
)

export const deleteNamespaceThunkAction = createAsyncThunk<
    DeleteNamespaceMutation,
    number,
    { extra: { client: GraphQLClient } }
>(
    'namespace/createNamespaceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<DeleteNamespaceMutationVariables, DeleteNamespaceMutation>(DeleteNamespaceDocument, { id: data })
        return res;
    }
)


import { Button, Col, Form, Input, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";

const FormSaveUi: React.FC<{
  new: boolean;
  ui: {
    name: string;
    is_home: boolean;
  };
  visible: boolean;
  onSave: (data: { name: string; is_home: boolean }) => void;
  onCancel: () => void;
}> = (props) => {
  const [form] = Form.useForm();
  const { name } = props.ui;
  const [is_home, setHome] = useState<boolean>(props.ui.is_home)

  const onSave = async () => {
    const value = await form.validateFields();
    props.onSave({
      name: value.name,
      is_home: value.is_home,
    });
  };

  useEffect(() => {
    form.setFields([
      {
        name: "name",
        value: name,
      },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Modal
      title={props.new ? "Add UI" : "Update UI"}
      visible={props.visible}
      closable={false}
      footer={null}
      width={400}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Requried input name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="is_home" label="Home Dashboard" >
            <Switch       
              checked={is_home}        
              onChange={(checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
                setHome(checked)
              }}
            />  
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row
              gutter={[10, 0]}
              justify="center"
            >
              <Col>
                <Button onClick={onSave}>Save</Button>
              </Col>
              <Col>
                <Button onClick={props.onCancel}>Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FormSaveUi;

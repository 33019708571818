import { Handle, NodeProps, Position } from "reactflow";
import { RuleInfo, RuleOptionProps } from "../rule";
import { Alert, Button, Col, Form, Input, Modal, Row, Spin, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
const { Title, Text } = Typography;

const ArithmeticRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                position={Position.Left}
                id="input"
                title="input"
                style={{ background: 'red' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Arithmetic</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                title="output"
                style={{  background: 'green' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}


export default ArithmeticRule;



export interface ItemArithmeticModel {
    target: string;
    source: string;
  }

const EditArithmetic: React.FC<{
    data: ItemArithmeticModel | null;
    onClose: () => void;
    onSave: (data: ItemArithmeticModel) => void;
  }> = (props) => {
    const [form] = Form.useForm();
  
    useEffect(() => {
      if (props.data !== null) {
        form.resetFields();
  
        if (props.data.target) {
          form.setFields([
            {
              name: "target",
              value: props.data.target,
            },
          ]);
  
        }
  
        if (props.data.source) {
          form.setFields([
            {
              name: "source",
              value: props.data.source,
            },
          ]);
        }
      }
    }, [props, form]);
  
    const onFinish = (values: any) => {
      props.onSave(values);
      form.resetFields(["target","source" ])
    };

    const onClose = () => {
        form.resetFields(["target","source" ])
        props.onClose()
    }
  
    return (
        <Form {...layout} onFinish={onFinish} form={form}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item name="target" label="Result Key" style={{ margin: 0 }}>
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="source"
                label="Arithmetic"
                style={{ margin: 0 }}
              >
                <Input.TextArea size="large" />
              </Form.Item>
            </Col>
  
            <Col span={24}>
              <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                  <Input size="large" type="submit" value="Save" />
                </Col>
  
                <Col>
                  <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  };

const EditItemArithmetic: React.FC<{
    list: ItemArithmeticModel[];
    onChange: (list: ItemArithmeticModel[]) => void
}> = (props) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [field, setField] = useState<ItemArithmeticModel | null>(null);
  const [list, setList] = useState<ItemArithmeticModel[]>([]);


  const onEdit = (row: ItemArithmeticModel) => {
    setField(row);
    setIsModal(true);
  };

  const onAdd  = () => {
    setIsModal(true);
    setField(null);
  }

  const onRemove  = (target: string) => {
    const newList = list.filter((item) => {
        return item.target !== target
    })
    setList(newList);
    props.onChange(newList)
  }

  const onSaveItem =  (data: ItemArithmeticModel) => {    
    data.target = data.target.trim()
    let found = false;
    const newList = list.map((item) => {
        if (item.target === data.target) {
            found = true;
            return data
        }
        return item
    })
    if (!found) {
        newList.push(data)
    }
    setList(newList);
    props.onChange(newList)
    setIsModal(false);
  }

  useEffect(() => {
    if (props.list && Array.isArray(props.list)) {
        setList(props.list)
    }
  }, [props])

  return (
    <Row gutter={[10, 10]} style={{ marginTop: "50px" }}>
        <Col span={16}>
          <Title level={5}>
            Field Item Arithmetic
          </Title>
        </Col>
        <Col span={8} style={{ textAlign: "right" }} >
            <Button
            type="primary"
            style={{ width: 100 }}
            onClick={onAdd}
            >
                Add
            </Button>
        </Col>
        <Col span={24}>
          <Table
            dataSource={list}
            size="middle"
            rowKey={"id"}
            pagination={false}
          >
            <Column
              title="Target Key"
              dataIndex="target"
              key="target"
              render={(v, row: ItemArithmeticModel) => {
                return <>{row.target}</>;
              }}
            />
            <Column
              title="Arithmetic"
              dataIndex="source"
              key="source"
              render={(text: any, record: ItemArithmeticModel) => {
                return record.source;
              }}
            />
            <Column
              title=""
              key="action"
              width={100}
              render={(text: any, record: ItemArithmeticModel) => (
                <>
                    <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={(e) => {
                            onEdit(record);
                        }}
                    />
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            onRemove(record.target);
                        }}
                    />
                </>
              )}
            />
          </Table>
        </Col>

        <Modal
          visible={isModal}
          title={"Edit Arithmetic"}
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
           <EditArithmetic
            data={field}
            onClose={() => {
              setIsModal(false);
            }}
            onSave={onSaveItem}
          />
        </Modal>
    </Row>
  );
};


const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const ArithmeticRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const [list, setList] = useState<ItemArithmeticModel[]>([]);
    const message = ""

    useEffect(() => {
        form.setFields([
            {
              name: "type",
              value: "Arithmetic",
            },
            {
                name: "name",
                value: props.label,
            },
        ])
        console.log(props.data)
        let newList = [] as ItemArithmeticModel[]
        if (props.data && props.data.operators) {
            for(let key of Object.keys(props.data.operators)) {
                console.log(key)
                newList.push({
                    target: key,
                    source: props.data.operators[key]
                })
            }
        }
        setList(newList)
    }, [props, form])

    const onFinish = (values: any) => {
        const operators = {} as any
        for(const item of list) {
            operators[item.target] = item.source
        }
        props.save(props.id, {
            option: {
                operators: operators,
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    const onChangeList = (updateList: ItemArithmeticModel[]) => {
        setList(updateList)
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <EditItemArithmetic list={list} onChange={onChangeList}/>
            </Col>
        

            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const ArithmeticRuleInfo: RuleInfo = {
    id: "arithmetic",
    name: "Arithmetic",
    rule: ArithmeticRule,
    option: ArithmeticRuleOption,
    infinite: false,
    option_default: {
        operators: {},
    },
}

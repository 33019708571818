import React from 'react'

type P = {
    className?: any
    style?: React.CSSProperties | undefined
    width?:  string
    height?:  string
}
const Logo: React.FC<P> = (props: P) => {

    let { width, height } = props;
    if (!height) {
        height = "50px";
    }
    if (!width) {
        width = "50px";
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1600 1600" width={width} height={height}>
            <path transform="translate(805,501)" d="m0 0h31l8 2 27 1 6 2 2 1 17 2 4 2 12 3 15 6 34 17 15 9 16 10 11 8 10 9 14 12 12 12 8 10 8 8 7 10 12 18 12 20 12 22 9 21 10 29 2 6 2 10 2 7 1 12 2 4 1 6v76l-1 11-2 4-2 16-7 24-7 19-7 16-11 19-12 19-9 14-8 12-6 7-6 9-8 9-9 11-9 9-11 9-13 12-10 7-11 8-23 13-16 8-12 5-13 5-21 6-5 2-12 3-10 2-7 1-2 2-19 1h-47l-18-1-35-7-19-6-13-4-25-12-21-11-11-7-14-9-12-8-12-11-9-7-8-7-7-8-8-10-9-9-9-12-11-16-10-15-9-15-12-23-7-15-6-19-2-5-2-9-2-7-1-11-2-3-1-5-1-30v-28l1-36 3-1 1-7v-10l4-5v-10l3-5 2-10 5-12 9-22 13-26 8-12 6-11 8-11 9-11 12-12 4-7 19-19 12-8 1-2h2l2-4 30-20 18-11 17-9 10-4 6-2 3-2 8-1 4-3 8-1 2-2 14-2 2-2 15-2 4-2 39-3z" fill="#36CAFD" />
            <path transform="translate(751,79)" d="m0 0h21v343l-6 2-27 3-5 2-15 2-36 12-20 8-21 10-9 4-24 13-20 15-5 4-5 3-13 10-9 7-17 17-11 10-5 5-7 8-7 7-9 13-7 8-7 11-9 14-8 13-5 7h-370l-1-1 1-16 9-29 9-25 14-38 12-28 10-19 15-25 6-11 12-20 7-11 8-11 14-20 4-7h2l2-4 6-7 4-6 9-10 8-9 5-8h2l2-4 46-46 11-9 10-8 10-9 9-6 11-10 6-3 9-7 11-8 9-6 5-4 6-3 9-7 15-9 13-8 21-11 13-8 22-11 25-11 19-8 17-6 26-9 6-1-1-6 11-1v2l16-3 9-3 9-2 8-2 30-6 20-3 7-2 22-2 7-2 25-2z" fill="#4AF4FD" />
            <path transform="translate(856,82)" d="m0 0 48 1 5 2 29 2 7 2 18 2 6 2 14 2 5 2 21 5 23 7 29 10 30 12 28 12 15 7 17 8 17 10 12 6 23 14 42 28 19 14 13 11 11 8 8 8 14 11 13 12 51 51 10 14 8 9 8 11 10 14 8 11 10 15 11 17 12 20 15 26 17 33 11 26 20 55 10 30 4 11v3l-6 1h-365l-5-5-9-15-9-14-11-15-7-10-7-8-41-41-10-6-7-7-13-9-8-6-10-7-17-10-8-5-8-4-19-10-20-9-18-7-27-8-17-4-21-3-9-2-1-1v-338z" fill="#A7FC75" />
            <path transform="translate(99,1044)" d="m0 0h384l5 5 7 9 6 8 7 9 6 8 10 10 7 11 8 8 16 12 11 11 15 11 12 9 24 14 46 23 22 8 18 5 18 4 17 3 33 2 1 1v349l-16-1-46-4-38-5-47-9-28-7-28-8-23-8-27-11-34-15-38-18-21-12-45-30-5-4-7-4v-2l-4-2-18-14-10-10-8-5-13-13-14-11-13-12-50-50-7-10-13-14-5-8-1-3h-2l-7-11-7-8-3-6-7-9-11-18-12-21-11-19-9-19-4-9-12-26-10-24-9-24-7-22-4-11z" fill="#37CBFD" />
            <path transform="translate(1144,1044)" d="m0 0h370l3 1v7l-6 20-13 35-11 29-10 21-15 25-11 19-6 12-12 20-8 12-12 16-6 7-8 11-8 9-8 11-16 16-7 10-11 12-50 50-11 9-10 8-16 13-15 11-10 7-15 10-20 12-7 5-7 4-27 18-16 7-37 17-19 8-17 6-24 8-22 7-24 7-22 5-34 6-30 4-12 1-39 1h-5l-1-1-1-230-3-4v-108l6-3 5-1 20-1 12-2 21-6 25-9 24-10 24-12 18-11 16-9 7-6 9-6 14-10 8-7 11-8 39-39 7-11z" fill="#6FF863" />
            <path transform="translate(797,651)" d="m0 0h33l23 4 16 5 18 8 14 8 16 12 10 9 11 12 14 21 10 18 9 20 6 22 3 13 1 8v26l-4 22-7 21-9 20-12 20-9 11-12 13-10 9-16 12-16 8-15 6-21 6-19 3h-27l-21-3-20-5-16-7-21-11-18-12-13-10-8-8-9-11-9-14-8-16-6-14-6-20-3-15-1-7v-27l2-19 5-21 6-18 9-19 8-12 9-11 18-18 20-14 18-10 21-8 25-5z" fill="#1D2634" />

            <path transform="translate(63,875)" d="m0 0h347l3 5 3 14 5 15 3 17 22 44v3h-366l-5-11-4-15-1-10-3-6-1-22-3-6z" fill="#37CBFE" />
            <path transform="translate(68,705)" d="m0 0h361v3l-3 7-4 15-3 10-2 11-2 4-2 12-2 3-1 17-2 5-1 1-1 10-2 1h-344l-1-1v-45l3-3 1-40 3-4z" fill="#37CBFE" />
            <path transform="translate(1210,879)" d="m0 0h346v14l-2 7-2 33-2 2-5 25-3 10-2 3h-358l-1-3 5-13 8-19 7-23 4-16 2-11z" fill="#6FF963" />
            <path transform="translate(1198,709)" d="m0 0h352l2 1 1 37v56l-2 1h-332l-5-9-1-7-1-13-6-18-2-14-6-19z" fill="#6FF863" />
        </svg>
    )
}

export default Logo;
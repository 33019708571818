import { Alert, Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useState } from "react";
import { InputRuleNode } from "src/types";
import { RuleInfo, makeNodeId } from "../rule";
import { InputModelRuleInfo } from "../rule-engine/input-model";
import { ArithmeticRuleInfo } from "../rule-engine/arithmetic";
import { ConditionalRuleInfo } from "../rule-engine/conditional";
import { ForkRuleInfo } from "../rule-engine/fork";
import { SwitchRuleInfo } from "../rule-engine/switch";
import { LogRuleInfo } from "../rule-engine/log";
import { OutputActionRuleInfo } from "../rule-engine/output-action";
import { InpuTelemetryRuleInfo } from "../rule-engine/input-telemetry";
import { GetLastModelRuleInfo } from "../rule-engine/get-last-mode";
import { OutputModelRuleInfo } from "../rule-engine/output-model";
import { FormatRuleInfo } from "../rule-engine/format-rule";
import { DelayRuleInfo } from "../rule-engine/delay-rule";


const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

const listRuleInfo: RuleInfo[] = [
    InputModelRuleInfo,
    ArithmeticRuleInfo,
    ConditionalRuleInfo,
    ForkRuleInfo,
    SwitchRuleInfo,
    LogRuleInfo,
    OutputActionRuleInfo,
    InpuTelemetryRuleInfo,
    GetLastModelRuleInfo,
    OutputModelRuleInfo,
    FormatRuleInfo,
    DelayRuleInfo,
]


const CreateRuleNode: React.FC<{
    chanId: string | undefined
    onCreate: (data: InputRuleNode) => void
    onClose: () => void
}> = (props) => {


    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const [message, ] = useState("");

    const onFinish = async (values: any) => {

        try {
          setLoading(true);

          let rule: RuleInfo | undefined
          listRuleInfo.forEach(item => {
            if (item.id === values.rule_id) {
                rule = item
            }
          })
          if  (!rule) throw new Error(`No such rule info ${values.rule_id}`)

         const inputNode =  {
            debug: false,
            end: false,
            infinite: rule.infinite,
            name: values.name,
            node_id: makeNodeId(),
            rule_id: rule.id,
            option: rule.option_default,
            connect_to: [],
        }
    
        //   res = await dispatch<any>(
        //     updateRuleNodeThunkAction({
        //         chanId: props.chanId,
        //         input: inputNode,
        //     })
        //   );
    
          setLoading(false);
    
        //   if (res.meta.requestStatus === RequestStatus.Fulfilled) {
            props.onCreate(inputNode);
        //     selectMessage("")
        //   } else {
        //     selectMessage(res.error.message);
        //   }
          
        //   console.log(res);
        } catch (error) {
          console.log(error);
        }
    }

    const onClose = () => {
        props.onClose();
    }

    return (
 
        <Spin tip="Proccess..." spinning={loading}>
            <Form {...layout} onFinish={onFinish} form={form}>
                <Row gutter={[10, 10]}>
                {message !== "" && (
                    <Col span={24}>
                        <Alert type="error" message={message}></Alert>
                    </Col>
                )}
                <Col span={24}>
                    <Form.Item
                    name="name"
                    label="Name"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: "Please input name!" }]}
                    >
                    <Input size="large" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name="rule_id"
                    label="Rule Node"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: "Please rule node type!" }]}
                    >
                    <Select size="large"
                        options={listRuleInfo.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }))}
                        filterOption={true}
                        showSearch={true}
                    >
                        {
                            listRuleInfo.map((item) => {
                                return (<Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>)
                            })
                        }
                    </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Row justify="center" gutter={[10, 10]}>
                        <Col style={{ width: 100 }}>
                            <Input size="large" type="submit" value="Save" />
                        </Col>
                        <Col>
                            <Button size="large" style={{ width: 100 }}
                                onClick={() => {
                                    onClose();
                                }} 
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Col>
                </Row>
            </Form>
        </Spin>
    )
}


export default CreateRuleNode;
import { Button, Drawer, Modal, Result, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { RequestStatus, ThunkResult, useAppDispatch, useAppSelector } from "src/app/hooks";
import { useCallback } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';

import 'reactflow/dist/style.css';
import ToolsBoxRuleChan from "./tools-box";
import { InpuTelemetryRuleInfo } from "./rule-engine/input-telemetry";
import { OutputModelRuleInfo } from "./rule-engine/output-model";
import OutputAlertRule from "./rule-engine/output-alert";
import { InputModelRuleInfo } from "./rule-engine/input-model";
import { ArithmeticRuleInfo } from "./rule-engine/arithmetic";
import { ConditionalRuleInfo } from "./rule-engine/conditional";
import { ForkRuleInfo } from "./rule-engine/fork";
import { LogRuleInfo } from "./rule-engine/log";
import { OutputActionRuleInfo } from "./rule-engine/output-action";
import { SwitchRuleInfo } from "./rule-engine/switch";
import OutputKafkaRule from "./rule-engine/output-kafka";
import CreateModelRule from "./rule-engine/create-model";
import OptionEngine from "./option-engine/option-engine";
import { LinkNode, RuleSelect, getRandomInt } from "./rule";
import { getDetailRuleChanThunkAction, updateRuleNodesThunkAction } from "../thunk";
import CreateRuleNode from "./option-engine/create-rule-node";
import { selectListNode, selectOptionNodes } from "../slice";
import { InputRuleNode } from "src/types";
import { GetLastModelRuleInfo } from "./rule-engine/get-last-mode";
import { FormatRuleInfo } from "./rule-engine/format-rule";
import { DelayRuleInfo } from "./rule-engine/delay-rule";
import { ResultStatusType } from "antd/lib/result";

const { Text } = Typography;

const nodeTypes = {
  [InputModelRuleInfo.id] : InputModelRuleInfo.rule,
  [InpuTelemetryRuleInfo.id]: InpuTelemetryRuleInfo.rule,
  [OutputModelRuleInfo.id]: OutputModelRuleInfo.rule,
  "output-alert": OutputAlertRule,
  [OutputActionRuleInfo.id]: OutputActionRuleInfo.rule,
  "output-kafka": OutputKafkaRule,
  [ArithmeticRuleInfo.id]: ArithmeticRuleInfo.rule,
  [ConditionalRuleInfo.id]: ConditionalRuleInfo.rule,
  [ForkRuleInfo.id]: ForkRuleInfo.rule,
  [LogRuleInfo.id]: LogRuleInfo.rule,
  [SwitchRuleInfo.id]: SwitchRuleInfo.rule,
  [GetLastModelRuleInfo.id]: GetLastModelRuleInfo.rule,
  "create-model":  CreateModelRule,
  [FormatRuleInfo.id]: FormatRuleInfo.rule,
  [DelayRuleInfo.id]: DelayRuleInfo.rule,
};

export const EditRuleChan: React.FC<{
  ruleChanId: string | undefined
  onBack?: () => void
}> = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isModal, setIsModal] = useState(false);
  const [ruleSelect, setRuleSelect] = useState<RuleSelect| undefined>()

  const dispatch = useAppDispatch();

  const { ruleChanId } = props

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch<any>(getDetailRuleChanThunkAction(ruleChanId as string));
  }, [ruleChanId, dispatch])

  const listNode = useAppSelector(selectListNode)
  const optionNodes = useAppSelector(selectOptionNodes)

  const [isModalCreate, setIsModalCreate] = useState<boolean>(false);

  const [debug, setDebug] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [modalSaveText, setModalSaveText] = useState('Save Rule');
  const [status, setStatus] = useState<ResultStatusType>("warning");

  const actionSave = async () => {
    let inputs : InputRuleNode[] = []

    for(let node of nodes) {
      const connectTo =  edges.filter(e=> e.target === node.id || e.source === node.id).map(e=> JSON.stringify(e))

        const option = {}
        if (optionNodes[node.id] !== undefined){  
          Object.assign(option, optionNodes[node.id])
        }

        inputs.push({
          debug: debug,
          end: false,
          infinite: false,
          name: node.data.label as string,
          node_id: node.id,
          option: {
            ...option,
            position: node.position
          },
          connect_to: connectTo,
          rule_id: node.type as string,
        })
      }

      setLoading(true);
      setStatus("info")
      try {
        let res: ThunkResult<boolean, any>;
        res = await dispatch<any>(
        updateRuleNodesThunkAction({
            chanId: ruleChanId,
            inputs: inputs as InputRuleNode[],
        })
      );

     
      if (res.meta.requestStatus === RequestStatus.Fulfilled) {
        setStatus("success")
        setModalSaveText("Save Rule Chan Success")
      } else {
        setStatus("error")
        setModalSaveText("Save Rule Chan have error")
      }
    } catch(e) {
      console.error(e)
      setStatus("error")
      setModalSaveText("Save Rule Chan have error")
    } finally {
      setLoading(false);
    }
  }



  const onConnect = useCallback((params: any) => {
    console.log(params);

    const link = {
      id: `${params.target}-${params.source}-${params.sourceHandle}-${params.targetHandle}`,
      sourceHandle: params.sourceHandle,
      targetHandle: params.targetHandle,
      target: params.target,
      source:  params.source,
      animated: true,
    }
    setEdges((eds) => addEdge(link, eds))
    
  }, [setEdges]);

  const onClickNode = useCallback((_ , params: any) => {
    let links = [] as LinkNode[];
    links = edges.filter(item => item.target === params.id || item.source === params.id).map((item) => {
      let type = "input"
      let key = item.targetHandle || ""
      let node = ""

      let idFind = ""
      let type_node: string | undefined = "" 

      if (item.target !== params.id) {
        type = "output"
      }

      console.log(item)

      if (type === "output")  {
        key = item.targetHandle || ""
        idFind = item.target
      } else {
        idFind = item.source
        key = item.sourceHandle || ""
      }
      
      if (nodes && Array.isArray(nodes)) {
        let nodeInfo =  nodes.find(e => e.id === idFind)
        if (nodeInfo) {
          node = nodeInfo.data.label
          type_node = nodeInfo.type
        }
      }
    
      return {
        id: item.id, 
        node: node,
        type_node: type_node,
        type: type, 
        key: key
      }
    }).sort(function(a, b){return a.type > b.type ? 1 : -1})

    setRuleSelect({
      ...params,
      links: links
    })
    setIsModal(true)
  }, [edges, nodes]);

  const onCreateNode = (data: InputRuleNode) => {
    setIsModalCreate(false);

    setNodes([...nodes, {
      id: data.node_id,
      type: data.rule_id,
      data: { label: data.name, },
      position: { x: 0, y: 50 },
    }])

  }

  const onSaveName = (id: string, name: string) => {
    console.log("save ", id, name)
    const newNodes = nodes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          data: {
            ...item.data,
            label: name
          }
        }
      }
      return item
    })
    setNodes(newNodes)
    setIsModal(false)
  }

  const onActionMenu = async (action: string) => {
    switch (action) {
      case "create": {
        setIsModalCreate(true);
        break;
      }
      case "save": {
        setDebug(false);
        setModalSaveText('Do you want save rule chan!');
        setOpenSave(true);
        setStatus("warning");
        break;
      }
      case "debug": {
        setDebug(true);
        setModalSaveText('Do you want save rule chan with debug!');
        setOpenSave(true);
        setStatus("warning");
        break;
      }
      case "back": {
        if (props.onBack) {
          props.onBack();
        }
        break;
      }
    }
  }


  useEffect(() => {
    if (listNode) {
      const nodes = listNode.map((item => {
        item.connect_to?.forEach(item => {
          if (!item) {
            return {}
          }
          setEdges((eds) => addEdge(JSON.parse(item), eds))
        })

      

        return  {
          id: item.node_id,
          type: item.rule_id,
          data: { label: item.name, },
          position: item.option.position || { x: getRandomInt(100), y: getRandomInt(100) },
        }
      }))

      setNodes(nodes)
    }

  }, [listNode,setEdges,setNodes])

  const onDeleteNode = () => {
    const newNodes = nodes.filter(item => item.id !== ruleSelect?.id)
    setNodes(newNodes)

    const newEdges = edges.filter(item => item.source !== ruleSelect?.id && item.target !== ruleSelect?.id)
    setEdges(newEdges)

    setIsModal(false);
  }

  const onRemoveLink = (nodeId: string, linkId: string) => {
    const newEdges = edges.filter(item => item.id !== linkId)
    setEdges(newEdges)
  }

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        fitView
        attributionPosition="bottom-left"
        onNodeClick={onClickNode}
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
      <ToolsBoxRuleChan onAction={onActionMenu} />

      <Drawer
        title={
        <>
          Option Rule <Text style={{ fontSize: "18px", fontWeight: 800 }} code>{ ruleSelect?.type }</Text>
        </>}
        placement="right"
        width={600}
        closable={true}
        visible={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        extra={
          <Space>
            <Button type="default" onClick={onDeleteNode} danger>
              Delete Node
            </Button>
          </Space>
        }
      >
        {isModal && (
          <OptionEngine
            select={ruleSelect}
            onClose={() => { setIsModal(false) }}
            onRemoveLink={onRemoveLink}
            onSave={onSaveName}
          />
        )}
      </Drawer>

      <Modal
        visible={isModalCreate}
        title={"Create Rule Node"}
        maskClosable={false}
        onCancel={() => {
          setIsModalCreate(false);
        }}
        footer={null}
        closable={false}
      >
        <CreateRuleNode chanId={props.ruleChanId} onCreate={onCreateNode} onClose={()=> { setIsModalCreate(false) } }/>
      </Modal>

      <Modal
        width={550}
        open={openSave}
        closable={false}
        footer={null}
      > 
          <Spin spinning={loading}>
            <Result 
              status={status}
              title={<>
                {
                  modalSaveText
                }
              </>}
                extra={
                <>
                  {
                    (status === "warning") && (
                      <>
                        <Button type="primary" key="console" onClick={() => {
                          setOpenSave(false);
                        }}>
                          Cancel
                        </Button>
                        <Button type="primary" danger key="save" onClick={actionSave}>
                          Save Change
                        </Button>
                      </>
                    )
                  }
                  {
                    (status !== "warning") && (
                      <>
                        <Button type="primary" key="cancel" onClick={() => {
                          setOpenSave(false);
                        }}>
                          Close
                        </Button>
                      </>
                    )
                  }
                </>
                }
            />
          </Spin>
        </Modal>
        
        
    </>
  );
};

export default EditRuleChan;

import { Col, Form, Input, Row } from 'antd'
import { isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { selectFilter, setFilter } from '../slice'

const FilterNamespace: React.FC<{}> = (props) => {
    const filter = useAppSelector(selectFilter)
    const dispatch = useAppDispatch()
    const [formData] = Form.useForm<any>()
    const [styleInput, setStyleInput] = useState<any>({})


    const onSearch: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        const search = formData.getFieldValue("search")
        if (event.keyCode === 13) {
            dispatch(setFilter({ ...filter, search }))
        }
        getStyle(search)
    }

    const getStyle = (search: string) => {
        if (!(isUndefined(filter.search) && search === "")) {
            if (filter.search !== search) {
                setStyleInput({ background: "#ffb6b6" })
                return
            }
        }
        setStyleInput({})
    }

    useEffect(() => {
        getStyle(formData.getFieldValue("search"))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    return (
        <Form form={formData}>
            <Row gutter={10}>
                <Col>
                    <Form.Item name="search" style={{margin: 0}}>
                        <Input placeholder='Search' onKeyUp={onSearch} style={styleInput} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

}

export default FilterNamespace;
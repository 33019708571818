import { Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { WidgetOption } from "../../../models/widget";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const Edit: React.FC<{
  option: WidgetOption;
  change: (option: WidgetOption) => void;
}> = (props) => {
  const [form] = Form.useForm<any>();
  const { option }= props

  const onChange = async () => {
    const values = await form.validateFields();
    props.change(values);
  };

  useEffect(() => {
    form.setFieldsValue(option)
  },[option, form] )

  return (
    <Form {...layout} form={form} onChangeCapture={onChange}>
      <Row style={{ paddingTop: "10px" }}>
        <Col span={24}>
          <Form.Item label="Source Data Key" name="data_key">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="X field" name="x_field">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Y field" name="y_field">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Series Field" name="series_field">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Edit;

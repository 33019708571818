import React, { Component } from "react";
import { Table } from "antd";
import { WidgetData } from "src/modules/widget/models/data";
import { WidgetOption } from "src/modules/widget/models/widget";

type P = {
    data: WidgetData
    option:  WidgetOption
}

type S = {

}

class ViewTable extends Component<P, S>{
    state = {}
    render (){
        return (
            <Table>

            </Table>
        )
    }
}

export default ViewTable;
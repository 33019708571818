import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceLogByTimeQueryVariables = Types.Exact<{
  input: Types.TimeInput;
}>;


export type GetDeviceLogByTimeQuery = { __typename?: 'Query', getDeviceLogByTime: { __typename?: 'ListDeviceLog', data?: Array<{ __typename?: 'DeviceLog', device_id: any, key: string, value: string, time: any, type: Types.TypeDeviceLog, device?: { __typename?: 'Device', id: any, key: string, name: string } | null }> | null } };


export const GetDeviceLogByTimeDocument = gql`
    query getDeviceLogByTime($input: TimeInput!) {
  getDeviceLogByTime(input: $input) {
    data {
      device_id
      key
      value
      time
      type
      device {
        id
        key
        name
      }
    }
  }
}
    `;

/**
 * __useGetDeviceLogByTimeQuery__
 *
 * To run a query within a React component, call `useGetDeviceLogByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceLogByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceLogByTimeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeviceLogByTimeQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceLogByTimeQuery, GetDeviceLogByTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceLogByTimeQuery, GetDeviceLogByTimeQueryVariables>(GetDeviceLogByTimeDocument, options);
      }
export function useGetDeviceLogByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceLogByTimeQuery, GetDeviceLogByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceLogByTimeQuery, GetDeviceLogByTimeQueryVariables>(GetDeviceLogByTimeDocument, options);
        }
export type GetDeviceLogByTimeQueryHookResult = ReturnType<typeof useGetDeviceLogByTimeQuery>;
export type GetDeviceLogByTimeLazyQueryHookResult = ReturnType<typeof useGetDeviceLogByTimeLazyQuery>;
export type GetDeviceLogByTimeQueryResult = Apollo.QueryResult<GetDeviceLogByTimeQuery, GetDeviceLogByTimeQueryVariables>;
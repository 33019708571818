import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  selectListWidgetSearch,
  selectLoading,
  selectDetail,
} from "../slice";
import {
  getSearchWidgetThunkAction,
  getWidgetByIdThunkAction,
} from "../thunk";

const SelectWidget: React.FC<{
  disabled: boolean;
  name: string;
  label?: string;
  placeholder?: string;
  rules?: Rule[] | undefined;
  onChange?: (value: string) => void;
  value?: string;
}> = (props) => {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const listWidget = useAppSelector(selectListWidgetSearch);
  const detail = useAppSelector(selectDetail);
  const { value } = props;

  const [text, setText] = useState("");

  useEffect(() => {
    dispatch<any>(
      getSearchWidgetThunkAction({
        limit: 100,
        skip: 0,
        search: text,
      })
    );
  }, [text, dispatch]);

  useEffect(() => {
    if (value) {
      dispatch<any>(getWidgetByIdThunkAction(value));
    }
  }, [value, dispatch]);

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Select
        onSearch={(value: string) => {
          setText(value);
        }}
        size="large"
        disabled={props.disabled}
        loading={loading}
        showSearch
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
      >
        {listWidget?.filter(item => item.id !== detail?.id).map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
        {detail && (
          <Select.Option value={detail.id}>{detail.name}</Select.Option>
        )}
      </Select>
    </Form.Item>
  );
};

export default SelectWidget;

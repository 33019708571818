import {
  BarsOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import Column from "antd/lib/table/Column";
import React, { useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { Widget } from "src/types";
import { selectListWidget } from "../slice";

const ListWidget: React.FC<{
  action: (widget: Widget | null, action: string) => void;
}> = (props) => {

  const widgets = useAppSelector(selectListWidget);

  const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);
  const [item, setSelectitem] = useState<any | null>(null);

  const onMenu = (info: { key: string }, row: Widget) => {
    setSelectitem(row);
    switch (info.key) {
      case "delete": {
        setIsModalVisibleDel(true);
        return;
      }
      default: {
        props.action(row, info.key);
      }
    }
  };

  const handleOkDelete = () => {
    if (item != null) {
      props.action(item, "delete");
    }
    setIsModalVisibleDel(false);
  };

  return (
    <>
        <Row gutter={[0, 5]}>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Row gutter={[5, 5]}>
              <Col>
                <Button
                  onClick={() => {
                    props.action(null, "new");
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              dataSource={
                widgets != null && widgets?.data != null
                  ? (widgets.data as any[])
                  : []
              }
              size="middle"
              rowKey={"id"}
            >
              <Column
                title="Name"
                dataIndex="name"
                key="name"
                render={(text: any, row: Widget) => (
                  <Button
                    type="link"
                    onClick={() => {
                      props.action(row, "detail");
                    }}
                  >
                    {text}
                  </Button>
                )}
              />
              <Column
                title=""
                key="action"
                width={10}
                render={(text: any, record: any) => (
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={(info) => {
                          onMenu(info, record);
                        }}
                      >
                        <Menu.Item key="detail" icon={<BarsOutlined />}>
                          Detail
                        </Menu.Item>
                        <Menu.Item key="edit" icon={<EditOutlined />}>
                          Edit
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="delete" icon={<DeleteOutlined />}>
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Button
                      type="text"
                      icon={<MoreOutlined />}
                      onClick={(e) => e.preventDefault()}
                    />
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
      <Modal
        title="Delete Ui"
        visible={isModalVisibleDel}
        onOk={handleOkDelete}
        onCancel={() => {
          setIsModalVisibleDel(false);
        }}
      >
        <p>
          You do want to delete widget <Tag color={"blue"}>{item?.name}</Tag>!
        </p>
      </Modal>
    </>
  );
};

export default ListWidget;

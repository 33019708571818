import { Spin, Table } from "antd";
import Column from "antd/lib/table/Column";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { ThunkResult, useAppDispatch } from "src/app/hooks";
import Time from "src/components/time";
import { ModelLog, TypeField } from "src/types";
import { GetModelLogLastTimeQuery } from "../graph/getModelLogLastTime.generated";
import { getModelLogLastTimeThunkAction } from "../thunk";

const ModelLastTime: React.FC<{
  deviceId: string;
  modelId: string;
  showType?: boolean;
  showDevice?: boolean;
  showmModel?: boolean;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ModelLog[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setList([]);
    let loadData = async (deviceId: string, modelId: string) => {
      setLoading(true);
      try {
        const res: ThunkResult<GetModelLogLastTimeQuery, any> =
          await dispatch<any>(
            getModelLogLastTimeThunkAction({
              device_id: deviceId,
              model_id: modelId,
            })
          );

        if (
          res.payload.getModelLogLastTime !== null &&
          !isUndefined(res.payload.getModelLogLastTime.data)
        ) {
          setList(res.payload.getModelLogLastTime.data as ModelLog[]);
        }
      } catch (error) {
        console.error(error);
      }

      setLoading(false);
    };
    loadData(props.deviceId, props.modelId);
  }, [props, dispatch]);

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Table dataSource={list} size="middle" rowKey={"key"} pagination={false}>
        <Column title="Key" dataIndex="key" key="key" />
        <Column
          title="Value"
          dataIndex="value"
          key="value"
          render={(value: any, row: ModelLog) => {
            if (row.field_model && row.field_model.type === TypeField.Number) {
              return row.value_n;
            }
            return row.value;
          }}
        />
        {props.showType && (
          <Column
            width={120}
            title="Type"
            dataIndex="type"
            key="type"
            render={(value: any, row: ModelLog) => {
              return row.field_model?.type;
            }}
          />
        )}
        {props.showDevice && (
          <Column
            width={100}
            title="Device Name"
            dataIndex="device_name"
            key="device_name"
            render={(text: any, record: ModelLog) => {
              if (!record.device) {
                return "--";
              }

              return record.device.name;
            }}
          />
        )}
        {props.showmModel && (
          <Column
            width={100}
            title="Model Name"
            dataIndex="model_name"
            key="model_name"
            render={(text: any, record: ModelLog) => {
              if (!record.model) {
                return "--";
              }

              return record.model.name;
            }}
          />
        )}
        <Column
          width={190}
          title="Time"
          dataIndex="time"
          key="time"
          render={(text: any, record: ModelLog) => (
            <Time second={true} data={record.time} />
          )}
        />
      </Table>
    </Spin>
  );
};

export default ModelLastTime;

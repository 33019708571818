import { Gauge, GaugeConfig } from '@ant-design/plots';
import { isUndefined } from 'lodash';
import React, { useRef } from "react";
import { WidgetData } from 'src/modules/widget/models/data';
import { WidgetOption } from 'src/modules/widget/models/widget';


const View: React.FC<{
  data: WidgetData
  option: WidgetOption
  height?: number
  width?: number
}> = (props) => {

  let {  data, option, height } = props

  const chartRef = useRef();
  
  const formatterValue = (v: Number, total: Number) => {
    let rs = ""
    var percent = v
    if (option["text"]) {
      rs = `${option["text"]} ${percent}`
    } else {
      rs = `${percent}`
    }

    if (option["unit"]) {
      rs = `${rs} ${option["unit"]}`
    }
    return rs;
  }

  const style = {
    textContent: formatterValue,
    size: {
      itemLabelFontSize: 10
    },
  }

 const  scale = {
    color: {
      range: [option["color"]|| "green"],
    },
  } 

  let target: Number = 0;
  if (!isUndefined(data.list) && data.list != null && data.list.length > 0) {
    const item = data.list[data.list.length - 1] as any;
    const val = item[option["data_val"]];
    if ( val && !isUndefined(val)) {
      target = parseFloat(`${val}`);
    }
  }

  const config: GaugeConfig = {
    autoFit: true,
    data:  {
      target: target,
      total:  100,
      name: 'score',
    },
    legend: false,
    style: style,
    scale: scale,
    padding: 'auto',
    height: height,
  };

  return (
    <Gauge {...config} chartRef={chartRef} />
  );
}

export default View;
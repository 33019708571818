import { Button, Card, Col, Dropdown, Menu, Row, Spin, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import {
  selectFilter,
  selectListRuleChan,
  selectLoading,
} from "../slice";
import { getListPaginationRuleChanThunkAction } from "../thunk";
import { Chan } from "src/types";
import { BarsOutlined, DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";


const ListRuleChan: React.FC<{
  onAction: (action: string, item: Chan) => void;
  refLoading: (callback: () => void) => void;
}> = (props) => {
  const filter = useSelector(selectFilter);
  const dispatch = useAppDispatch();

  const list = useAppSelector(selectListRuleChan);
  const loading = useAppSelector(selectLoading);

  useEffect(() => {
    dispatch<any>(getListPaginationRuleChanThunkAction(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);


  const onMenu = (info: any, record: Chan) => {

  }


  return (
    <>
    <Card size="small">
    <Row gutter={[10,10]}>
      <Col span={24}>
       
      </Col>
      <Col span={24}>
        <Spin tip="Loading..." spinning={loading}>
          <Table dataSource={list} size="middle" rowKey={"id"}>
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              render={(text: any, row: Chan) => (
                <Button
                  type="link"
                  onClick={() => {
                    props.onAction("update_rule_chan", row);
                  }}
                >
                  {text}
                </Button>
              )}
            />
             <Column
              width={100}
              title="Status"
              dataIndex="status"
              key="status"
            />
            <Column
              width={200}
              title="Create Time"
              dataIndex="craeted_at"
              key="craeted_at"
              render={(text: any, record: Chan) => (
                <Time second={true} data={record.created_at} />
              )}
            />
             <Column
            title=""
            key="action"
            width={10}
            render={(text: any, record: any) => (
              <Dropdown
                overlay={
                  <Menu
                    onClick={(info) => {
                      onMenu(info, record);
                    }}
                  >
                    <Menu.Item key="detail" icon={<BarsOutlined />}>
                      Detail
                    </Menu.Item>
                    <Menu.Item key="edit" icon={<EditOutlined />}>
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>
            )}
          />
          </Table>
        </Spin>
      </Col>
    </Row>
    </Card>
    </>
  );
};

export default ListRuleChan;

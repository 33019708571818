import { Breadcrumb, Col, Row } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Widgets from "src/modules/widget/components/widgets";

const WidgetPage: React.FC<{}> = (props) => {

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - List Widget</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/ui"}>Ui</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Widget</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Widgets onAction={() => {}} refLoading={() => {}} />
        </Col>
      </Row>
    </div>
  );
};

export default WidgetPage;

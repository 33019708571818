import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import { DefaultOptionType } from "antd/lib/select";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectModelDevices } from "../slice";
import { geListModeIdHaveLogByDeviceIdThunkAction } from "../thunk";


const SelectModelDevice: React.FC<{
  deviceId: string;
  name: string;
  rules?: Rule[] | undefined;
  disabled?: boolean;
  label?: string
  onChange?: (field: string, option: DefaultOptionType | DefaultOptionType[]) => void
  showDevice?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const list = useAppSelector(selectModelDevices);
  const { deviceId } = props

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await dispatch<any>(
        geListModeIdHaveLogByDeviceIdThunkAction({
          deviceId: deviceId,
        })
      );
      setLoading(false);
    };
    loadData();
  }, [dispatch, deviceId ]);

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Select
        size="large"
        disabled={props.disabled}
        loading={loading}
        placeholder="Select Model"
        onChange={props.onChange}
      >
        {list.map((item) => {
          return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>;
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectModelDevice;

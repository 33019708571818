import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationModelQueryVariables = Types.Exact<{
  input: Types.ListPaginationModelInput;
}>;


export type GetListPaginationModelQuery = { __typename?: 'Query', getListPaginationModel: { __typename?: 'ListPaginationModel', limit: number, skip: number, total: number, data?: Array<{ __typename?: 'Model', id: any, name: string, description?: string | null, status: Types.StatusModel, created_by?: any | null, updated_by?: any | null, created_at: any, updated_at: any, created_by_user?: { __typename?: 'UserBaseView', email: string, name: string } | null }> | null } };


export const GetListPaginationModelDocument = gql`
    query getListPaginationModel($input: ListPaginationModelInput!) {
  getListPaginationModel(input: $input) {
    limit
    skip
    total
    data {
      id
      name
      description
      status
      created_by
      updated_by
      created_at
      updated_at
      created_by_user {
        email
        name
      }
    }
  }
}
    `;

/**
 * __useGetListPaginationModelQuery__
 *
 * To run a query within a React component, call `useGetListPaginationModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationModelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationModelQuery(baseOptions: Apollo.QueryHookOptions<GetListPaginationModelQuery, GetListPaginationModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationModelQuery, GetListPaginationModelQueryVariables>(GetListPaginationModelDocument, options);
      }
export function useGetListPaginationModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationModelQuery, GetListPaginationModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationModelQuery, GetListPaginationModelQueryVariables>(GetListPaginationModelDocument, options);
        }
export type GetListPaginationModelQueryHookResult = ReturnType<typeof useGetListPaginationModelQuery>;
export type GetListPaginationModelLazyQueryHookResult = ReturnType<typeof useGetListPaginationModelLazyQuery>;
export type GetListPaginationModelQueryResult = Apollo.QueryResult<GetListPaginationModelQuery, GetListPaginationModelQueryVariables>;
import { Col, DatePicker, Form, Row } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import { TypeDeviceLog } from "src/types";
import { selectFilter, setFilter } from "../../slice";
import SelectField from "../select-field";
import moment from "moment";

const { RangePicker } = DatePicker;

const FilterLog: React.FC<{
  deviceId: number;
  type: TypeDeviceLog;
}> = (props) => {
  const [formData] = Form.useForm<any>();
  const filter = useSelector(selectFilter);
  const dispatch = useAppDispatch();

  const onOk = (value: RangePickerProps["value"]) => {
    if (value != null) {
      const [startTime,endTime] = value;
      if (startTime && endTime ) {
        dispatch<any>(
          setFilter({
            ...filter,
            start_time: startTime.format("YYYY-MM-DD HH:mm:ss"),
            end_time: endTime.format("YYYY-MM-DD HH:mm:ss"),
          })
        );
      }
    
    }
  };

  const onChangeFields = (fields: string[]) => {
    if (fields.length === 0) {
      dispatch(
        setFilter({
          ...filter,
          keys: undefined,
        })
      );
      return;
    }
    dispatch(
      setFilter({
        ...filter,
        keys: fields.join(","),
      })
    );
  };

  return (
    <Form form={formData}>
      <Row gutter={[10, 10]}>
        <Col span={9}>
          <SelectField
            deviceId={props.deviceId}
            type={props.type}
            name={"fields"}
            onChange={onChangeFields}
          />
        </Col>
        <Col span={15}>
          <RangePicker
            value={[
              filter.start_time ? moment(filter.start_time, "YYYY-MM-DD HH:mm:ss") : null,
              filter.end_time ? moment(filter.end_time, "YYYY-MM-DD HH:mm:ss") : null,
            ]}
            size="large"
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            onOk={onOk}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FilterLog;

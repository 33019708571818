import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import GridLayoutEdit from "src/modules/ui/components/grid-layout-edit";

const NewUiPage: React.FC<{}> = (props) => {
  const nav = useNavigate();

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - New Ui</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/ui"}>Ui</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>New</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <GridLayoutEdit detail={null} onBack={() => { nav("/ui") }} onSuccess={() => { nav("/ui") }} />
        </Col>
      </Row>
    </div>
  );
};

export default NewUiPage;

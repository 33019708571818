import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationDeviceModelQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListPaginationDeviceModelInput>;
}>;


export type GetListPaginationDeviceModelQuery = { __typename?: 'Query', getListPaginationDeviceModel: { __typename?: 'ListPaginationDeviceModel', limit: number, skip: number, total: number, data?: Array<{ __typename?: 'AssignModelDevice', device_id: any, assign_id: any, model_id: any, status?: Types.StatusAssign | null, created_by?: any | null, updated_by?: any | null, updated_at: any, created_at: any, model?: { __typename?: 'Model', id: any, name: string, description?: string | null, status: Types.StatusModel } | null }> | null } };


export const GetListPaginationDeviceModelDocument = gql`
    query getListPaginationDeviceModel($input: ListPaginationDeviceModelInput) {
  getListPaginationDeviceModel(input: $input) {
    limit
    skip
    total
    data {
      device_id
      assign_id
      model_id
      status
      created_by
      updated_by
      updated_at
      created_at
      model {
        id
        name
        description
        status
      }
    }
  }
}
    `;

/**
 * __useGetListPaginationDeviceModelQuery__
 *
 * To run a query within a React component, call `useGetListPaginationDeviceModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationDeviceModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationDeviceModelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationDeviceModelQuery(baseOptions?: Apollo.QueryHookOptions<GetListPaginationDeviceModelQuery, GetListPaginationDeviceModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationDeviceModelQuery, GetListPaginationDeviceModelQueryVariables>(GetListPaginationDeviceModelDocument, options);
      }
export function useGetListPaginationDeviceModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationDeviceModelQuery, GetListPaginationDeviceModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationDeviceModelQuery, GetListPaginationDeviceModelQueryVariables>(GetListPaginationDeviceModelDocument, options);
        }
export type GetListPaginationDeviceModelQueryHookResult = ReturnType<typeof useGetListPaginationDeviceModelQuery>;
export type GetListPaginationDeviceModelLazyQueryHookResult = ReturnType<typeof useGetListPaginationDeviceModelLazyQuery>;
export type GetListPaginationDeviceModelQueryResult = Apollo.QueryResult<GetListPaginationDeviceModelQuery, GetListPaginationDeviceModelQueryVariables>;
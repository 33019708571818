import { Form, Spin, Row, Col, Alert, Input, Button, Typography, Table } from "antd";
import { useEffect, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import SelectModel from "src/modules/model/components/select-model";
import { RuleOptionProps, RuleInfo } from "../rule";
import Column from "antd/lib/table/Column";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { getModelByIdThunkAction } from "src/modules/model/thunk";
import { selectDetail } from "src/modules/model/slice";

// eslint-disable-next-line
const { Title, Text } = Typography;

const OutputModelRule: React.FC<NodeProps> = (props) => {
    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Output Model</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
        </div>
    )
}

export default OutputModelRule;

export interface ModelFieldOutput {
    id: string
    key: string
    type: string
    output_key: string
}

const EditOuputModel: React.FC<{
  modelId: string| undefined;
  list: ModelFieldOutput[];
  onChane: (list: ModelFieldOutput[]) => void;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ModelFieldOutput[]>([]);
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetail);
  
  useEffect(() => {
    if (props.modelId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modelId]);

  const loadData = async () => {
    setLoading(true);
    dispatch<any>(getModelByIdThunkAction(props.modelId));
    setLoading(false);
  };

  useEffect(() => {
    setList(props.list);
  }, [props])

  useEffect(() => {
    
    const mapData = new Map<string, ModelFieldOutput>();
    console.log("useEffect",list)
    list.forEach(item => {
        mapData.set(item.id, item)
    })

    if (detail?.fields) {
      for (const field of detail?.fields) {
        if (!mapData.has(field.id)) {
            mapData.set(field.id, {
                id: field.id,
                key: field.key,
                type: field.type,
                output_key: ""
            });
        }
      }
    }


    const result = [] as ModelFieldOutput[];

    mapData.forEach((v, k) => {
      result.push(v);
    });

    setList(result);
    // eslint-disable-next-line
  }, [detail]);

  const onChangeMap = (value: string, id: string) => {
    const newList = list.map((item) => {
        if (item.id === id) {
            return {
                ...item,
                output_key: value,
            }
        }
        return item
    })

    setList(newList)
    console.log("newList",newList)
    props.onChane(newList)
  };



  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Row gutter={[10, 10]} style={{ marginTop: "50px" }}>
        <Col span={24}>
          <Title style={{ textAlign: "center" }} level={5}>
            Field Item Model
          </Title>
        </Col>
        <Col span={24}>
          <Table
            dataSource={list}
            size="middle"
            rowKey={"id"}
            pagination={false}
          >
             <Column
              title="Model Key"
              dataIndex="key"
              key="key"
              render={(text: any, record: ModelFieldOutput) => {
                if (!record.key) {
                  return "--";
                }
                return record.key;
              }}
            />
            <Column
              title="Field Type"
              dataIndex="type"
              key="type"
              render={(text: any, record: ModelFieldOutput) => {
                return record.type;
              }}
            />
            <Column
              title="Output Key"
              dataIndex="output_key"
              key="output_key"
              render={(text: any, record: ModelFieldOutput) => {
                return <Input onChange={(item) => onChangeMap(item.target.value, record.id)} value={record.output_key} />;
              }}
            />
          </Table>
        </Col>
      </Row>
    </Spin>
  );
};




const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const OutputModelRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const [modelId, setModelId] = useState("")
    const [list, setList] = useState<ModelFieldOutput[]>([]);
    const message = ""

    useEffect(() => {
        let model_id = ""

        if (props.data && props.data.model_id) {
            model_id = props.data.model_id
            setModelId(model_id)
        }
        if (props.data && props.data.list) {
            setList(props.data.list)
        }

        form.setFields([
            {
                name: "name",
                value: props.label,
            },
            {
                name: "model_id",
                value: model_id,
            },
          ])
    }, [props,form,setList,setModelId])

    const onFinish = (values: any) => {
        console.log("list ",props.id, list)
        props.save(props.id, {
            option: {
                model_id: values.model_id,
                list: list,
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    const onFormLayoutChange = (values: any) => {
      setModelId(values.model_id)
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}  onValuesChange={onFormLayoutChange}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <SelectModel name={"model_id"} disabled={false} />
            </Col>
            <Col span={24}>
                <EditOuputModel modelId={modelId} list={list} onChane={(list) => { setList(list); }} />
            </Col>

            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const OutputModelRuleInfo: RuleInfo = {
    id: "output-model",
    name: "Output Model",
    rule: OutputModelRule,
    option: OutputModelRuleOption,
    infinite: false,
    option_default: {
        
    },
}

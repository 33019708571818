import { Breadcrumb, Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDetail } from "src/modules/ui/slice";
import GridLayoutView from "src/modules/ui/components/grid-layout-view";
import { getUiByIdThunkAction } from "src/modules/ui/thunk";

const ShowUiPage: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetail);

  let { id } = useParams<{ id: string }>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (id) {
      dispatch<any>(getUiByIdThunkAction(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="session-main">
      <Spin spinning={detail == null}>
        <Helmet>
          <title>Dipper - Detail Ui - {detail?.name || ""}</title>
        </Helmet>
        <Row gutter={[0, 10]}>
          <Col span={24} style={{ paddingLeft: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/ui"}>Ui</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detail?.name || ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24}>{detail && <GridLayoutView detail={detail} />}</Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ShowUiPage;

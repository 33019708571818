import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePropertyDeviceMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
  input: Types.PropertyDeviceInput;
}>;


export type UpdatePropertyDeviceMutation = { __typename?: 'Mutation', updatePropertyDevice: boolean };


export const UpdatePropertyDeviceDocument = gql`
    mutation updatePropertyDevice($id: Uint64!, $input: PropertyDeviceInput!) {
  updatePropertyDevice(id: $id, input: $input)
}
    `;
export type UpdatePropertyDeviceMutationFn = Apollo.MutationFunction<UpdatePropertyDeviceMutation, UpdatePropertyDeviceMutationVariables>;

/**
 * __useUpdatePropertyDeviceMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyDeviceMutation, { data, loading, error }] = useUpdatePropertyDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyDeviceMutation, UpdatePropertyDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyDeviceMutation, UpdatePropertyDeviceMutationVariables>(UpdatePropertyDeviceDocument, options);
      }
export type UpdatePropertyDeviceMutationHookResult = ReturnType<typeof useUpdatePropertyDeviceMutation>;
export type UpdatePropertyDeviceMutationResult = Apollo.MutationResult<UpdatePropertyDeviceMutation>;
export type UpdatePropertyDeviceMutationOptions = Apollo.BaseMutationOptions<UpdatePropertyDeviceMutation, UpdatePropertyDeviceMutationVariables>;
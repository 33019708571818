import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationAlertQueryVariables = Types.Exact<{
  input: Types.ListPaginationAlertInput;
}>;


export type GetListPaginationAlertQuery = { __typename?: 'Query', getListPaginationAlert: { __typename?: 'ListPaginationAlert', limit: number, total: number, data?: Array<{ __typename?: 'ViewAlert', id: any, name: string, enable: boolean, model_id: any, created_at: any, updated_at: any, user_id?: any | null, model?: { __typename?: 'Model', id: any, name: string } | null, created_by_user?: { __typename?: 'UserBaseView', email: string, name: string } | null }> | null } };


export const GetListPaginationAlertDocument = gql`
    query getListPaginationAlert($input: ListPaginationAlertInput!) {
  getListPaginationAlert(input: $input) {
    limit
    total
    data {
      id
      name
      model {
        id
        name
      }
      enable
      model_id
      created_at
      updated_at
      user_id
      created_by_user {
        email
        name
      }
    }
  }
}
    `;

/**
 * __useGetListPaginationAlertQuery__
 *
 * To run a query within a React component, call `useGetListPaginationAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationAlertQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationAlertQuery(baseOptions: Apollo.QueryHookOptions<GetListPaginationAlertQuery, GetListPaginationAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationAlertQuery, GetListPaginationAlertQueryVariables>(GetListPaginationAlertDocument, options);
      }
export function useGetListPaginationAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationAlertQuery, GetListPaginationAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationAlertQuery, GetListPaginationAlertQueryVariables>(GetListPaginationAlertDocument, options);
        }
export type GetListPaginationAlertQueryHookResult = ReturnType<typeof useGetListPaginationAlertQuery>;
export type GetListPaginationAlertLazyQueryHookResult = ReturnType<typeof useGetListPaginationAlertLazyQuery>;
export type GetListPaginationAlertQueryResult = Apollo.QueryResult<GetListPaginationAlertQuery, GetListPaginationAlertQueryVariables>;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { logoutThunkAction, verifyThunkAction } from 'src/modules/login/login.thunk'
import { RootState } from './store'

export const AppSlice = createSlice({
    name: 'app',
    initialState: {
        is_auth: true,
        menu: false,
    },
    reducers: {
        setAuth: (state, action: PayloadAction<true>) => {
            state.is_auth = action.payload
        },
        menuSet: (state, payload: PayloadAction<boolean>) => {
            state.menu = payload.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(verifyThunkAction.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.is_auth = true
                return
            }
            state.is_auth = false
        })
        builder.addCase(verifyThunkAction.rejected, (state, action) => {
            state.is_auth = false
        })
        builder.addCase(logoutThunkAction.fulfilled, (state, action) => {
            state.is_auth = false
        })
    }
})

export default AppSlice.reducer
export const { setAuth, menuSet } = AppSlice.actions

// select
export const selectIsAuth = (state: RootState) => state.app.is_auth
export const selectMenu = (state: RootState) => state.app.menu
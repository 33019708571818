import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRuleChanMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
  input: Types.InputRuleChan;
}>;


export type UpdateRuleChanMutation = { __typename?: 'Mutation', updateRuleChan: boolean };


export const UpdateRuleChanDocument = gql`
    mutation updateRuleChan($id: Uint64!, $input: InputRuleChan!) {
  updateRuleChan(id: $id, input: $input)
}
    `;
export type UpdateRuleChanMutationFn = Apollo.MutationFunction<UpdateRuleChanMutation, UpdateRuleChanMutationVariables>;

/**
 * __useUpdateRuleChanMutation__
 *
 * To run a mutation, you first call `useUpdateRuleChanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRuleChanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuleChanMutation, { data, loading, error }] = useUpdateRuleChanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRuleChanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRuleChanMutation, UpdateRuleChanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRuleChanMutation, UpdateRuleChanMutationVariables>(UpdateRuleChanDocument, options);
      }
export type UpdateRuleChanMutationHookResult = ReturnType<typeof useUpdateRuleChanMutation>;
export type UpdateRuleChanMutationResult = Apollo.MutationResult<UpdateRuleChanMutation>;
export type UpdateRuleChanMutationOptions = Apollo.BaseMutationOptions<UpdateRuleChanMutation, UpdateRuleChanMutationVariables>;
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { loginThunkAction, verifyThunkAction } from "../login.thunk";
import {
  selectDeviceId,
  selectLoading,
  selectLogin,
  selectMessage,
  selectRedirect,
  setLoginWithToken,
} from "../login.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuth } from "src/app/app.slice";
import { Alert, Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import "./style.css";
import { UserOutlined, LockOutlined, GoogleOutlined, AppleOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Logo from "src/components/logo";
import { DeviceType } from "src/types";

interface P {}

const Login: React.FC<P> = (props: P) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const nav = useNavigate();

  const loading = useAppSelector(selectLoading);
  const message = useAppSelector(selectMessage);
  const redirect = useAppSelector(selectRedirect);
  const isLogin = useAppSelector(selectLogin);
  const [form] = Form.useForm();
  const deviceId = useAppSelector(selectDeviceId);

  const urlNow = window.location.href.split("?")[0];
  const redirectGoogle = `https://account.overchall.com/auth/provider/google?redirect=${urlNow}` as string
  const redirectApple = `https://account.overchall.com/auth/provider/apple?redirect=${urlNow}`  as string

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      dispatch<any>(setLoginWithToken(token))
      dispatch<any>(
        verifyThunkAction({})
      );
    }
  }, [location, nav, dispatch])


  useEffect(() => {
    if (isLogin) {
      console.log("login success");
      dispatch(setAuth(true));
      if (redirect === "") {
        nav("/");
      } else {
        nav(redirect);
      }
    }
  }, [isLogin, nav, dispatch, redirect]);

  const onFinish = (values: any) => {
    dispatch<any>(
      loginThunkAction({
        email: values.email,
        password: values.password,
        deviceType: DeviceType.Web,
        deviceId: deviceId,
      })
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Logo
          style={{ width: 60, height: 60, position: "relative", top: "15px" }}
        />
        <Title className="title" style={{ display: "inline-block" }}>
          Overchall Hub
        </Title>
      </Col>
      {message === "NOT_AUTH" && (
        <Col span={24} style={{ margin: "0px 0px 20px 0px" }}>
          <Alert message="Username or Password incorrect!" type="error" />
        </Col>
      )}
      <Col span={24}>
        <Spin spinning={loading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{ width: "100%" }}
            size="large"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              <Divider>Or</Divider>
              <Button
                type="primary"
                size="large"
                href={redirectGoogle}
                className="login-form-button"
                danger
              >
               <GoogleOutlined />  Log in with Google 
              </Button>
              <div style={{padding: "5px"}}></div>
              <Button 
                type="primary"
                size="large"
                href={redirectApple}
                className="login-form-button"
                style={{ borderColor: "#131212",background: "#141313"}}      
              >
               <AppleOutlined />  Log in with Apple 
              </Button>
              <div style={{padding: "5px"}}></div>
              <a href="https://account.overchall.com/auth/register">Register a new membership</a>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default Login;

import { Area } from "@ant-design/charts";
import React from "react";
import { isUndefined } from "lodash";
import { WidgetData } from "../../../models/data";
import { WidgetOption } from "../../../models/widget";

const View: React.FC<{
  data: WidgetData;
  option: WidgetOption;
}> = (props) => {
  let { option, data } = props;

  if (isUndefined(data.list) || data.list == null) {
    data.list = [];
  }
  var config = {
    data: data[option.data_key || "list"] || [],
    xField: option.x_field || "time",
    yField: option.y_field || "val",
    seriesField: option.series_field || "key",
    color: [
      "#6897a7",
      "#8bc0d6",
      "#60d7a7",
      "#dedede",
      "#fedca9",
      "#fab36f",
      "#d96d6f",
    ],
  };
  return <Area {...config} />;
};

export default View;

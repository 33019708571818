import { createAsyncThunk } from '@reduxjs/toolkit'
import { MiddlewareType } from 'src/app/store'
import { UserLogout } from './models/user_logout'
import { UserLoginRequest, UserLoginResponse } from './models/user_login'

export const loginThunkAction = createAsyncThunk<
  UserLoginResponse,
  UserLoginRequest,
  MiddlewareType
>(
  'login/loginThunk',
  async (data, { extra }) => {
    const result = await extra.http.post("https://account.overchall.com/v1/auth/login", data)
    console.log(result)
    return result
  }
)

export const logoutThunkAction = createAsyncThunk<
  any,
  UserLogout,
  MiddlewareType
>(
  'login/logoutThunk',
  async (data, { extra }) => {
    const result = await extra.http.post("https://account.overchall.com/v1/auth/logout", data)
    console.log(result)
    return result
  }
)

export const verifyThunkAction = createAsyncThunk<
    {
      success: boolean,
      msg: string,
      token: string
      user: any
    },
    any,
    MiddlewareType
  >(
  'login/verifyThunk',
  async (data, { extra }) => {
    const result = await extra.http.post("https://account.overchall.com/v1/auth/verify", {})
    console.log(result)
    return result
  }
)
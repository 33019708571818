import {  BugOutlined, PlusOutlined, RollbackOutlined, SaveOutlined, SettingOutlined } from "@ant-design/icons";
import { Card } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";

const gridStyle: React.CSSProperties = {
    textAlign: 'center',
    width: "100%",
    padding: "10px"
};

const ToolsBoxRuleChan: React.FC<{
    onAction: (action: string) => void
}> = (props) => {

  return (
    <Card 
        hoverable
        style={{
            width: "50px",
            position: "absolute",
            top: "100px",
            right: "10px",
        }}
    >
        <Title level={5} style={{ 
            fontSize: "11px", 
            textAlign: "center", 
            width: "100%",
            margin: "0px",
            padding: "7px 0px",
        }}>Action</Title>
        <Card.Grid style={gridStyle} title="New Node">
            <PlusOutlined  title="New Node" onClick={() => { props.onAction("create")}} />
        </Card.Grid>
        <Card.Grid style={gridStyle} title="Save">
            <SaveOutlined onClick={() => { props.onAction("save")}}  />
        </Card.Grid>
        <Card.Grid style={gridStyle} title="Debug" onClick={() => { props.onAction("debug")}}>
            <BugOutlined />
        </Card.Grid>
        <Card.Grid style={gridStyle} title="Setting">
            <SettingOutlined />
        </Card.Grid>
        <Card.Grid style={gridStyle} title="Back" onClick={() => { props.onAction("back")}}>
            <RollbackOutlined />
        </Card.Grid>

    </Card>
  );
};

export default ToolsBoxRuleChan;

import React, { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { selectIsAuth } from "../../app/app.slice";
import { Layout, Space } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import './login.css'

interface P {
  children: any
}


export const LoginLayout: React.FC<P> = (props: P) => {
  const navigate = useNavigate();

  const isAuth = useAppSelector(selectIsAuth)
  useEffect(() => {
    if (isAuth) {
      navigate("/")
    }
  }, [isAuth, navigate])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content id='main-login'>
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          {props.children}
        </Space>
      </Content>
      <Footer style={{ textAlign: 'center' }}>O-Hub ©2024 Created by Overchall</Footer>
    </Layout>
  );
};

export default LoginLayout;

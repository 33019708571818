import { Alert, Button, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RequestStatus, ThunkResult, useAppDispatch } from "src/app/hooks";
import { Model, ModelFieldInput, Status } from "src/types";
import { selectDetail } from "../../slice";
import { createModelThunkAction, getModelByIdThunkAction, updateModelThunkAction } from "../../thunk";
import EditModelField from "./fields";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const EditModel: React.FC<{
  data: Model | null;
  isEdit: boolean;
  onClose: (loading: boolean) => void;
  onEdit: () => void;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, selectMessage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState<ModelFieldInput[]>([]);
  const modelDetail =  useSelector(selectDetail)

  useEffect(() => {
    if (props.data == null) {
      form.resetFields()
      form.setFields([
        {
          name: "status",
          value: Status.Active,
        },
      ]);
    } else {
      setLoading(true);
      form.setFieldsValue(props.data);

      dispatch<any>(getModelByIdThunkAction(props.data.id))
      .then(() => {
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
      });

    }
  }, [props, form, dispatch]);

  useEffect(() => {
    if (props.data != null &&modelDetail != null && modelDetail.fields) {
      setFields(modelDetail.fields)
    } else {
      setFields([])
    }
  }, [modelDetail, props])

  const onFinish = async (values: any) => {
    let res: ThunkResult<boolean, any>;
    try {
      setLoading(true);

      if (props.data == null) {
        res = await dispatch<any>(createModelThunkAction({
          ...values,
          fields: fields,
        }));
      } else {
        res = await dispatch<any>(
          updateModelThunkAction({ id: props.data.id, input: {
            ...values,
            fields: fields,
          } })
        );
      }
      setLoading(false);

      if (res.meta.requestStatus === RequestStatus.Fulfilled) {
        props.onClose(true);
      } else {
        selectMessage(res.error.message);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row gutter={[10, 10]}>
          {message !== "" && (
            <Col span={24}>
              <Alert type="error" message={message}></Alert>
            </Col>
          )}
          <Col span={24}>
            <Row justify="end" gutter={[10, 10]}>
              {props.isEdit && (
                <Col style={{ width: 100 }}>
                  <Input size="large" type="submit" value="Save" />
                </Col>
              )}
              {!props.isEdit && props.data != null && (
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onEdit();
                  }}
                >
                  Edit
                </Button>
              )}
              <Col>
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input size="large" disabled={!props.isEdit} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please input description!" }]}
            >
              <Input.TextArea size="large" disabled={!props.isEdit} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"status"}
              label="Status"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please select type!" }]}
            >
              <Select size="large" disabled={!props.isEdit}>
                <Select.Option value={Status.Active}>Active</Select.Option>
                <Select.Option value={Status.Inactive}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <EditModelField data={fields} onChange={(data) => { setFields(data) }} isEdit={props.isEdit} />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default EditModel;

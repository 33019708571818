import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { ListPaginationAlertInput, ListPaginationAlert, Alert, ListPaginationAlertLog, ListPaginationAlertLogInput } from 'src/types'
import { getAlertByIdThunkAction, getSearchAlertThunkAction, listPaginationAlertLogThunkAction, listPaginationAlertThunkAction } from './thunk'
import moment from 'moment'

export const AlertSlice = createSlice({
    name: 'alert',
    initialState: {
        loading: false,
        message: "",
        listAlert: null as ListPaginationAlert | null,
        listAlertLog: null as ListPaginationAlertLog | null,
        detail: null as Alert |  null,
        filter: {
            limit: 50,
            skip: 0,
        } as ListPaginationAlertInput,
        filterLog: {
            limit: 50,
            skip: 0,
            end_time: moment().add(1, "minute"),
            start_time: moment().subtract(10, "minute"),
        } as ListPaginationAlertLogInput,
        search: {
            data: [] as any[]
        } as ListPaginationAlert,
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationAlertInput>) => {
            state.filter = payload.payload
        },
        setFilterLog: (state, payload: PayloadAction<ListPaginationAlertLogInput>) => {
            state.filterLog = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(listPaginationAlertThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(listPaginationAlertThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(listPaginationAlertThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.listAlert = action.payload.getListPaginationAlert as ListPaginationAlert
        })
        builder.addCase(getAlertByIdThunkAction.fulfilled, (state, action) => {
            state.message = ""
            state.detail = action.payload.getAlert as Alert
        })
        builder.addCase(getAlertByIdThunkAction.pending, (state, action) => {
            state.message = ""
            state.detail = null
        })
        builder.addCase(getSearchAlertThunkAction.fulfilled, (state, action) => {
            state.message = ""
            state.search = action.payload.getListPaginationAlert as ListPaginationAlert
        })
        // alert log
        builder.addCase(listPaginationAlertLogThunkAction.pending, (state, action) => {
            state.filterLog = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(listPaginationAlertLogThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(listPaginationAlertLogThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.listAlertLog = action.payload.getListPaginationAlertLog as ListPaginationAlertLog
        })
    }
})

export default AlertSlice.reducer
export const { setFilter, setFilterLog } = AlertSlice.actions


// select
export const selectPolicy = (state: RootState) => state.alert
export const selectLoading = (state: RootState) => state.alert.loading
export const selectMessage = (state: RootState) => state.alert.message
export const selectFilter = (state: RootState) => state.alert.filter
export const selectListAlert = (state: RootState) => state.alert.listAlert
export const selectDetail = (state: RootState) => state.alert.detail
export const selectListAlertSearch = (state: RootState) => state.alert.search.data || []
// alert log
export const selectFilterLog = (state: RootState) => state.alert.filterLog
export const selectListAlertLog = (state: RootState) => state.alert.listAlertLog
import { Alert, Button, Col, Form, Row, Spin } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleInfo, RuleOptionProps } from "../rule";
import { useEffect } from "react";
import Title from "antd/lib/typography/Title";

const InpuTelemetryRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <div>
                <Title level={5}>Input Telemetry</Title>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                title="output"
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}

export default InpuTelemetryRule;


const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const InpuTelemetryRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        form.setFields([
            {
              name: "type",
              value: "Input Telemetry",
            },
          ])
    }, [props,form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {},
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                    <Col>
                        <Button
                        size="large"
                        style={{ width: 100 }}
                        onClick={onCancel}
                        >
                        Cancel
                        </Button>
                    </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const InpuTelemetryRuleInfo: RuleInfo = {
    id: "input-telemetry",
    name: "Input Telemetry",
    rule: InpuTelemetryRule,
    option: InpuTelemetryRuleOption,
    infinite: false,
    option_default: {},
}

import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNamespaceMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
  input: Types.NamespaceInput;
}>;


export type UpdateNamespaceMutation = { __typename?: 'Mutation', updateNamespace: boolean };


export const UpdateNamespaceDocument = gql`
    mutation updateNamespace($id: Uint64!, $input: NamespaceInput!) {
  updateNamespace(id: $id, input: $input)
}
    `;
export type UpdateNamespaceMutationFn = Apollo.MutationFunction<UpdateNamespaceMutation, UpdateNamespaceMutationVariables>;

/**
 * __useUpdateNamespaceMutation__
 *
 * To run a mutation, you first call `useUpdateNamespaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNamespaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNamespaceMutation, { data, loading, error }] = useUpdateNamespaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNamespaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNamespaceMutation, UpdateNamespaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNamespaceMutation, UpdateNamespaceMutationVariables>(UpdateNamespaceDocument, options);
      }
export type UpdateNamespaceMutationHookResult = ReturnType<typeof useUpdateNamespaceMutation>;
export type UpdateNamespaceMutationResult = Apollo.MutationResult<UpdateNamespaceMutation>;
export type UpdateNamespaceMutationOptions = Apollo.BaseMutationOptions<UpdateNamespaceMutation, UpdateNamespaceMutationVariables>;
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['Uint64'];
}>;


export type DeleteAlertMutation = { __typename?: 'Mutation', deleteAlert: boolean };


export const DeleteAlertDocument = gql`
    mutation deleteAlert($alertId: Uint64!) {
  deleteAlert(alertId: $alertId)
}
    `;
export type DeleteAlertMutationFn = Apollo.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, options);
      }
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = Apollo.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = Apollo.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;
import { Alert, Button, Col, Form, Input, Row, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
  RequestStatus,
  ThunkResult,
  useAppDispatch,
  useAppSelector,
} from "src/app/hooks";
import { Device, DeviceInput, Status, TypeCertificate } from "src/types";
import { selectDetail } from "../../slice";
import {
  createDeviceThunkAction,
  getDeviceByIdThunkAction,
  updateDeviceThunkAction,
} from "../../thunk";
import EditDeviceCertificate from "./certificate";
import EditDeviceGeneral from "./general";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const EditDevice: React.FC<{
  data: Device | null;
  isEdit: boolean;
  onClose: (loading: boolean) => void;
  onEdit: () => void;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, selectMessage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetail);

  useEffect(() => {
    if (props.data == null) {
      form.setFields([
        {
          name: "status",
          value: Status.Active,
        },
        {
          name: "certificate_type",
          value: TypeCertificate.Certificate,
        },
      ]);
    } else {
      setLoading(true);

      dispatch<any>(getDeviceByIdThunkAction(props.data.id))
        .then(() => {
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });

      form.setFieldsValue(props.data);
    }
  }, [props, form, dispatch]);

  useEffect(() => {
    if (detail != null) {
      form.setFieldsValue(detail);
      form.setFields([
        {
          name: "certificate_type",
          value: detail.certificate.type,
        },
        {
          name: "username",
          value: detail.certificate.username,
        },
        {
          name: "password",
          value: detail.certificate.password,
        },
        {
          name: "client_id",
          value: detail.certificate.client_id,
        },
        {
          name: "token",
          value: detail.certificate.token,
        },
        {
          name: "gateway_id",
          value: detail.certificate.gateway_id,
        },
      ]);
    }
  }, [detail, form]);

  const onFinish = async (values: any) => {
    let res: ThunkResult<boolean, any>;
    try {
      setLoading(true);

      let deviceInput: DeviceInput = {
        name: values.name,
        key: values.key,
        description: values.description,
        namespace_id: values.namespace_id,
        is_create_device: values.is_create_device,
        is_gateway: values.is_gateway,
        certificate: {
          type: values.certificate_type,
          username: values.username,
          password: values.password,
          client_id: values.client_id,
          gateway_id: values.gateway_id,
          token: values.token,
        },
      };

      if (props.data == null) {
        res = await dispatch<any>(createDeviceThunkAction(deviceInput));
      } else {
        if (detail != null) {
          deviceInput.certificate = {
            type: values.certificate_type
              ? values.certificate_type
              : detail.certificate.type,
            username: values.username
              ? values.username
              : detail.certificate.username,
            password: values.password
              ? values.password
              : detail.certificate.password,
            client_id: values.client_id
              ? values.client_id
              : detail.certificate.client_id,
            gateway_id: values.gateway_id
              ? values.gateway_id
              : detail.certificate.gateway_id,
            token: values.token ? values.token : detail.certificate.token,
          };
        }

        res = await dispatch<any>(
          updateDeviceThunkAction({ id: props.data.id, input: deviceInput })
        );
      }
      setLoading(false);

      if (res.meta.requestStatus === RequestStatus.Fulfilled) {
        props.onClose(true);
      } else {
        selectMessage(res.error.message);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const { TabPane } = Tabs;

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row gutter={[10, 10]}>
          {message !== "" && (
            <Col span={24}>
              <Alert type="error" message={message}></Alert>
            </Col>
          )}

          <Col span={24}>
            <Row justify="end" gutter={[10, 10]}>
              {props.isEdit && (
                <Col style={{ width: 100 }}>
                  <Input size="large" type="submit" value="Save" />
                </Col>
              )}
              {!props.isEdit && props.data != null && (
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onEdit();
                  }}
                >
                  Edit
                </Button>
              )}
              <Col>
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Tabs defaultActiveKey="1" onChange={onChange}>
              <TabPane tab="General" key="1">
                <EditDeviceGeneral namespaceId={props.data?.namespace_id} isEdit={props.isEdit} />
              </TabPane>
              <TabPane tab="Certificate" key="2">
                <EditDeviceCertificate isEdit={props.isEdit} />
              </TabPane>
              <TabPane tab="Alert" key="3"></TabPane>
            </Tabs>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default EditDevice;

import { WidgetComponent } from "src/modules/widget/models/widget"
import Edit from "./edit-column"
import View from "./view-column"

const wiget: WidgetComponent = {
    type: "column",
    edit: Edit,
    icon: "column-chart",
    name: "Column Chart",
    view: View,
    options: [],
}

export default wiget
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModelLogLastTimeQueryVariables = Types.Exact<{
  input: Types.LastTimeModelInput;
}>;


export type GetModelLogLastTimeQuery = { __typename?: 'Query', getModelLogLastTime: { __typename?: 'ListModelLog', data?: Array<{ __typename?: 'ModelLog', device_id: any, model_id: any, field_id: any, source_input: Types.SourceInput, key: string, value: string, value_n: number, time: any, device?: { __typename?: 'Device', name: string } | null, model?: { __typename?: 'Model', name: string } | null, field_model?: { __typename?: 'ModelField', key: string, type: Types.TypeField } | null }> | null } };


export const GetModelLogLastTimeDocument = gql`
    query getModelLogLastTime($input: LastTimeModelInput!) {
  getModelLogLastTime(input: $input) {
    data {
      device_id
      model_id
      field_id
      source_input
      key
      value
      value_n
      time
      device {
        name
      }
      model {
        name
      }
      field_model {
        key
        type
      }
    }
  }
}
    `;

/**
 * __useGetModelLogLastTimeQuery__
 *
 * To run a query within a React component, call `useGetModelLogLastTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelLogLastTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelLogLastTimeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetModelLogLastTimeQuery(baseOptions: Apollo.QueryHookOptions<GetModelLogLastTimeQuery, GetModelLogLastTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelLogLastTimeQuery, GetModelLogLastTimeQueryVariables>(GetModelLogLastTimeDocument, options);
      }
export function useGetModelLogLastTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelLogLastTimeQuery, GetModelLogLastTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelLogLastTimeQuery, GetModelLogLastTimeQueryVariables>(GetModelLogLastTimeDocument, options);
        }
export type GetModelLogLastTimeQueryHookResult = ReturnType<typeof useGetModelLogLastTimeQuery>;
export type GetModelLogLastTimeLazyQueryHookResult = ReturnType<typeof useGetModelLogLastTimeLazyQuery>;
export type GetModelLogLastTimeQueryResult = Apollo.QueryResult<GetModelLogLastTimeQuery, GetModelLogLastTimeQueryVariables>;
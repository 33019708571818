import { WidgetComponent } from "src/modules/widget/models/widget"
import EditColorFormat from "./edit-colorFormat"
import ViewColorFormat from "./view-colorFormat"

const wiget: WidgetComponent = {
    type: "ColorFormat",
    edit: EditColorFormat,
    icon: "Color-Format",
    name: "ColorFormat",
    view: ViewColorFormat,
    options: [],
}

export default wiget
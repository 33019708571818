import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationNamespaceQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListPaginationNamespaceInput>;
}>;


export type GetListPaginationNamespaceQuery = { __typename?: 'Query', getListPaginationNamespace: { __typename?: 'ListPaginationNamespace', limit: number, skip: number, total: number, data?: Array<{ __typename?: 'Namespace', id: any, name: string, owner_id: any, created_at: any, updated_at: any, description?: string | null, status: Types.Status, count_device: number, owner?: { __typename?: 'UserBaseView', name: string, email: string } | null }> | null } };


export const GetListPaginationNamespaceDocument = gql`
    query getListPaginationNamespace($input: ListPaginationNamespaceInput) {
  getListPaginationNamespace(input: $input) {
    limit
    skip
    total
    data {
      id
      name
      owner_id
      created_at
      updated_at
      description
      owner {
        name
        email
      }
      status
      count_device
    }
  }
}
    `;

/**
 * __useGetListPaginationNamespaceQuery__
 *
 * To run a query within a React component, call `useGetListPaginationNamespaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationNamespaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationNamespaceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationNamespaceQuery(baseOptions?: Apollo.QueryHookOptions<GetListPaginationNamespaceQuery, GetListPaginationNamespaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationNamespaceQuery, GetListPaginationNamespaceQueryVariables>(GetListPaginationNamespaceDocument, options);
      }
export function useGetListPaginationNamespaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationNamespaceQuery, GetListPaginationNamespaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationNamespaceQuery, GetListPaginationNamespaceQueryVariables>(GetListPaginationNamespaceDocument, options);
        }
export type GetListPaginationNamespaceQueryHookResult = ReturnType<typeof useGetListPaginationNamespaceQuery>;
export type GetListPaginationNamespaceLazyQueryHookResult = ReturnType<typeof useGetListPaginationNamespaceLazyQuery>;
export type GetListPaginationNamespaceQueryResult = Apollo.QueryResult<GetListPaginationNamespaceQuery, GetListPaginationNamespaceQueryVariables>;
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { Chan, RuleNode, ListPaginationRuleChanInput } from 'src/types'
import { getDetailRuleChanThunkAction, getListPaginationRuleChanThunkAction } from './thunk'

export const RuleChanSlice = createSlice({
    name: 'rule_chan',
    initialState: {
        loading: false,
        debug: false,
        message: "",
        list: [] as Chan[],
        list_node: [] as RuleNode[],
        detail_chan: {} as Chan,
        option_nodes: {} as any,
        filter: {
            limit: 50,
            skip: 0
        } as ListPaginationRuleChanInput
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationRuleChanInput>) => {
            state.filter = payload.payload
        },
        saveConnectToNodes: (state, payload: PayloadAction<{ chanId: string, link :  any }>) => {
            
        },
        saveOption: (state, payload: PayloadAction<{ nodeId: string, option : any }>) => {
           let newOption = Object.assign({}, state.option_nodes)
           newOption[payload.payload.nodeId] = payload.payload.option
           state.option_nodes = newOption
        },
    },
    extraReducers: builder => {
        // getListPaginationRuleChanThunkAction
        builder.addCase(getListPaginationRuleChanThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
            state.list = []
        })
        builder.addCase(getListPaginationRuleChanThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationRuleChanThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            if (action.payload.getListPaginationRuleChan != null) {
                let list =  action.payload.getListPaginationRuleChan?.list as Chan[]
                if (list) {
                    state.list = list
                    return
                } 
            }
            state.list = []
        })
        // getDetailRuleChanThunkAction
        builder.addCase(getDetailRuleChanThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(getDetailRuleChanThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getDetailRuleChanThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            if (action.payload.getDetailRuleChan != null) {
                let list =  action.payload.getDetailRuleChan?.nodes as RuleNode[]
                if (list) {
                    state.list_node = list

                    for(let item of list) {
                        if (item.option) {
                            let option = {} as any
                            for(const key of  Object.keys(item.option)) {
                                option[key] = item.option[key]
                            }
                            state.option_nodes[item.node_id] = option
                        }
                    }
                } 
                let chan =  action.payload.getDetailRuleChan?.chan as Chan
                if (chan) {
                    state.detail_chan = chan
                } 
                return
            }
            state.list_node = []
            state.detail_chan = {} as Chan
        })
    }
})

export default RuleChanSlice.reducer
export const { setFilter, saveOption } = RuleChanSlice.actions


// select
export const selectRuleChan = (state: RootState) => state.rule_chan
export const selectLoading = createSelector(selectRuleChan, (state) => state.loading)
export const selectMessage = createSelector(selectRuleChan, (state) => state.message)
export const selectFilter = createSelector(selectRuleChan, (state) => state.filter)
export const selectListRuleChan = createSelector(selectRuleChan, (state) => state.list)
export const selectListNode = createSelector(selectRuleChan, (state) => state.list_node)
export const selectOptionNodes = createSelector(selectRuleChan, (state) => state.option_nodes)
import { Form, Select } from "antd";

const TimeWindow: React.FC<{
  onChange?: (data: string) => void;
}> = (props) => {
  return (
    <Form.Item name="window" label="Aggregate">
      <Select onChange={props.onChange}>
        <Select.Option value={"1s"}>5s</Select.Option>
        <Select.Option value={"5s"}>15s</Select.Option>
        <Select.Option value={"30s"}>30s</Select.Option>
        <Select.Option value={"1m"}>1m</Select.Option>
        <Select.Option value={"5m"}>5m</Select.Option>
        <Select.Option value={"10m"}>10m</Select.Option>
        <Select.Option value={"30m"}>30m</Select.Option>
        <Select.Option value={"1h"}>1h</Select.Option>
        <Select.Option value={"2h"}>2h</Select.Option>
        <Select.Option value={"6h"}>6h</Select.Option>
        <Select.Option value={"1d"}>1d</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default TimeWindow;

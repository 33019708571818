import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationDeviceLogQueryVariables = Types.Exact<{
  input: Types.ListPaginationDeviceLogInput;
}>;


export type GetListPaginationDeviceLogQuery = { __typename?: 'Query', getListPaginationDeviceLog: { __typename?: 'ListPaginationDeviceLog', limit: number, data?: Array<{ __typename?: 'DeviceLog', device_id: any, key: string, value: string, is_number: boolean, time: any, type: Types.TypeDeviceLog, device?: { __typename?: 'Device', id: any, key: string, name: string } | null }> | null } };


export const GetListPaginationDeviceLogDocument = gql`
    query getListPaginationDeviceLog($input: ListPaginationDeviceLogInput!) {
  getListPaginationDeviceLog(input: $input) {
    limit
    data {
      device_id
      key
      key
      value
      is_number
      time
      type
      device {
        id
        key
        name
      }
    }
  }
}
    `;

/**
 * __useGetListPaginationDeviceLogQuery__
 *
 * To run a query within a React component, call `useGetListPaginationDeviceLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationDeviceLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationDeviceLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationDeviceLogQuery(baseOptions: Apollo.QueryHookOptions<GetListPaginationDeviceLogQuery, GetListPaginationDeviceLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationDeviceLogQuery, GetListPaginationDeviceLogQueryVariables>(GetListPaginationDeviceLogDocument, options);
      }
export function useGetListPaginationDeviceLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationDeviceLogQuery, GetListPaginationDeviceLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationDeviceLogQuery, GetListPaginationDeviceLogQueryVariables>(GetListPaginationDeviceLogDocument, options);
        }
export type GetListPaginationDeviceLogQueryHookResult = ReturnType<typeof useGetListPaginationDeviceLogQuery>;
export type GetListPaginationDeviceLogLazyQueryHookResult = ReturnType<typeof useGetListPaginationDeviceLogLazyQuery>;
export type GetListPaginationDeviceLogQueryResult = Apollo.QueryResult<GetListPaginationDeviceLogQuery, GetListPaginationDeviceLogQueryVariables>;
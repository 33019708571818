import { Breadcrumb, Button, Card, Col, Drawer, PageHeader, Row } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Device } from "src/types";
import FilterDevice from "src/modules/device/components/filter";
import ListDevice from "src/modules/device/components/list";
import EditDevice from "src/modules/device/components/edit/edit";
import { deleteDeviceThunkAction } from "src/modules/device/thunk";
import { useAppDispatch } from "src/app/hooks";
import DataDeviceLog from "./detail";
import ListProperty from "src/modules/device_property/components/list-property";

const ListDevicePage: React.FC<{}> = (props) => {
  const [device, setSelectDevice] = useState<Device | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isModalData, setIsModalData] = useState<boolean>(false);
  const [isModalProperty, setIsModalProperty] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [add, setAdd] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const onAction = async (action: string, row: Device) => {
    setSelectDevice(row);
    switch (action) {
      case "detail": {
        setIsEdit(false);
        setIsModal(true);
        break;
      }
      case "edit": {
        setAdd(false);
        setIsEdit(true);
        setIsModal(true);
        break;
      }
      case "detail-data": {
        setIsModalData(true);
        break;
      }
      case "property": {
        setIsModalProperty(true);
        break;
      }
      case "model": {
        nav(`/device/${row.id}/model`);

        break;
      }
      case "delete": {
        await dispatch<any>(deleteDeviceThunkAction(row.id));
        callbackLoadData();
        console.log("del namespace", device);
        break;
      }
    }
  };

  const onAdd = () => {
    setAdd(true);
    setIsEdit(true);
    setSelectDevice(null);
    setIsModal(true);
  };

  let callbackLoadData = () => {};

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - List Device</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Device</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Card size={"small"}>
            <PageHeader
              className="page-header-responsive"
              title="Devives"
              extra={
                <>
                  <FilterDevice />
                  <Button type="primary" onClick={onAdd}>
                    Add Device
                  </Button>
                </>
              }
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>
          <ListDevice
            onAction={onAction}
            refLoading={(callback) => {
              callbackLoadData = callback;
            }}
          />
        </Col>
      </Row>

      <Drawer
        title={!isEdit ? "Detail Device" : add ? "Add Device" : "Edit Device"}
        placement="right"
        width={600}
        closable={false}
        visible={isModal}
        onClose={() => {
          if (!isEdit) {
            setIsModal(false);
          }
        }}
      >
        {isModal && (
          <EditDevice
            data={device}
            isEdit={isEdit}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                callbackLoadData();
              }
            }}
            onEdit={() => {
              setIsEdit(true);
            }}
          ></EditDevice>
        )}
      </Drawer>

      <Drawer
        title={"Data Device Log"}
        placement="right"
        width={800}
        visible={isModalData}
        onClose={() => {
          setIsModalData(false);
        }}
      >
        {isModalData && <DataDeviceLog device={device} />}
      </Drawer>

      <Drawer
        title={"Property Device "}
        placement="right"
        width={600}
        closable={false}
        visible={isModalProperty}
        onClose={() => {
          setIsModalProperty(false);
        }}
      >
        {isModalProperty && <ListProperty deviceId={device?.id} />}
      </Drawer>
    </div>
  );
};

export default ListDevicePage;

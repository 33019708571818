import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, Row, Table, Tag } from "antd";
import { Typography } from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import { InputMaybe, ModelFieldInput, TypeField } from "src/types";
import EditField from "./edit-field";

const { Title } = Typography;

export interface ModelFieldInputIndex {
  description?: InputMaybe<string> | undefined;
  field_type_id?: any;
  id?: any;
  key: string;
  type: TypeField;
  index: number;
}

const EditModelField: React.FC<{
  data: ModelFieldInput[];
  onChange: (data: ModelFieldInput[]) => void;
  isEdit: boolean;
}> = (props) => {
  const onMenu = (info: { key: string }, row: ModelFieldInputIndex) => {
    setField(row);
    switch (info.key) {
      case "edit": {
        setIsModal(true);
        return;
      }
      case "delete": {
        setIsModalVisibleDel(true);
        return;
      }
      default: {
      }
    }
  };
  const [isModal, setIsModal] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [field, setField] = useState<ModelFieldInputIndex | null>(null);
  const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);
  const [list, setList] = useState<ModelFieldInputIndex[]>([]);

  useEffect(() => {
    const dataTime = new Date();
    const list: ModelFieldInputIndex[] = props.data.map((item, index) => {
      return {
        ...item,
        index: index + dataTime.getTime(),
      };
    });
    setList(list);
  }, [props]);

  const changeFields = (list: ModelFieldInputIndex[]) => {
    const listData: ModelFieldInput[] = list.map((item) => {
      return {
        id: item.id,
        key: item.key,
        description: item.description,
        type: item.type,
        field_type_id: item.field_type_id,
      };
    });
    props.onChange(listData);
  };

  const handleOkDelete = () => {
    const listData: ModelFieldInputIndex[] = list.filter(
      (item) => item.index !== field?.index
    );
    setList(listData);
    changeFields(listData);
    setIsModalVisibleDel(false);
  };

  const onChange = (data: ModelFieldInputIndex) => {
    if (!add) {
      const listData: ModelFieldInputIndex[] = list.map((item) => {
        if (item.index === data.index) {
          return data;
        }
        return item;
      });
      setList(listData);
      changeFields(listData);
    } else {
      list.push(data);
      setList(list);
      changeFields(list);
    }

    setIsModalVisibleDel(false);
    setIsModal(false);
  };

  return (
    <Row gutter={[10, 10]} style={{ marginTop: "50px" }}>
      <Col span={24}>
        <Title style={{ textAlign: "center" }} level={5}>
          Field Item Model
        </Title>
      </Col>
      <Col span={24}>
        <Table dataSource={list} size="middle" rowKey={"id"} pagination={false}>
          <Column title="Key" dataIndex="key" key="key" />
          <Column width={120} title="Type" dataIndex="type" key="type" />
          <Column
            width={200}
            title="Field Type"
            dataIndex="field_type"
            key="field_type"
            render={(text: any, record: ModelFieldInputIndex) => {
              if (!record.field_type_id) {
                return "--";
              }

              return record.field_type_id;
            }}
          />
          {props.isEdit && (
            <Column
              title=""
              key="action"
              width={10}
              render={(text: any, record: ModelFieldInputIndex) => (
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(info) => {
                        onMenu(info, record);
                      }}
                    >
                      <Menu.Item key="edit" icon={<EditOutlined />}>
                        Edit
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="delete" icon={<DeleteOutlined />}>
                        Delete
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    type="text"
                    icon={<MoreOutlined />}
                    onClick={(e) => e.preventDefault()}
                  />
                </Dropdown>
              )}
            />
          )}
        </Table>
      </Col>
      {props.isEdit && (
        <Col span={24} style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              setField(null);
              setIsModal(true);
              setAdd(true);
            }}
          >
            Add New Field
          </Button>
        </Col>
      )}

      <Modal
        visible={isModal}
        title={add ? "Add Field" : "Edit Field"}
        maskClosable={false}
        onCancel={() => {
          setIsModal(false);
        }}
        footer={null}
        closable={false}
      >
        <EditField
          data={field}
          onClose={() => {
            setIsModal(false);
          }}
          onChange={onChange}
        />
      </Modal>
      <Modal
        title="Delete Field"
        visible={isModalVisibleDel}
        onOk={handleOkDelete}
        onCancel={() => {
          setIsModalVisibleDel(false);
        }}
      >
        <p>
          You do want to delete key <Tag color={"blue"}>{field?.key}</Tag>!
        </p>
      </Modal>
    </Row>
  );
};

export default EditModelField;

import { Line } from "@ant-design/plots";
import { isArray, isUndefined } from "lodash";
import { useEffect, useRef } from "react";
import { WidgetData } from "src/modules/widget/models/data";
import { WidgetOption } from "src/modules/widget/models/widget";

const View: React.FC<{
  data: WidgetData;
  option: WidgetOption;
  height?: number
  width?: number
}> = (props) => {
  let { option, data, height, width } = props

  const chartRef = useRef();

  if (isUndefined(data.list) || data.list == null || !isArray(data.list)) {
    data.list = []
  }
  var config = {
    data: data[option.data_key || "list" ] || [],
    xField: option.x_field || 'time',
    yField: option.y_field || 'value_n',
    seriesField: option.series_field || 'key',
    legend: { size: false },
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
    style: {
      lineWidth: 2,
    },
    height: height,
    width: width
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ref = chartRef.current as any
      ref.changeSize(width, height)
    }
  }, [height, width])

  return (
    <Line {...config}  padding='auto' chartRef={chartRef}/>
  );
};

export default View;


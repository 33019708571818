import { Alert, Button, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { RequestStatus, ThunkResult, useAppDispatch } from "src/app/hooks";
import SelectFieldOne from "src/modules/device_log/components/select-field-one";
import SelectProperty from "src/modules/device_property/components/select-property";
import { SourceInput, TypeDeviceLog } from "src/types";
import { updateDeviceModelThunkAction } from "../../thunk";
import { DeviceModelFieldAndModel } from "./fields";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const EditField: React.FC<{
  assignId: any | null;
  deviceId: string;
  data: DeviceModelFieldAndModel | null;
  onClose: (loading: boolean) => void;
}> = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, selectMessage] = useState("");
  const [typeDeviceLog, setTypeDeviceLog] = useState<TypeDeviceLog>(
    TypeDeviceLog.Log
  );
  const [sourceInput, setSourceInput] = useState<SourceInput>(
    SourceInput.Property
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.data != null) {
      form.resetFields();

      if (props.data.source_input) {
        form.setFields([
          {
            name: "source_input",
            value: props.data.source_input,
          },
        ]);

        setSourceInput(props.data.source_input as SourceInput);
      }

      if (props.data.description) {
        form.setFields([
          {
            name: "description",
            value: props.data.description,
          },
        ]);
      }

      if (props.data.key) {
        form.setFields([
          {
            name: "key",
            value: props.data.key,
          },
        ]);
      }

      form.setFields([
        {
          name: "model_key",
          value: props.data.model_field.key,
        },
      ]);
    }
  }, [props, form]);

  const onFinish = async (values: any) => {
    let res: ThunkResult<boolean, any>;
    setLoading(true);

    try {
      res = await dispatch<any>(
        updateDeviceModelThunkAction({
          id: props.data?.id,
          assign_id: props.assignId,
          field_id: props.data?.field_id,
          key: values.key,
          description: values.description,
          source_input: values.source_input,
        })
      );
    } catch (error) {
      console.log(error);
      return;
    }

    setLoading(false);
    if (res.meta.requestStatus === RequestStatus.Fulfilled) {
      props.onClose(true);
    } else {
      selectMessage(res.error.message);
    }
  };

  const onChange = (fieldType: SourceInput) => {
    form.setFields([
      {
        name: "key",
        value: "",
      },
    ]);
    switch (fieldType) {
      case SourceInput.Telemetry: {
        setTypeDeviceLog(TypeDeviceLog.Log);
        break;
      }
      case SourceInput.Property: {
        setTypeDeviceLog(TypeDeviceLog.Property);
        break;
      }
    }
    setSourceInput(fieldType);
  };

  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row gutter={[10, 10]}>
          {message !== "" && (
            <Col span={24}>
              <Alert type="error" message={message}></Alert>
            </Col>
          )}
          <Col span={24}>
            <Form.Item name="model_key" label="Model Key" style={{ margin: 0 }}>
              <Input size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"source_input"}
              label="Source Input"
              style={{ margin: 0 }}
              rules={[
                { required: true, message: "Please select Source Input!" },
              ]}
            >
              <Select size="large" onChange={onChange}>
                <Select.Option value={SourceInput.Telemetry}>
                  Telemetry
                </Select.Option>
                <Select.Option value={SourceInput.Property}>
                  Device Property
                </Select.Option>
                <Select.Option value={SourceInput.ServerProperty}>
                  Server Property
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {(sourceInput === SourceInput.Telemetry ||
            sourceInput === SourceInput.Property) && (
            <Col span={24}>
              <SelectFieldOne
                name="key"
                type={typeDeviceLog}
                deviceId={props.deviceId}
                label={"Key"}
                rules={[{ required: true, message: "Please input key!" }]}
              />
            </Col>
          )}
          {sourceInput === SourceInput.ServerProperty && (
            <Col span={24}>
              <SelectProperty
                name="key"
                deviceId={props.deviceId}
                label={"Key"}
                rules={[{ required: true, message: "Please input key!" }]}
              />
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              style={{ margin: 0 }}
            >
              <Input.TextArea size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row justify="center" gutter={[10, 10]}>
              <Col style={{ width: 100 }}>
                <Input size="large" type="submit" value="Save" />
              </Col>

              <Col>
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default EditField;

import { Button, Col, Form, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import SelectNamespace from "src/modules/namespace/components/select-namespace";
import { WidgetData } from "src/types";
import TimeWindow from "./time-window";
import TypeSource from "./type-source";
import Header from "./header";
import SelectDevice from "src/modules/device/components/select-device";
import SelectModelAssign from "src/modules/device_model/components/select-model-assign";
import CheckboxFieldModel from "src/modules/model/components/checkbox-field-model";
import TimeData from "./time";

const OptionWidget: React.FC<{
  option: WidgetData;
  data?: any;
  submit: (data: WidgetData) => void;
}> = (props) => {
  const { option } = props;

  const [form] = Form.useForm<any>();
  const [formGeneral] = Form.useForm<any>();
  const [fields, setFields] = useState<string[]>([]);
  const [namespaceId, setNamespaceId] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [modelId, setModelId] = useState<string>("");
  const [tab, setTab] = useState("raw");

  useEffect(() => {
    form.setFieldsValue(option);
    formGeneral.setFieldsValue(option);

    setNamespaceId(option.namespace_id);
    setDeviceId(option.device_id);
    setModelId(option.model_id);
    setFields(option.fields || []);
  }, [option, form, formGeneral]);

  const onSubmit = async () => {
    const valueGeneral = await formGeneral.validateFields();
    const values = await form.validateFields();

    props.submit({
      model_id: values.model_id,
      namespace_id: values.namespace_id,
      device_id: values.device_id,
      fields: fields,
      time: valueGeneral.time,
      source: valueGeneral.source,
      type: valueGeneral.type,
      window: valueGeneral.window,
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Form form={formGeneral}>
          <Header
            extra={
              <Row gutter={[5, 5]}>
                <Col>
                  <TypeSource />
                </Col>
                <Col>
                  <TimeWindow />
                </Col>
                <Col>
                  <TimeData />
                </Col>
                <Col>
                  <Button type="primary" onClick={onSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            }
          ></Header>
        </Form>
      </Col>
      <Col span={24}>
        <Form layout="vertical" form={form}>
          <Row gutter={[10, 10]}>
            <Col span={4}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <SelectNamespace
                    name="namespace_id"
                    label="Namespace"
                    disabled={false}
                    rules={[{ required: true, message: "Required namespace!" }]}
                    onChange={(value) => {
                      setNamespaceId(value);
                      setDeviceId("");
                      setModelId("");
                      setFields([]);
                      form.setFields([
                        {
                          name: "device_id",
                          value: "",
                        },
                        {
                          name: "model_id",
                          value: "",
                        },
                        {
                          name: "fields",
                          value: [],
                        },
                      ]);
                    }}
                  />
                </Col>
                <Col span={24}>
                  <SelectDevice
                    name="device_id"
                    label="Device"
                    namepsace_id={namespaceId}
                    value={deviceId}
                    disabled={namespaceId === ""}
                    rules={[{ required: true, message: "Required device!" }]}
                    onChange={(value) => {
                      setDeviceId(value);
                      setModelId("");
                      form.setFields([
                        {
                          name: "model_id",
                          value: "",
                        },
                      ]);
                    }}
                  />
                </Col>
                <Col span={24}>
                  <SelectModelAssign
                    label="Model"
                    deviceId={deviceId || ""}
                    name="model_id"
                    value={modelId}
                    disabled={deviceId === ""}
                    rules={[{ required: true, message: "Required model!" }]}
                    onChange={(value) => {
                      setModelId(value);
                      if (value === "") {
                        setFields([]);
                        form.setFields([
                          {
                            name: "fields",
                            value: [],
                          },
                        ]);
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <CheckboxFieldModel
                modelId={modelId}
                name="fields"
                label="Fields"
                value={fields}
                disabled={modelId === ""}
                onChange={(value) => {
                  setFields(value);
                }}
              />
            </Col>
            <Col span={16}>
              <Tabs
                activeKey={tab}
                onChange={(value) => {
                  setTab(value);
                }}
              >
                <Tabs.TabPane tab="Aggregate" key="aggr"></Tabs.TabPane>
                <Tabs.TabPane
                  tab="Raw Data Reponse"
                  key="raw"
                  style={{
                    height: "250px",
                    overflow: "auto",
                    border: "1px solid #424857",
                  }}
                >
                  <ReactJson name={false} src={props.data} />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default OptionWidget;

import { Breadcrumb, Button, Card, Col, Drawer, PageHeader, Row } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Model } from "src/types";
import FilterModel from "src/modules/model/components/filter";
import ListModel from "src/modules/model/components/list";
import EditModel from "src/modules/model/components/edit/edit";
import { deleteModelThunkAction } from "src/modules/model/thunk";
import { useAppDispatch } from "src/app/hooks";

const ListModelPage: React.FC<{}> = (props) => {
  const [model, setSelectModel] = useState<Model | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [add, setAdd] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onAction = async (action: string, row: Model) => {
    setSelectModel(row);
    switch (action) {
      case "detail": {
        setIsEdit(false);
        setIsModal(true);
        break;
      }
      case "edit": {
        setAdd(false);
        setIsEdit(true);
        setIsModal(true);
        break;
      }
      case "delete": {
        await dispatch<any>(deleteModelThunkAction(row.id));
        callbackLoadData();
        console.log("del namespace", model);
        break;
      }
    }
  };

  const onAdd = () => {
    setAdd(true);
    setIsEdit(true);
    setSelectModel(null);
    setIsModal(true);
  };

  let callbackLoadData = () => {};

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - List Model</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Model</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Card size={"small"}>
            <PageHeader
              className="page-header-responsive"
              title="Models"
              extra={
                <>
                  <FilterModel />
                  <Button type="primary" onClick={onAdd}>
                    Add Model
                  </Button>
                </>
              }
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>
          <ListModel
            onAction={onAction}
            refLoading={(callback) => {
              callbackLoadData = callback;
            }}
          />
        </Col>
      </Row>

      <Drawer
        title={!isEdit ? "Detail Model" : add ? "Add Model" : "Edit Model"}
        placement="right"
        width={600}
        closable={false}
        visible={isModal}
        onClose={() => {
          if (!isEdit) {
            setIsModal(false);
          }
        }}
      >
        <EditModel
          data={model}
          isEdit={isEdit}
          onClose={(loading) => {
            setIsModal(false);
            if (loading) {
              callbackLoadData();
            }
          }}
          onEdit={() => {
              setIsEdit(true)
          }}
        ></EditModel>
      </Drawer>
    </div>
  );
};

export default ListModelPage;

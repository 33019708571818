import { WidgetComponent } from "../../../../models/widget"
import Edit from "./edit"
import View from "./view"

const wiget: WidgetComponent = {
    type: "process-gauge",
    edit: Edit,
    icon: "process-gauge-chart",
    name: "Process Gauge",
    view: View,
    options: [],
}

export default wiget
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeListModeIdHaveLogByDeviceIdQueryVariables = Types.Exact<{
  deviceId: Types.Scalars['Uint64'];
}>;


export type GeListModeIdHaveLogByDeviceIdQuery = { __typename?: 'Query', geListModeIdHaveLogByDeviceId?: Array<{ __typename?: 'Model', id: any, name: string }> | null };


export const GeListModeIdHaveLogByDeviceIdDocument = gql`
    query geListModeIdHaveLogByDeviceId($deviceId: Uint64!) {
  geListModeIdHaveLogByDeviceId(DeviceId: $deviceId) {
    id
    name
  }
}
    `;

/**
 * __useGeListModeIdHaveLogByDeviceIdQuery__
 *
 * To run a query within a React component, call `useGeListModeIdHaveLogByDeviceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeListModeIdHaveLogByDeviceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeListModeIdHaveLogByDeviceIdQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGeListModeIdHaveLogByDeviceIdQuery(baseOptions: Apollo.QueryHookOptions<GeListModeIdHaveLogByDeviceIdQuery, GeListModeIdHaveLogByDeviceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeListModeIdHaveLogByDeviceIdQuery, GeListModeIdHaveLogByDeviceIdQueryVariables>(GeListModeIdHaveLogByDeviceIdDocument, options);
      }
export function useGeListModeIdHaveLogByDeviceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeListModeIdHaveLogByDeviceIdQuery, GeListModeIdHaveLogByDeviceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeListModeIdHaveLogByDeviceIdQuery, GeListModeIdHaveLogByDeviceIdQueryVariables>(GeListModeIdHaveLogByDeviceIdDocument, options);
        }
export type GeListModeIdHaveLogByDeviceIdQueryHookResult = ReturnType<typeof useGeListModeIdHaveLogByDeviceIdQuery>;
export type GeListModeIdHaveLogByDeviceIdLazyQueryHookResult = ReturnType<typeof useGeListModeIdHaveLogByDeviceIdLazyQuery>;
export type GeListModeIdHaveLogByDeviceIdQueryResult = Apollo.QueryResult<GeListModeIdHaveLogByDeviceIdQuery, GeListModeIdHaveLogByDeviceIdQueryVariables>;
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface ThunkResult<R,A>  {
    error: Error
    meta: {
        aborted: boolean
        arg: A
        condition: boolean
        rejectedWithValue: boolean
        requestId: string
        requestStatus: RequestStatus
    }
    payload: R
    type: string
};

export enum RequestStatus {
    Rejected = 'rejected',
    Fulfilled = 'fulfilled'
}
import { Checkbox, Col, Form, Row, Spin } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Rule } from "antd/lib/form";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDetail, selectLoading } from "../slice";
import { getModelByIdThunkAction } from "../thunk";

const CheckboxFieldModel: React.FC<{
  modelId: any;
  disabled?: boolean;
  label?: string;
  name: string;
  rules?: Rule[] | undefined;
  onChange?: (field: string[]) => void;
  value?: string[];
}> = (props) => {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetail);
  const { modelId } = props;

  useEffect(() => {
    dispatch<any>(getModelByIdThunkAction(modelId));
  }, [modelId, dispatch]);

  return (
    <Form.Item
      name={props.name}
      label={props.label ? props.label : "Field Model"}
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Spin spinning={loading}>
        <Checkbox.Group
          value={props.value}
          onChange={(checkedValue: CheckboxValueType[]) => {
            const values = checkedValue.map((item) => item.toString());
            if (props.onChange) {
              props.onChange(values);
            }
          }}
        >
          <Row>
            {detail?.fields?.map((item) => {
              return (
                <Col span={24} key={item.key}>
                  <Checkbox value={item.key}> {item.key}</Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Spin>
    </Form.Item>
  );
};

export default CheckboxFieldModel;

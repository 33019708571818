import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFieldDeviceModelMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.DeviceModelFieldInput>;
}>;


export type UpdateFieldDeviceModelMutation = { __typename?: 'Mutation', updateFieldDeviceModel: boolean };


export const UpdateFieldDeviceModelDocument = gql`
    mutation updateFieldDeviceModel($input: DeviceModelFieldInput) {
  updateFieldDeviceModel(input: $input)
}
    `;
export type UpdateFieldDeviceModelMutationFn = Apollo.MutationFunction<UpdateFieldDeviceModelMutation, UpdateFieldDeviceModelMutationVariables>;

/**
 * __useUpdateFieldDeviceModelMutation__
 *
 * To run a mutation, you first call `useUpdateFieldDeviceModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldDeviceModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldDeviceModelMutation, { data, loading, error }] = useUpdateFieldDeviceModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFieldDeviceModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldDeviceModelMutation, UpdateFieldDeviceModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldDeviceModelMutation, UpdateFieldDeviceModelMutationVariables>(UpdateFieldDeviceModelDocument, options);
      }
export type UpdateFieldDeviceModelMutationHookResult = ReturnType<typeof useUpdateFieldDeviceModelMutation>;
export type UpdateFieldDeviceModelMutationResult = Apollo.MutationResult<UpdateFieldDeviceModelMutation>;
export type UpdateFieldDeviceModelMutationOptions = Apollo.BaseMutationOptions<UpdateFieldDeviceModelMutation, UpdateFieldDeviceModelMutationVariables>;
import { Tiny } from '@ant-design/plots';
import { isUndefined } from "lodash";
import React, { Component } from "react";
import { WidgetData } from "src/modules/widget/models/data";
import { WidgetOption } from "src/modules/widget/models/widget";

type P = {
    data: WidgetData
    option: WidgetOption
};

type S = {

};

class ViewRing extends Component<P, S>{
    state = {}
    render() {
        let { data } = this.props

        if(isUndefined(data.list) || data.list == null){
            data.list = []
        }

        const percent = 0.7;
        const config = {
            percent,
            width: 300,
            height: 300,
            color: ['#E8EFF5', '#66AFF4'],
            annotations: [
            {
                type: 'text',
                style: {
                text: `${percent * 100}%`,
                x: '50%',
                y: '50%',
                textAlign: 'center',
                fontSize: 16,
                fontStyle: 'bold',
                },
            },
            ],
        };

        return <Tiny.Ring {...config} />
    }
}

export default ViewRing
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUiMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type DeleteUiMutation = { __typename?: 'Mutation', deleteUi: boolean };


export const DeleteUiDocument = gql`
    mutation deleteUi($id: Uint64!) {
  deleteUi(id: $id)
}
    `;
export type DeleteUiMutationFn = Apollo.MutationFunction<DeleteUiMutation, DeleteUiMutationVariables>;

/**
 * __useDeleteUiMutation__
 *
 * To run a mutation, you first call `useDeleteUiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUiMutation, { data, loading, error }] = useDeleteUiMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUiMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUiMutation, DeleteUiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUiMutation, DeleteUiMutationVariables>(DeleteUiDocument, options);
      }
export type DeleteUiMutationHookResult = ReturnType<typeof useDeleteUiMutation>;
export type DeleteUiMutationResult = Apollo.MutationResult<DeleteUiMutation>;
export type DeleteUiMutationOptions = Apollo.BaseMutationOptions<DeleteUiMutation, DeleteUiMutationVariables>;
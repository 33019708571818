import { Breadcrumb, Button, Card, Col, Drawer, Modal, PageHeader, Row } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import EditChan from "src/modules/rule_chan/components/edit";
import ListRuleChan from "src/modules/rule_chan/components/list-rule-chan";
import { deleteRuleChanThunkAction } from "src/modules/rule_chan/thunk";
import { Chan } from "src/types";

const ListRuleChanPage: React.FC<{}> = () => {

  const [isModaRuleChan, setModalRuleChan] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [chanInfo, setChanInfo] = useState<Chan>()
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const onAdd = () => {
    setEdit(false)
    setIsModal(true)
  }

  const callbackLoadData = () => {

  }

  const onAction = async (action: string, row: Chan) => {
    setChanInfo(row);
    switch (action) {
      case "detail": {
        
        return;
      }
      case "update_rule_chan": {
        nav(`/rule_chan/${row.id}/edit`);
        return;
      }
      case "edit": {
       // setAdd(false);
        setEdit(true);
        setIsModal(true);
        return;
      }
      case "delete": {
        await dispatch<any>(deleteRuleChanThunkAction(row.id));
        callbackLoadData();
        console.log("del namespace", row);
        return;
      }
    }
  };

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - Rule Chan</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Rule Chan</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Card size={"small"}>
            <PageHeader
              className="page-header-responsive"
              title="Rule Chan"
              extra={
                <>
                  <Button type="primary" onClick={onAdd}>
                    Add Rule Chan
                  </Button>
                </>
              }
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>

          <ListRuleChan 
            refLoading={callbackLoadData}
            onAction={onAction}
          />

        </Col>

      </Row>

      <Drawer
        title={"List Rule Chan"}
        placement="right"
        width={600}
        visible={isModaRuleChan}
        onClose={() => {
          setModalRuleChan(false);
        }}
      >
        {isModaRuleChan && <></>}
      </Drawer>

      <Modal
          visible={isModal}
          title={
            !isEdit
              ? "Add Rule Chan"
              : "Edit Rule Chan"
          }
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
          <EditChan
            data={chanInfo}
            isEdit={true}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                
              }
            }}
            onEdit={() => {
              setEdit(true);
            }}
          />
        </Modal>
    </div>
  );
};

export default ListRuleChanPage;

import { Col, Form, Input, Radio, Row } from "antd";
import React, { useState } from "react";
import { TypeCertificate } from "src/types";

const EditDeviceCertificate: React.FC<{
  isEdit: boolean;
}> = (props) => {
  const [type, setType] = useState(TypeCertificate.Certificate);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Form.Item name="certificate_type" style={{ margin: 0 }}>
          <Radio.Group
            onChange={(val) => {
              setType(val.target.value);
            }}
            disabled={!props.isEdit}
            value={type}
            style={{ marginBottom: 8 }}
          >
            <Radio.Button value={TypeCertificate.Certificate}>
              Certificate
            </Radio.Button>
            <Radio.Button value={TypeCertificate.Token}>Token</Radio.Button>
            <Radio.Button value={TypeCertificate.Gateway}>Gateway</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
      {type === TypeCertificate.Certificate && (
        <>
          <Col span={24}>
            <Form.Item
              name="username"
              label="Username"
              style={{ margin: 0 }}
              rules={[
                {
                  required: type === TypeCertificate.Certificate,
                  message: "Please input username!",
                },
              ]}
            >
              <Input size="large" disabled={!props.isEdit} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Password"
              style={{ margin: 0 }}
              rules={[
                {
                  required: type === TypeCertificate.Certificate,
                  message: "Please input password!",
                },
              ]}
            >
              <Input.Password size="large" disabled={!props.isEdit} />
            </Form.Item>
          </Col>
        </>
      )}

      {type === TypeCertificate.Token && (
        <Col span={24}>
          <Form.Item
            name="token"
            label="Token"
            style={{ margin: 0 }}
            rules={[
              {
                required: type === TypeCertificate.Token,
                message: "Please input token!",
              },
            ]}
          >
            <Input size="large" disabled={!props.isEdit} />
          </Form.Item>
        </Col>
      )}

      {type === TypeCertificate.Gateway && (
        <Col span={24}>
          <Form.Item
            name="token"
            label="Token"
            style={{ margin: 0 }}
            rules={[
              {
                required: type === TypeCertificate.Gateway,
                message: "Please input token!",
              },
            ]}
          >
            <Input size="large" disabled={!props.isEdit} />
          </Form.Item>
        </Col>
      )}

      <Col span={24}>
        <Form.Item name="client_id" label="Client Id" style={{ margin: 0 }}>
          <Input size="large" disabled={true} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default EditDeviceCertificate;

import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModelByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetModelByIdQuery = { __typename?: 'Query', getModelById: { __typename?: 'ModelDetail', id: any, name: string, description?: string | null, status?: Types.StatusModel | null, created_by?: any | null, updated_by?: any | null, created_at: any, updated_at: any, fields?: Array<{ __typename?: 'ModelField', id: any, key: string, description?: string | null, type: Types.TypeField, field_type_id?: any | null, field_type?: { __typename?: 'FieldType', id: any, name: string, description?: string | null, type: Types.TypeField, is_public: boolean } | null }> | null } };


export const GetModelByIdDocument = gql`
    query getModelById($id: Uint64!) {
  getModelById(id: $id) {
    id
    name
    description
    status
    created_by
    updated_by
    created_at
    updated_at
    fields {
      id
      key
      description
      type
      field_type_id
      field_type {
        id
        name
        description
        type
        is_public
      }
    }
  }
}
    `;

/**
 * __useGetModelByIdQuery__
 *
 * To run a query within a React component, call `useGetModelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModelByIdQuery(baseOptions: Apollo.QueryHookOptions<GetModelByIdQuery, GetModelByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelByIdQuery, GetModelByIdQueryVariables>(GetModelByIdDocument, options);
      }
export function useGetModelByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelByIdQuery, GetModelByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelByIdQuery, GetModelByIdQueryVariables>(GetModelByIdDocument, options);
        }
export type GetModelByIdQueryHookResult = ReturnType<typeof useGetModelByIdQuery>;
export type GetModelByIdLazyQueryHookResult = ReturnType<typeof useGetModelByIdLazyQuery>;
export type GetModelByIdQueryResult = Apollo.QueryResult<GetModelByIdQuery, GetModelByIdQueryVariables>;
import { Breadcrumb, Card, Col, Row, Spin } from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ListWidget from "src/modules/widget/components/list-widget";
import { LineWidget, QueryDataShowWidget } from "src/modules/widget/components/widget";
import WidgetEdit from "src/modules/widget/components/widget-edit/widget-edit";
import { Widget, WidgetDetail } from "src/types";

const PreviewWidget: React.FC<{}> = (props) => {

  const [detail, setDetail] = useState<WidgetDetail | null>(null);
  const [, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, ] = useState(false);
  const [, setData] = useState<any>({});

  const onAction = (widget: Widget | null, action: string) => {
    switch (action) {
      case "new": {
        setDetail({
          name: "",
          option: {},
          data: {
            model_id: "",
            namespace_id: "",
            device_id: "",
            source: "model_log",
            time: "5m",
            type: "list",
          },
          created_at: "",
          type: LineWidget.type,
          updated_at: "",
          id: "",
        });
        setAdd(true);
        setEdit(true);
        return;
      }
      case "detail": {
        break;
      }
      case "delete": {
        return;
      }
    }
  };


  return (
    <div className="session-main" style={{  overflow: "hidden",height: "100%" }}>
      <Helmet>
        <title>Dipper - List Widget</title>
      </Helmet>
      <Row gutter={[0, 10]} style={{ height: "100%" }}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/ui"}>Ui</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Widget</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} style={{ height: "100%" }}>
          <Row gutter={[2, 2]} style={{ height: "100%" }}>
            <Col span={6}>
              <Card size="small" style={{ height: "100%" }}>
                {!edit && <ListWidget action={onAction} />}
                {detail !== null && edit && (
                  <Spin spinning={loading}>
                    <WidgetEdit
                      save={() => {}}
                      cancel={() => {
                        setEdit(false);
                      }}
                      change={(item) => {
                        setDetail(item);
                      }}
                      widget={detail}
                    />
                  </Spin>
                )}
              </Card>
            </Col>
            <Col span={18}>
              <Card size="small" style={{ height: 550 }}>
                {detail !== null && (
                  <QueryDataShowWidget
                    widget={detail}
                    onData={(value) => {
                      setData(value);
                    }}
                    height={500}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewWidget;

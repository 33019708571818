import { Typography, Alert, Button, Col, Form, Input, Row, Select, Spin, Modal, Table } from "antd";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleInfo, RuleOptionProps } from "../rule";
import { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";


const { Text } = Typography;

export const FormatRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: 'red' }}
                id="input"
                title="input"
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Format</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                title="output"
                style={{ background: 'green' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}


export interface ItemFormatModel {
    target: string;
    format: string;
    option: string;
    source: string;
}

const listFormart = ["timestamp", "time", "number", "string", "double", "bool"]

const EditArithmetic: React.FC<{
    data: ItemFormatModel | null;
    onClose: () => void;
    onSave: (data: ItemFormatModel) => void;
  }> = (props) => {
    const [form] = Form.useForm();
  
    useEffect(() => {
      if (props.data !== null) {
        form.resetFields();
  
        if (props.data.target) {
          form.setFields([
            {
              name: "target",
              value: props.data.target,
            },
          ]);
  
        }
  
        if (props.data.source) {
          form.setFields([
            {
              name: "source",
              value: props.data.source,
            },
          ]);
        }
      }
    }, [props, form]);
  
    const onFinish = (values: any) => {
      props.onSave(values);
      form.resetFields(["target","source" ])
    };

    const onClose = () => {
        form.resetFields(["target","source" ])
        props.onClose()
    }
  
    return (
        <Form {...layout} onFinish={onFinish} form={form}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
                <Form.Item name="target" label="Target Key"  style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="format" label="Format type"  style={{ margin: 0 }}>
                    <Select size="large">
                        {
                            listFormart.map((item: string) => {
                                return (<Select.Option key={item} value={item}>{ item }</Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
  
            <Col span={24}>
                <Form.Item name="option" label="Option format"  style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="source" label="Source Key"  style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                  <Input size="large" type="submit" value="Save" />
                </Col>
  
                <Col>
                  <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
    );
  };

const EditItemFormat: React.FC<{
    list: ItemFormatModel[];
    onChange: (list: ItemFormatModel[]) => void
}> = (props) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [field, setField] = useState<ItemFormatModel | null>(null);
  const [list, setList] = useState<ItemFormatModel[]>([]);


  const onEdit = (row: ItemFormatModel) => {
    setField(row);
    setIsModal(true);
  };

  const onAdd  = () => {
    setIsModal(true);
    setField(null);
  }

  const onRemove  = (target: string) => {
    const newList = list.filter((item) => {
        return item.target !== target
    })
    setList(newList);
    props.onChange(newList)
  }

  const onSaveItem =  (data: ItemFormatModel) => {    
    data.target = data.target.trim()
    let found = false;
    const newList = list.map((item) => {
        if (item.target === data.target) {
            found = true;
            return data
        }
        return item
    })
    if (!found) {
        newList.push(data)
    }
    setList(newList);
    props.onChange(newList)
    setIsModal(false);
  }

  useEffect(() => {
    if (props.list && Array.isArray(props.list)) {
        setList(props.list)
    }
  }, [props])

  return (
    <Row gutter={[10, 10]} style={{ marginTop: "50px" }}>
        <Col span={16}>
          <Title level={5}>
            Fields Format
          </Title>
        </Col>
        <Col span={8} style={{ textAlign: "right" }} >
            <Button
            type="primary"
            style={{ width: 100 }}
            onClick={onAdd}
            >
                Add
            </Button>
        </Col>
        <Col span={24}>
          <Table
            dataSource={list}
            size="middle"
            rowKey={"id"}
            pagination={false}
          >
            <Column
              title="Target Key"
              dataIndex="target"
              key="target"
            />
            <Column
              title="Format"
              dataIndex="format"
              key="format"
            />
            <Column
              title="Format Option"
              dataIndex="option"
              key="option"
            />
             <Column
              title="Source Key"
              dataIndex="source"
              key="source"
            />
            <Column
              title=""
              key="action"
              width={100}
              render={(text: any, record: ItemFormatModel) => (
                <>
                    <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={(e) => {
                            onEdit(record);
                        }}
                    />
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            onRemove(record.target);
                        }}
                    />
                </>
              )}
            />
          </Table>
        </Col>

        <Modal
          visible={isModal}
          title={"Format"}
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
           <EditArithmetic
            data={field}
            onClose={() => {
              setIsModal(false);
            }}
            onSave={onSaveItem}
          />
        </Modal>
    </Row>
  );
};


const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const FormatRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        form.setFields([
            {
                name: "name",
                value: props.label,
            },
          ])
    }, [props, form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {
                model_id: values.model_id,
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
              <EditItemFormat list={[]} onChange={() => {}} />
            </Col>
            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const FormatRuleInfo: RuleInfo = {
    id: "format",
    name: "Format",
    rule: FormatRule,
    option: FormatRuleOption,
    infinite: false,
    option_default: {
    },
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { ListPaginationUiInput, UiInput } from 'src/types'

import { GetListPaginationUiDocument, GetListPaginationUiQuery, GetListPaginationUiQueryVariables } from './graph/getListPaginationUi.generated'
import { GetUiByIdDocument, GetUiByIdQuery, GetUiByIdQueryVariables } from './graph/getUiById.generated'
import { CreateUiDocument, CreateUiMutation, CreateUiMutationVariables } from './graph/createUi.generated'
import { UpdateUiDocument, UpdateUiMutation, UpdateUiMutationVariables } from './graph/updateUi.generated'
import { DeleteUiDocument, DeleteUiMutation, DeleteUiMutationVariables } from './graph/deleteUi.generated'
import { GetUiHomeDocument, GetUiHomeQuery } from "./graph/getUiHome.generated";

export const getListPaginationUiThunkAction = createAsyncThunk<
    GetListPaginationUiQuery,
    ListPaginationUiInput,
    { extra: { client: GraphQLClient } }
>(
    'Ui/getListPaginationUiThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationUiQueryVariables, GetListPaginationUiQuery>(GetListPaginationUiDocument, { input: data })
        return res;
    }
)

export const getSearchUiThunkAction = createAsyncThunk<
    GetListPaginationUiQuery,
    ListPaginationUiInput,
    { extra: { client: GraphQLClient } }
>(
    'Ui/getSearchUiThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationUiQueryVariables, GetListPaginationUiQuery>(GetListPaginationUiDocument, { input: data })
        return res;
    }
)


export const getUiByIdThunkAction = createAsyncThunk<
    GetUiByIdQuery,
    any,
    { extra: { client: GraphQLClient } }
>(
    'Ui/getUiByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetUiByIdQueryVariables, GetUiByIdQuery>(GetUiByIdDocument, { id: id })
        return res;
    }
)


export const createUiThunkAction = createAsyncThunk<
    CreateUiMutation,
    UiInput,
    { extra: { client: GraphQLClient } }
>(
    'Ui/createUiThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateUiMutationVariables, CreateUiMutation>(CreateUiDocument, { input: data })
        return res;
    }
)

export const updateUiThunkAction = createAsyncThunk<
    UpdateUiMutation,
    UpdateUiMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'Ui/updateUiThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateUiMutationVariables, UpdateUiMutation>(UpdateUiDocument, data)
        return res;
    }
)

export const deleteUiThunkAction = createAsyncThunk<
    DeleteUiMutation,
    number,
    { extra: { client: GraphQLClient } }
>(
    'Ui/createUiThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<DeleteUiMutationVariables, DeleteUiMutation>(DeleteUiDocument, { id: data })
        return res;
    }
)

export const getUiHomeThunkAction = createAsyncThunk<
    GetUiHomeQuery,
    any,
    { extra: { client: GraphQLClient } }
>(
    'Ui/getUiHomeThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.query<any, GetUiHomeQuery>(GetUiHomeDocument)
        return res;
    }
)
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRuleChanNodeMutationVariables = Types.Exact<{
  chanId: Types.Scalars['Uint64'];
  input: Types.InputRuleNode;
}>;


export type UpdateRuleChanNodeMutation = { __typename?: 'Mutation', updateRuleNode: boolean };


export const UpdateRuleChanNodeDocument = gql`
    mutation updateRuleChanNode($chanId: Uint64!, $input: InputRuleNode!) {
  updateRuleNode(chanId: $chanId, input: $input)
}
    `;
export type UpdateRuleChanNodeMutationFn = Apollo.MutationFunction<UpdateRuleChanNodeMutation, UpdateRuleChanNodeMutationVariables>;

/**
 * __useUpdateRuleChanNodeMutation__
 *
 * To run a mutation, you first call `useUpdateRuleChanNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRuleChanNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuleChanNodeMutation, { data, loading, error }] = useUpdateRuleChanNodeMutation({
 *   variables: {
 *      chanId: // value for 'chanId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRuleChanNodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRuleChanNodeMutation, UpdateRuleChanNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRuleChanNodeMutation, UpdateRuleChanNodeMutationVariables>(UpdateRuleChanNodeDocument, options);
      }
export type UpdateRuleChanNodeMutationHookResult = ReturnType<typeof useUpdateRuleChanNodeMutation>;
export type UpdateRuleChanNodeMutationResult = Apollo.MutationResult<UpdateRuleChanNodeMutation>;
export type UpdateRuleChanNodeMutationOptions = Apollo.BaseMutationOptions<UpdateRuleChanNodeMutation, UpdateRuleChanNodeMutationVariables>;
import { Alert, Button, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { RequestStatus, ThunkResult, useAppDispatch } from "src/app/hooks";
import { Chan, ChanStatus } from "src/types";
import {
  createRuleChanThunkAction,
  updateRuleChanThunkAction,
} from "../thunk";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const EditChan: React.FC<{
  data?: Chan;
  isEdit: boolean;
  onClose: (loading: boolean) => void;
  onEdit: () => void;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, selectMessage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.data == null) {
      form.setFields([
          {
            name: "status",
            value: ChanStatus.Activated,
          },
      ]);
    } else {
      form.setFieldsValue(props.data);
    }
  }, [props, form]);

  const onFinish = async (values: any) => {
    let res: ThunkResult<boolean, any>;
    try {
      setLoading(true);

      if (props.data == null) {
        res = await dispatch<any>(createRuleChanThunkAction(values));
      } else {
        res = await dispatch<any>(
          updateRuleChanThunkAction({ id: props.data.id, input: values })
        );
      }
      setLoading(false);

      if (res.meta.requestStatus === RequestStatus.Fulfilled) {
        props.onClose(true);
      } else {
        selectMessage(res.error.message);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row gutter={[10, 10]}>
          {message !== "" && (
            <Col span={24}>
              <Alert type="error" message={message}></Alert>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input size="large" disabled={!props.isEdit} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please input description!" }]}
            >
              <Input.TextArea size="large" disabled={!props.isEdit} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"status"}
              label="Status"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please select type!" }]}
            >
              <Select size="large" disabled={!props.isEdit}>
                <Select.Option value={ChanStatus.Activated}>Activated</Select.Option>
                <Select.Option value={ChanStatus.Deactivated}>Deactivated</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row justify="center" gutter={[10, 10]}>
              {props.isEdit && (
                <Col style={{ width: 100 }}>
                  <Input size="large" type="submit" value="Save" />
                </Col>
              )}
              {(!props.isEdit && props.data != null )&& (
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onEdit();
                  }}
                >
                  Edit
                </Button>
              )}
              <Col>
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default EditChan;

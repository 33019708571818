import { WidgetComponent } from "../../../../models/widget"
import Edit from "./edit"
import View from "./view"

const wiget: WidgetComponent = {
    type: "process-liquid",
    edit: Edit,
    icon: "process-liquid-chart",
    name: "Process Liquid",
    view: View,
    options: [],
}

export default wiget
import { Spin, Table } from "antd";
import Column from "antd/lib/table/Column";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { ThunkResult, useAppDispatch } from "src/app/hooks";
import Time from "src/components/time";
import { DeviceLog, TypeDeviceLog } from "src/types";
import { GetDeviceLogLastTimeQuery } from "../graph/getDeviceLogLastTime.generated";
import { getDeviceLogLastTimeThunkAction } from "../thunk";

const LastTime: React.FC<{
  deviceId: number;
  type: TypeDeviceLog;
  showType?: boolean
  showDevice?: boolean
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<DeviceLog[]>([]);
  const { deviceId, type } = props
  const dispatch = useAppDispatch();

  useEffect(() => {
    setList([]);
    let loadData = async (deviceId: number, type: TypeDeviceLog) => {
      setLoading(true);
      const res: ThunkResult<GetDeviceLogLastTimeQuery, any> =
        await dispatch<any>(
          getDeviceLogLastTimeThunkAction({
            device_id: deviceId,
            type: type,
          })
        );
      if (
        res.payload.getDeviceLogLastTime !== null &&
        !isUndefined(res.payload.getDeviceLogLastTime.data)
      ) {
        setList(res.payload.getDeviceLogLastTime.data as DeviceLog[]);
      }
      setLoading(false);
    };
    loadData(props.deviceId, props.type);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ deviceId, type ]);

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Table dataSource={list} size="middle" rowKey={"key"} pagination={false}>
        <Column title="Key" dataIndex="key" key="key" />
        <Column
          title="Value"
          dataIndex="value"
          key="value"
          render={(value: any, row: DeviceLog) => {
            if (row.key === "status") {
              return row.value;
            }
            return row.value;
          }}
        />
        {
          props.showType && (
            <Column width={120} title="Type" dataIndex="type" key="type" />
          )
        }
        {props.showDevice && (
          <Column
            width={100}
            title="Device Name"
            dataIndex="device_name"
            key="device_name"
            render={(text: any, record: DeviceLog) => {
              if (!record.device) {
                return "--";
              }

              return record.device.name;
            }}
          />
        )}
        <Column
          width={200}
          title="Time"
          dataIndex="time"
          key="time"
          render={(text: any, record: DeviceLog) => (
            <Time second={true} data={record.time} />
          )}
        />
      </Table>
    </Spin>
  );
};

export default LastTime;

import { Handle, NodeProps, Position } from "reactflow";
import { RuleInfo, RuleOptionProps } from "../rule";
import { Alert, Button, Checkbox, CheckboxProps, Col, Form, Input, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
const { Text, Title } = Typography;

const ConditionalRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: 'red' }}
                id="input"
                title="input"
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Conditional</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="yes"
                title="yes"
                style={{ top: 15, background: 'green' }}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                title="no"
                id="no"
                style={{ bottom: 15, top: 'auto', background: 'red' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}

export default ConditionalRule;




const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const ConditionalRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const [isAssign, setIsAssign]= useState(false)
    const message = ""

    useEffect(() => {
        let conditional = ""
        let assign = ""
        let check = false
        if (props.data && props.data.conditional) {
            conditional = props.data.conditional
        }
        if (props.data && props.data.set_param_result_to && props.data.set_param_result_to !== "") {
            assign = props.data.set_param_result_to
            setIsAssign(true)
            check = true
        }
        form.setFields([
            {
              name: "type",
              value: "Conditional",
            },
            {
                name: "name",
                value: props.label,
            },
            {
                name: "conditional",
                value: conditional,
            },
            {
                name: "assign_to",
                value: assign,
            },
            {
                name: "check",
                value: check,
            },
          ])
    }, [props, form])

    const onFinish = (values: any) => {
        let option = {
            conditional: values.conditional
        } as any
        if (isAssign) {
            option["set_param_result_to"] = values.assign_to
        }
        props.save(props.id, {
            option: option,
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    const onChange: CheckboxProps['onChange'] = (e) => {
        setIsAssign(e.target.checked)
    };

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="conditional"
                    label="Conditional"
                    style={{ margin: 0 }}
                >
                <Input.TextArea size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="check" style={{ margin: 0 }} label="Assign">
                    <Checkbox checked={isAssign} onChange={onChange}>Assign result conditional</Checkbox>
                </Form.Item>
                {
                    isAssign && (
                    <Form.Item name="assign_to" label="Assign to key" style={{ margin: 0 }}>
                        <Input size="large" />
                    </Form.Item>
                    )
                }
            </Col>
            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}




export const ConditionalRuleInfo: RuleInfo = {
    id: "conditional",
    name: "Conditional",
    rule: ConditionalRule,
    option: ConditionalRuleOption,
    infinite: false,
    option_default: {
        operators: {},
    },
}

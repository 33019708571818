import { Col, Form, FormInstance, Image, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { PropertyDeviceDetail, PropertyDeviceInput } from "src/types";

const ImageProperty: React.FC<{
  view?: boolean;
  property?: PropertyDeviceDetail;
  edit?: PropertyDeviceInput;
  form?: FormInstance<any> | undefined;
}> = (props) => {
  const { edit, property } = props;
  const [image, setImage] = useState<string>("");
  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // use a regex to remove data url part
        const base64String = reader.result as string;
        resolve(base64String.replace("data:", "").replace(/^.+,/, ""));
      };
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (edit != null) {
      setImage(edit?.value as string);
    }
  }, [edit]);

  useEffect(() => {
    if (property != null) {
      setImage(property?.value as string);
    }
  }, [property]);

  if (props.view) {
    return <Image height={200} src={`data:image/png;base64,${property?.value}`} />;
  }


  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Form.Item
          name="key"
          label="Key"
          style={{ margin: 0 }}
          rules={[
            { required: true, message: "Please input key!" },
            {
              min: 2,
              message: "Length min of 2 characters",
            },
            {
              max: 30,
              message: "Length max of 30 characters",
            },
            {
              whitespace: true,
              message: "Cannot enter spaces",
            },
            {
              type: "regexp",
              pattern: new RegExp("([a-zA-Z]{3,30}\\s*)+"),
              message: "Key using not space and symbol",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="value_file"
          label="Value"
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please input value!" }]}
        >
          <Input
            type={"file"}
            accept="image/png, image/gif, image/jpeg"
            size="large"
            onChange={(e) => {
              const file = e.target.files?.item(0);
              if (file == null || file === undefined) {
                return;
              }
              toBase64(file).then((item) => {
                setImage(item as string);
                props.form?.setFields([
                  {
                    name: "value",
                    value: item,
                  },
                ]);
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={24} style={{ textAlign: "center" }}>
        <Form.Item name="value" hidden>
          <Input type={"hidden"} />
        </Form.Item>
        {image !== "" && (
          <Image height={200} src={`data:image/png;base64,${image}`} />
        )}
      </Col>
    </Row>
  );
};

export default ImageProperty;

import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, Modal, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import { Alert, ViewAlert } from "src/types";
import { selectFilter, selectListAlert } from "../slice";
import { listPaginationAlertThunkAction } from "../thunk";

const ListAlert: React.FC<{
  onAction: (action: string, item: Alert) => void;
  refLoading: (callback: () => void) => void;
}> = (props) => {
  const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);
  const [alert, setSelectAlert] = useState<Alert | null>(null);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const listAlert = useAppSelector(selectListAlert);

  props.refLoading(() => {
    dispatch<any>(listPaginationAlertThunkAction(filter));
  });

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  useEffect(() => {
    dispatch<any>(listPaginationAlertThunkAction(filter));
  }, [dispatch, filter]);

  const onMenu = (info: { key: string }, row: Alert) => {
    setSelectAlert(row);
    switch (info.key) {
      case "delete": {
        setIsModalVisibleDel(true);
        return;
      }
      default: {
        props.onAction(info.key, row);
      }
    }
  };

  const handleOkDelete = () => {
    if (alert != null) {
      props.onAction("delete", alert);
    }
    setIsModalVisibleDel(false);
  };

  return (
    <>
      <Card size="small">
        <Table
         dataSource={
          listAlert != null && listAlert?.data != null
            ? (listAlert.data as Alert[])
            : []
        }
          onChange={onChange}
          size="middle"
          rowKey={"id"}
        >
          <Column
            title=""
            key="action_base"
            width={60}
            render={(text: any, row: Alert) => (
              <>
                <Button
                  type="link"
                  onClick={() => {
                    props.onAction("alert", row);
                  }}
                  title="Alert"
                >
                  <ContainerOutlined />
                </Button>
              </>
            )}
          />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            render={(text: any, row: Alert) => (
              <Button
                type="link"
                onClick={() => {
                  props.onAction("detail", row);
                }}
              >
                {text}
              </Button>
            )}
          />
          <Column
            title="Model"
            dataIndex="model"
            key="model"
            render={(item: any, row: ViewAlert) => {
              return (
                <>
                  {row.model?.name}
                </>
              )
            }}
          />
          <Column
            width={200}
            title="Enable"
            dataIndex="enable"
            key="enable"
            render={(item: any, row: ViewAlert) => {
              return (
                <>
                  {row.enable? "True" : "Fail"}
                </>
              )
            }}
          />
          <Column
            width={300}
            title="Created by"
            key="created_by"
            render={(text: any, record: ViewAlert) =>
              `${record.created_by_user?.name} <${record.created_by_user?.email}>`
            }
          />
          <Column
            width={200}
            title="Created At"
            dataIndex="created_at"
            key="created_at"
            render={(text: any, record: Alert) => (
              <Time data={record.created_at} />
            )}
          />
          <Column
            width={200}
            title="Updated At"
            dataIndex="updated_at"
            key="updated_at"
            render={(text: any, record: Alert) => (
              <Time data={record.updated_at} />
            )}
          />
          <Column
            title=""
            key="action"
            width={10}
            render={(text: any, record: any) => (
              <Dropdown
                overlay={
                  <Menu
                    onClick={(info) => {
                      onMenu(info, record);
                    }}
                  >
                    <Menu.Item key="alert" icon={<ContainerOutlined />}>
                      List Alert
                    </Menu.Item>
                    <Menu.Item key="detail" icon={<BarsOutlined />}>
                      Detail
                    </Menu.Item>
                    <Menu.Item key="edit" icon={<EditOutlined />}>
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>
            )}
          />
        </Table>
      </Card>
      <Modal
        title="Delete Alert"
        visible={isModalVisibleDel}
        onOk={handleOkDelete}
        onCancel={() => {
          setIsModalVisibleDel(false);
        }}
      >
        <p>
          You do want to delete alert{" "}
          <Tag color={"blue"}>
            {alert?.name} - {alert?.id}
          </Tag>{" "}
          !
        </p>
      </Modal>
    </>
  );
};

export default ListAlert;

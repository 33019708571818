import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUiByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetUiByIdQuery = { __typename?: 'Query', getUiById: { __typename?: 'UiDetail', id: any, name: string, type: string, is_home: boolean, labels: any, widgets: any, created_at: any, updated_at: any, layouts?: Array<{ __typename?: 'LayoutUi', x: number, y: number, w: number, i: string, h: number } | null> | null, widget_list?: Array<{ __typename?: 'WidgetDetail', id: any, name: string, type: string, option: any, created_at: any, data: { __typename?: 'WidgetData', source: string, type: string, model_id: any, namespace_id: any, device_id: any, time: string, window?: string | null, fields?: Array<string> | null } }> | null } };


export const GetUiByIdDocument = gql`
    query getUiById($id: Uint64!) {
  getUiById(id: $id) {
    id
    name
    type
    is_home
    labels
    widgets
    layouts {
      x
      y
      w
      i
      h
    }
    widget_list {
      id
      name
      type
      data {
        source
        type
        model_id
        namespace_id
        device_id
        time
        window
        fields
      }
      option
      created_at
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetUiByIdQuery__
 *
 * To run a query within a React component, call `useGetUiByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUiByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUiByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUiByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUiByIdQuery, GetUiByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUiByIdQuery, GetUiByIdQueryVariables>(GetUiByIdDocument, options);
      }
export function useGetUiByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUiByIdQuery, GetUiByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUiByIdQuery, GetUiByIdQueryVariables>(GetUiByIdDocument, options);
        }
export type GetUiByIdQueryHookResult = ReturnType<typeof useGetUiByIdQuery>;
export type GetUiByIdLazyQueryHookResult = ReturnType<typeof useGetUiByIdLazyQuery>;
export type GetUiByIdQueryResult = Apollo.QueryResult<GetUiByIdQuery, GetUiByIdQueryVariables>;
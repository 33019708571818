import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import SelectWidget from "src/modules/widget/components/select-widget";

const EditWidget: React.FC<{
  new: boolean;
  widget: {
    name: string;
    widgetId: string;
  };
  visible: boolean;
  onSave: (data: { name: string; widgetId: string }) => void;
  onCancel: () => void;
}> = (props) => {
  const [form] = Form.useForm();
  const { name, widgetId } = props.widget;

  const onSave = async () => {
    const value = await form.validateFields();
    props.onSave({
      name: value.name,
      widgetId: value.widgetId,
    });
  };

  useEffect(() => {
    form.setFields([
      {
        name: "name",
        value: name,
      },
      {
        name: "widgetId",
        value: widgetId,
      },
    ]);
  }, [name, widgetId, form]);

  return (
    <Modal
      title={props.new ? "Add Widget" : "Edit Widget"}
      visible={props.visible}
      closable={false}
      footer={null}
      width={400}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Requried input name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <SelectWidget
              name="widgetId"
              label="Widget"
              disabled={false}
              rules={[{ required: true, message: "Requried select widget!" }]}
              value={props.widget.widgetId}
            />
          </Col>
          <Col span={24}>
            <Row
              gutter={[10, 10]}
              justify="center"
              style={{ marginTop: "10px" }}
            >
              <Col>
                <Button onClick={onSave}>Save</Button>
              </Col>
              <Col>
                <Button onClick={props.onCancel}>Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditWidget;

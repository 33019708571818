import { Form, Spin, Row, Col, Alert, Typography,  } from "antd";
import { useEffect } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleOptionProps, RuleInfo } from "../rule";
import { LazyLog } from "@melloware/react-logviewer";

const { Title } = Typography;

const LogRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                title="input"
                position={Position.Left}
                id="input"
                style={{ background: 'red' }}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Log</Title>
            </div>
        </div>
    )
}
export default LogRule;


export const LogViewer : React.FC<{ nodeId: String }> = (props) => {

    return (
        <LazyLog
        caseInsensitive
        enableHotKeys
        enableSearch
        extraLines={1}
        height={"100%"}
        onLineContentClick={function noRefCheck(){}}
        selectableLines
        text="Click me 100!
Click me 200!
Click me 300!
"
        />
    )
}



const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const LogRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        form.setFields([
            {
                name: "name",
                value: props.label,
            },
          ])
    }, [props, form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {},
            name: values.name,
        })
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <LogViewer nodeId={props.id} />
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}



export const LogRuleInfo: RuleInfo = {
    id: "log",
    name: "Log",
    rule: LogRule,
    option: LogRuleOption,
    infinite: false,
    option_default: { },
    // tabs: [{
    //     tab_name: "Log",
    //     tab: LogTab
    // }]
}

import { Breadcrumb, Button, Card, Col, Drawer, PageHeader, Row } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Alert } from "src/types";
import { deleteAlertThunkAction } from "src/modules/alert/thunk";
import { useAppDispatch } from "src/app/hooks";
import FilterAlert from "src/modules/alert/components/filter";
import ListAlert from "src/modules/alert/components/list";
import EditAlert from "src/modules/alert/components/edit/edit";
import ListAlertLog from "src/modules/alert/components/list-alert-log";

const ListStoragePage: React.FC<{}> = (props) => {
  const [alert, setSelectAlert] = useState<Alert | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isModalLog, setIsModalLog] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [add, setAdd] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onAction = async (action: string, row: Alert) => {
    setSelectAlert(row);
    switch (action) {
      case "detail": {
        setIsEdit(false);
        setIsModal(true);
        break;
      }
      case "edit": {
        setAdd(false);
        setIsEdit(true);
        setIsModal(true);
        break;
      }
      case "alert": {
        setIsModalLog(true);
        break;
      }
      case "delete": {
        await dispatch<any>(deleteAlertThunkAction(row.id));
        callbackLoadData();
        console.log("del namespace", alert);
        break;
      }
    }
  };

  const onAdd = () => {
    setAdd(true);
    setIsEdit(true);
    setSelectAlert(null);
    setIsModal(true);
  };

  let callbackLoadData = () => {};

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - List Alert</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Alert</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Card size={"small"}>
            <PageHeader
              className="page-header-responsive"
              title="Alerts"
              extra={
                <>
                  <FilterAlert />
                  <Button type="primary" onClick={onAdd}>
                    Add Alert
                  </Button>
                </>
              }
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>
          <ListAlert
            onAction={onAction}
            refLoading={(callback) => {
              callbackLoadData = callback;
            }}
          />
        </Col>
      </Row>

      <Drawer
        title={!isEdit ? "Detail Alert" : add ? "Add Alert" : "Edit Alert"}
        placement="right"
        width={600}
        closable={false}
        visible={isModal}
        onClose={() => {
          if (!isEdit) {
            setIsModal(false);
          }
        }}
      >
        {isModal && (
          <EditAlert
            data={alert}
            isEdit={isEdit}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                callbackLoadData();
              }
            }}
            onEdit={() => {
              setIsEdit(true);
            }}
          ></EditAlert>
        )}
      </Drawer>

      <Drawer
        title={"List Alert Log"}
        placement="right"
        width={800}
        closable={true}
        visible={isModalLog}
        onClose={() => {
          setIsModalLog(false);
        }}
      >
        {isModalLog && <ListAlertLog showDevice alertId={alert?.id}  filterOption={{ filterDevice: true }} />}
      </Drawer>
    </div>
  );
};

export default ListStoragePage;

import { Routes, Route } from 'react-router-dom'
import React from "react";
import MainLayout from 'src/components/layout/main-layout';
import ListConnectorPage from './list-connector.page';
import { Spin } from 'antd';
import { useAppSelector } from 'src/app/hooks';
import { selectLoading } from 'src/modules/device/slice';

type P = {

}

export function ConnectorRouter(props: P) {
    const loading = useAppSelector(selectLoading)
    return (
        <MainLayout>
            <Spin tip="Loading..." spinning={loading}>
                <Routes>
                    <Route key={"connector-list"} path={'/'} element={<ListConnectorPage />} />
                </Routes>
            </Spin>
        </MainLayout>
    )
}

export default ConnectorRouter
import { Form, Spin, Row, Col, Alert, Input, Button, Typography } from "antd";
import { useEffect } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleOptionProps, RuleInfo } from "../rule";
import SelectDevice from "src/modules/device/components/select-device";

// eslint-disable-next-line
const { Title, Text } = Typography;

const OutputActionRule: React.FC<NodeProps> = (props) => {
    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                position={Position.Left}
                id="input"
                title="input"
                style={{ background: 'red' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}> Output Action</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
        </div>
    )
}

export default OutputActionRule;



const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const OutputActionRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        let action = ""
        let device_id = ""

        if (props.data && props.data.device_id) {
            device_id = props.data.device_id
        }

        if (props.data && props.data.action) {
            action = props.data.action
        }

        form.setFields([
            {
              name: "type",
              value: "Input Model",
            },
            {
                name: "name",
                value: props.label,
            },
            {
                name: "device_id",
                value: device_id,
            },
            {
                name: "action",
                value: action,
            },
          ])
    }, [props,form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {
                device_id: values.device_id,
                action : values.action
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="device_id"
                    label="Device"
                    style={{ margin: 0 }}
                >
                  <SelectDevice name={"device_id"} disabled={false} />
                </Form.Item>
              
            </Col>
            <Col span={24}>
                <Form.Item
                    name="action"
                    label="Action"
                    style={{ margin: 0 }}
                >
                <Input.TextArea size="large" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const OutputActionRuleInfo: RuleInfo = {
    id: "output-action",
    name: "Output Action",
    rule: OutputActionRule,
    option: OutputActionRuleOption,
    infinite: false,
    option_default: {
        
    },
}

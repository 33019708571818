import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { getDeviceByIdThunkAction, getSearchDeviceThunkAction, listPaginationDeviceThunkAction } from './thunk'
import { ListPaginationDeviceInput, ListPaginationDevice, DeviceDetail } from 'src/types'

export const DeviceSlice = createSlice({
    name: 'device',
    initialState: {
        loading: false,
        message: "",
        listDevice: null as ListPaginationDevice | null,
        listGatewayDevice: null as ListPaginationDevice | null,
        detail: null as DeviceDetail |  null,
        filter: {
            limit: 50,
            skip: 0,
        } as ListPaginationDeviceInput,
        search: {
            data: [] as any[]
        } as ListPaginationDevice,
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationDeviceInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(listPaginationDeviceThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(listPaginationDeviceThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(listPaginationDeviceThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.listDevice = action.payload.getListPaginationDevice as ListPaginationDevice
        })
        builder.addCase(getDeviceByIdThunkAction.fulfilled, (state, action) => {
            state.message = ""
            state.detail = action.payload.getDeviceById as DeviceDetail
        })
        builder.addCase(getDeviceByIdThunkAction.pending, (state, action) => {
            state.message = ""
            state.detail = null
        })
        builder.addCase(getSearchDeviceThunkAction.fulfilled, (state, action) => {
            state.message = ""
            state.search = action.payload.getListPaginationDevice as ListPaginationDevice
        })
    }
})

export default DeviceSlice.reducer
export const { setFilter } = DeviceSlice.actions


// select
export const selectPolicy = (state: RootState) => state.device
export const selectLoading = (state: RootState) => state.device.loading
export const selectMessage = (state: RootState) => state.device.message
export const selectFilter = (state: RootState) => state.device.filter
export const selectListDivice = (state: RootState) => state.device.listDevice
export const selectDetail = (state: RootState) => state.device.detail
export const selectListDeviceSearch = (state: RootState) => state.device.search.data || []
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssignModelForDeviceMutationVariables = Types.Exact<{
  input: Types.AssignModelInput;
}>;


export type CreateAssignModelForDeviceMutation = { __typename?: 'Mutation', createAssignModelForDevice: boolean };


export const CreateAssignModelForDeviceDocument = gql`
    mutation createAssignModelForDevice($input: AssignModelInput!) {
  createAssignModelForDevice(input: $input)
}
    `;
export type CreateAssignModelForDeviceMutationFn = Apollo.MutationFunction<CreateAssignModelForDeviceMutation, CreateAssignModelForDeviceMutationVariables>;

/**
 * __useCreateAssignModelForDeviceMutation__
 *
 * To run a mutation, you first call `useCreateAssignModelForDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignModelForDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignModelForDeviceMutation, { data, loading, error }] = useCreateAssignModelForDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignModelForDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignModelForDeviceMutation, CreateAssignModelForDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignModelForDeviceMutation, CreateAssignModelForDeviceMutationVariables>(CreateAssignModelForDeviceDocument, options);
      }
export type CreateAssignModelForDeviceMutationHookResult = ReturnType<typeof useCreateAssignModelForDeviceMutation>;
export type CreateAssignModelForDeviceMutationResult = Apollo.MutationResult<CreateAssignModelForDeviceMutation>;
export type CreateAssignModelForDeviceMutationOptions = Apollo.BaseMutationOptions<CreateAssignModelForDeviceMutation, CreateAssignModelForDeviceMutationVariables>;
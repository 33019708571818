import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceModelAssignDeviceIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetDeviceModelAssignDeviceIdQuery = { __typename?: 'Query', getDeviceModelByDeviceId?: Array<{ __typename?: 'DeviceModelDetail', device_id: any, assign_id: any, model_id: any, model?: { __typename?: 'ModelDetail', name: string } | null, device?: { __typename?: 'Device', name: string } | null }> | null };


export const GetDeviceModelAssignDeviceIdDocument = gql`
    query getDeviceModelAssignDeviceId($id: Uint64!) {
  getDeviceModelByDeviceId(id: $id) {
    device_id
    assign_id
    model_id
    model {
      name
    }
    device {
      name
    }
  }
}
    `;

/**
 * __useGetDeviceModelAssignDeviceIdQuery__
 *
 * To run a query within a React component, call `useGetDeviceModelAssignDeviceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceModelAssignDeviceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceModelAssignDeviceIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceModelAssignDeviceIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceModelAssignDeviceIdQuery, GetDeviceModelAssignDeviceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceModelAssignDeviceIdQuery, GetDeviceModelAssignDeviceIdQueryVariables>(GetDeviceModelAssignDeviceIdDocument, options);
      }
export function useGetDeviceModelAssignDeviceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceModelAssignDeviceIdQuery, GetDeviceModelAssignDeviceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceModelAssignDeviceIdQuery, GetDeviceModelAssignDeviceIdQueryVariables>(GetDeviceModelAssignDeviceIdDocument, options);
        }
export type GetDeviceModelAssignDeviceIdQueryHookResult = ReturnType<typeof useGetDeviceModelAssignDeviceIdQuery>;
export type GetDeviceModelAssignDeviceIdLazyQueryHookResult = ReturnType<typeof useGetDeviceModelAssignDeviceIdLazyQuery>;
export type GetDeviceModelAssignDeviceIdQueryResult = Apollo.QueryResult<GetDeviceModelAssignDeviceIdQuery, GetDeviceModelAssignDeviceIdQueryVariables>;
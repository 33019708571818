import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDetail, selectListDeviceSearch, selectLoading } from "../slice";
import { getSearchDeviceThunkAction } from "../thunk";

const SelectDevice2: React.FC<{
  disabled: boolean;
  name: string
  rules?: Rule[] | undefined
  value?: any
}> = (props) => {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const listModel = useAppSelector(selectListDeviceSearch);
  const detail = useAppSelector(selectDetail);
  const { value } = props

  const [text, setText] = useState("");

  useEffect(() => {
    dispatch<any>(
      getSearchDeviceThunkAction({
        limit: 100,
        skip: 0,
        search: text,
      })
    );
  }, [text, dispatch]);

  useEffect(() => {
    if (value) {
      dispatch<any>(getSearchDeviceThunkAction(value));
    }
  }, [value, dispatch]);

  return (
    <Form.Item
      name={props.name}
      label="Device"
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Select
        onSearch={(value: string) => {
          setText(value);
        }}
        size="large"
        disabled={props.disabled}
        loading={loading}
        showSearch
      >
        {listModel?.filter(item => item.id !== detail?.id).map((item) => {
          return <Select.Option  key={item.id} value={item.id}>{item.name}</Select.Option>;
        })}
        {
          detail && (
            <Select.Option value={detail.id}>{detail.name}</Select.Option>
          )
        }
      </Select>
    </Form.Item>
  );
};

export default SelectDevice2;

import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectListDeviceSearch, selectLoading } from "../slice";
import { getSearchDeviceThunkAction } from "../thunk";

const SelectDevice: React.FC<{
  disabled: boolean;
  name: string;
  namepsace_id?: string;
  rules?: Rule[] | undefined;
  onChange?: (value: string) => void;
  value?: string;
  label?: string;
  placeholder?: string;
}> = (props) => {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const listDevice = useAppSelector(selectListDeviceSearch);
  const { namepsace_id } = props;
  const [text, setText] = useState("");

  useEffect(() => {
    let namespaces: string[] | undefined = undefined;
    if (namepsace_id) {
      namespaces = [namepsace_id];
    }
    dispatch<any>(
      getSearchDeviceThunkAction({
        limit: 100,
        skip: 0,
        search: text,
        namespaces,
      })
    );
  }, [text, namepsace_id, dispatch]);

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Select
        placeholder={props.placeholder}
        onSearch={(value: string) => {
          setText(value);
        }}
        size="large"
        disabled={props.disabled}
        loading={loading}
        showSearch
        onChange={props.onChange}
        value={props.value}
      >
        {listDevice?.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectDevice;

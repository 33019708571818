import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import Column from "antd/lib/table/Column";

const { Title } = Typography;

interface DataRule {
    index: number;
    rule: string;
}

const EditListRule: React.FC<{
    rules: string[];
    isEdit: boolean;
    onChange: (rules: string[]) => void;
}> =  (props) => {

    const [list,setList] = useState<DataRule[]>([])

    useEffect(() => {
        if (props.rules) {
            let newList : DataRule[] = props.rules.map((item, index) => {
                return ({
                    index: index,
                    rule: item
                })
            })
            setList(newList);
        }
    }, [props.rules])


    const updateRule = (index: number, rule: string) => {
        const newList = list.map((item) => {
            if (item.index === index) {
                item.rule = rule;
            }
            return item;
        })
        setList(newList);
        props.onChange(newList.map(item => {
            return item.rule
        }))
    }

    const remoteRule = (index: number) => {
        const newList: DataRule[] =  list.filter(item => item.index = index)
        setList(newList);
        props.onChange(newList.map(item => {
            return item.rule
        }))
    }

    return (
        <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
        <Col span={24}>
          <Title style={{ textAlign: "center" }} level={5}>
            List Rule
          </Title>
        </Col>
        <Col span={24}>
          <Table dataSource={list} size="middle" rowKey={"id"} pagination={false}>
          <Column width={50} title="No" dataIndex="index" key="index"
              render={(text: any, record: DataRule, index: number) => (
                <>
                    { index + 1 }
                </>
              )}
            
            />
            <Column title="Rule" dataIndex="rule" key="rule"
              render={(text: any, record: DataRule) => (
                <>
                    <Input disabled={!props.isEdit } value={record.rule} onChange={(event) => {
                        updateRule(record.index, event.target.value)
                    }} />
                </>
              )}
            
            />
            {props.isEdit && (
              <Column
                title=""
                key="action"
                width={50}
                render={(text: any, record: DataRule) => (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            remoteRule(record.index)
                        }}
                    />
                )}
              />
            )}
          </Table>
        </Col>
        {props.isEdit && (
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                var date = new Date();
                setList([
                    ...list,
                    { index: date.getTime(), rule: "" }
                ])
              }}
            >
              Add Rule
            </Button>
          </Col>
        )}
      </Row>
    )
}

export default EditListRule;
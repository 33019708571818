import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDetail } from "src/modules/model/slice";
import { getModelByIdThunkAction } from "src/modules/model/thunk";
import { selectListDeviceModelAssignDevice } from "../slice";
import { getDeviceModelAssignDeviceIdThunkAction } from "../thunk";

const SelectModelAssign: React.FC<{
  deviceId: string
  disabled: boolean;
  label?: string
  name: string
  rules?: Rule[] | undefined
  onChange?: (model: string) =>  void
  value?: string
}> = (props) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch();
  const listModel = useAppSelector(selectListDeviceModelAssignDevice);
  const detail = useAppSelector(selectDetail);
  const { deviceId, value } = props

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const loadData = async () => {
    setLoading(true)
    await  dispatch<any>(
      getDeviceModelAssignDeviceIdThunkAction(props.deviceId)
    );
    setLoading(false)
  }

  useEffect(() => {
    if (value) {
      dispatch<any>(getModelByIdThunkAction(value as any));
    }
  }, [value, dispatch]);

  return (
    <Form.Item
      name={props.name}
      label={ props.label ?  props.label :"Model Assign"}
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Select
        size="large"
        disabled={props.disabled}
        loading={loading}
        showSearch
        onChange={props.onChange}
        value={props.value}
      >
        {listModel?.filter(item => item.model_id !== detail?.id).map((item) => {
          return <Select.Option  key={item.model_id} value={item.model_id}>{item.model?.name}</Select.Option>;
        })}
        {
          detail && (
            <Select.Option value={detail.id}>{detail.name}</Select.Option>
          )
        }
      </Select>
    </Form.Item>
  );
};

export default SelectModelAssign;

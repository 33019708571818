import { createAsyncThunk } from "@reduxjs/toolkit"
import { GraphQLClient } from "src/app/apollo"
import { DeviceInput, ListPaginationDeviceInput } from "src/types"
import { CreateDeviceDocument, CreateDeviceMutation, CreateDeviceMutationVariables } from "./graph/createDevice.generated"
import { DeleteDeviceDocument, DeleteDeviceMutation, DeleteDeviceMutationVariables } from "./graph/deleteDevice.generated"
import { GetDeviceByIdDocument, GetDeviceByIdQuery, GetDeviceByIdQueryVariables } from "./graph/getDeviceById.generated"
import { GetListPaginationDeviceDocument, GetListPaginationDeviceQuery, GetListPaginationDeviceQueryVariables } from './graph/getListPaginationDevice.generated'
import { UpdateDeviceDocument, UpdateDeviceMutation, UpdateDeviceMutationVariables } from "./graph/updateDevice.generated"

export const listPaginationDeviceThunkAction = createAsyncThunk<
    GetListPaginationDeviceQuery,
    ListPaginationDeviceInput,
    { extra: { client: GraphQLClient } }
>(
    'device/listPaginationDeviceThunk',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationDeviceQueryVariables, GetListPaginationDeviceQuery>(GetListPaginationDeviceDocument, { input: data })
        return res
    }
)

export const getSearchDeviceThunkAction = createAsyncThunk<
    GetListPaginationDeviceQuery,
    ListPaginationDeviceInput,
    { extra: { client: GraphQLClient } }
>(
    'device/getSearchDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationDeviceQueryVariables, GetListPaginationDeviceQuery>(GetListPaginationDeviceDocument, { input: data })
        return res
    }
)

export const listGatewayDeviceThunkAction = createAsyncThunk<
    GetListPaginationDeviceQuery,
    ListPaginationDeviceInput,
    { extra: { client: GraphQLClient } }
>(
    'device/listGatewayDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationDeviceQueryVariables, GetListPaginationDeviceQuery>(GetListPaginationDeviceDocument, { input: {
            ...data,
            is_gateway: true,
        } })
        return res
    }
)

export const getDeviceByIdThunkAction = createAsyncThunk<
    GetDeviceByIdQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'device/getDeviceByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetDeviceByIdQueryVariables, GetDeviceByIdQuery>(GetDeviceByIdDocument, { id: id })
        return res;
    }
)


export const createDeviceThunkAction = createAsyncThunk<
    CreateDeviceMutation,
    DeviceInput,
    { extra: { client: GraphQLClient } }
>(
    'device/createDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateDeviceMutationVariables, CreateDeviceMutation>(CreateDeviceDocument, { input: data })
        return res;
    }
)

export const updateDeviceThunkAction = createAsyncThunk<
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'device/updateDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateDeviceMutationVariables, UpdateDeviceMutation>(UpdateDeviceDocument, data)
        return res;
    }
)

export const deleteDeviceThunkAction = createAsyncThunk<
    DeleteDeviceMutation,
    number,
    { extra: { client: GraphQLClient } }
>(
    'device/deleteDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<DeleteDeviceMutationVariables, DeleteDeviceMutation>(DeleteDeviceDocument, { id: data })
        return res;
    }
)


import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from "antd";
import {
  DashboardOutlined,
  MobileOutlined,
  DatabaseOutlined,
  UnorderedListOutlined,
  SisternodeOutlined,
  AlertOutlined,
  BarChartOutlined,
  ApiOutlined,
  // LinkOutlined
} from '@ant-design/icons';

type P = {
};

interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

const MenuApp: React.FC<P> = (props: P) => {

  const navigate = useNavigate()
  const [keys, setKeys] = useState<string[]>([])
  let location = useLocation();

  React.useEffect(() => {
    const path = location.pathname.slice(1);
    const pathKey = path.replace("/", "-")
    let listPath = [pathKey]
    const listData = pathKey.split("-")
    listPath.push(listData[0])
    for (let index = 0; index < listData.length; index++) {
      listPath.push(listData.slice(index).join("-"))
    }
    setKeys(listPath)
  }, [location]);

  const handleClick = (info: MenuInfo) => {
    navigate(`/${info.key.replace("-", "/")}`)
    setKeys(info.keyPath)
  }


  return (
    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" onClick={handleClick} selectedKeys={keys}>
      <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
        Dashboard
      </Menu.Item>
      <Menu.Item key="ui" icon={<BarChartOutlined />}>
        Ui
      </Menu.Item>
      <Menu.Item key="namespace" icon={<DatabaseOutlined />}>
        Namespace
      </Menu.Item>
      <Menu.Item key="device" icon={<MobileOutlined />}>
        Device
      </Menu.Item>
      <Menu.Item key="model" icon={<UnorderedListOutlined />}>
        Model
      </Menu.Item>
      <Menu.Item key="rule_chan" icon={<SisternodeOutlined />}>
        Rule Chan
      </Menu.Item>
      <Menu.Item key="connector" icon={<ApiOutlined />}>
        Connector
      </Menu.Item>
      {/* <Menu.Item key="storage" icon={<LinkOutlined />}>
        Storage
      </Menu.Item> */}
      <Menu.Item key="alert" icon={<AlertOutlined />}>
        Alert
      </Menu.Item>
      {/* <SubMenu key="policy" icon={<ApiOutlined />} title="Policy">
        <Menu.Item key="policy-myPolicy">My Policy</Menu.Item>
        <Menu.Item key="policy-assign">Assign Policy</Menu.Item>
      </SubMenu> */}
    </Menu>
  );
};

export default MenuApp;

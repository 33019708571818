import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { loginThunkAction, logoutThunkAction, verifyThunkAction } from './login.thunk'
import { setToken } from 'src/app/apollo'
import { setTokenHttp } from 'src/app/http'
import { makeid } from 'src/utils/id'
import { UserLogin } from './models/user_login'

export const LoginSlice = createSlice({
    name: 'login',
    initialState: {
        loading: false,
        message: "",
        redirect: "",
        login: false,
        token: "",
        deviceId: "",
        user: {
            email: "",
            id: "",
            name: "",
            permissions: []
        } as UserLogin,
    },
    reducers: {
        setRedirectTo: (state, playload: PayloadAction<string>) => {
            state.redirect = playload.payload
        },
        setLogin: (state, playload: PayloadAction<boolean>) => {
            state.login = playload.payload
        },
        loadDeviecId: (state, playload: PayloadAction<string| null>) => {
            var deviceId = localStorage.getItem("device_id")
            if (playload.payload != null) {
                deviceId = playload.payload
            }
            if (deviceId == null) {
                deviceId = makeid(10)
            }
            state.deviceId = deviceId
        },
        setLoginWithToken: (state, playload: PayloadAction<string>) => {
            console.log("setLoginWithToken", playload)
            const token = playload.payload
            if (token) {
                state.token = token as any
                setToken(token, "")
                setTokenHttp(token)
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(loginThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
            state.login = false
        })
        builder.addCase(loginThunkAction.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.user = action.payload.user.name  as any
                state.token = action.payload.token  as any
                localStorage.setItem("device_id", state.deviceId)
                setToken(state.token, state.deviceId)
                state.login = true
                return
            } else{
                state.message = action.payload.msg ?? ""
            }
            state.loading = false
            state.login = false
        })
        builder.addCase(loginThunkAction.rejected, (state, action) => {
            state.loading = false
            if (action.error.message !== undefined) {
                state.message = action.error.message
            }
        })
        builder.addCase(logoutThunkAction.fulfilled, (state, action) => {
            if (action.payload.logout) {
                state.login = false
                state.token = ""
                setToken("", state.deviceId)
                localStorage.clear()
                return
            }
        })
        builder.addCase(verifyThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
       })
        builder.addCase(verifyThunkAction.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.user = action.payload.user as any
                state.login = true
                return
            }
            state.loading = false
            state.login = false
        })
        builder.addCase(verifyThunkAction.rejected, (state, action) => {
            state.loading = false
            state.login = false
            setToken("", "")
        })
    }
})

export default LoginSlice.reducer
export const { setRedirectTo, setLogin, loadDeviecId, setLoginWithToken } = LoginSlice.actions


// select
export const selectDeviceId = (state: RootState) => state.login.deviceId
export const selectLoading = (state: RootState) => state.login.loading
export const selectMessage = (state: RootState) => state.login.message
export const selectRedirect = (state: RootState) => state.login.redirect
export const selectLogin = (state: RootState) => state.login.login
export const selectUserDisplayName = (state: RootState) => state.login.user?.name
import { Form, Spin, Row, Col, Alert, Input, Button, Typography, Table, Space } from "antd";
import { useEffect, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleOptionProps, RuleInfo } from "../rule";
import Title from "antd/lib/typography/Title";
import { DeleteOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import { useAppSelector } from "src/app/hooks";
import Column from "antd/lib/table/Column";
import { selectOptionNodes } from "../../slice";

const { Text } = Typography;

const SwitchRule: React.FC<NodeProps> = (props) => {
    

    const {  isConnectable } = props
    const optionNodes = useAppSelector(selectOptionNodes)

    const [list, setList] = useState<string[]>([]);


    useEffect(() => {
        if (optionNodes[props.id]) {
            let option = optionNodes[props.id]
            if (option.list_switch) {
                setList(option.list_switch)
            }
        }

    }, [props, optionNodes])

    const getMinHeight = () => {
        if (list.length < 7) {
            return 80
        }

        return (list.length - 7 )* 10 + 80
    }

    return (
        <div className="react-flow__node-output node__input_telemetry" style={{ minHeight: getMinHeight()}}>
            <Handle
                type="target"
                position={Position.Left}
                title="input"
                id="input"
                style={{ background: 'red' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Switch</Title>
                <Text italic>{ props.data.label } </Text>
            </div>

            {
                list.map((item,index)=> {
                    return (
                        <Handle
                        key={item}
                        type="source"
                        title={item}
                        position={Position.Right}
                        id={item}
                        style={{ top: 10 + 10 * index, background: 'green' }}
                        onConnect={(params) => console.log('handle onConnect', params)}
                        isConnectable={isConnectable}
                    />
                    )
                })
            }
        </div>
    )
}
export default SwitchRule;


interface ItemList {
    case: string
}

const EditItemSwitch: React.FC<{
    list: string[];
    onChange: (list: string[]) => void
}> = (props) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [list, setList] = useState<ItemList[]>([]);
  const [input, setInput] = useState("");

  const onAdd  = () => {
    setIsModal(true);
  }

  const onRemove  = (target: string) => {
    const newList = list.filter((item) => {
        return item.case !== target
    })
    setList(newList);
    props.onChange(newList.map(item =>  item.case))
  }

  const onSaveItem =  (data: string) => {    
    let newData = data.trim()
    let found = false;
    const newList = list.map((item) => {
        if (item.case === newData) {
            found = true;
        }
        return item
    })
    if (!found) {
        newList.push({
            case: newData
        })
    }
    setList(newList);
    props.onChange(newList.map(item =>  item.case))
    setIsModal(false);
  }

  useEffect(() => {
    if (props.list && Array.isArray(props.list)) {
        let newList = [] as any
        for(let item of  props.list) {
            newList.push({
                case: item
            })
        }
        setList(newList)
    }
  }, [props.list])

  const onChangeText: React.ChangeEventHandler<HTMLInputElement>  = (e) => {
    setInput(e.target.value)
  }

  return (
    <Row gutter={[10, 10]} style={{ marginTop: "50px" }}>
        <Col span={16}>
          <Title level={5}>
            Switch Case
          </Title>
        </Col>
        <Col span={8} style={{ textAlign: "right" }} >
            <Button
            type="primary"
            style={{ width: 100 }}
            onClick={onAdd}
            >
                Add Case
            </Button>
        </Col>
        { 
            isModal && (
                <Col span={24}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Input onChange={onChangeText}/>
                        <Button type="default"  icon={<SaveOutlined />} 
                         onClick={(e) => {
                            onSaveItem(input)
                            setIsModal(false)
                        }}
                        
                        ></Button>
                        <Button type="default" icon={<StopOutlined />}
                            onClick={(e) => {
                                setIsModal(false)
                            }}
                        />
                    </Space.Compact>
                </Col>
            )
        }
       
        <Col span={24}>
          <Table
            dataSource={list}
            size="middle"
            rowKey={"id"}
            pagination={false}
          >
            <Column
              title="Case"
              dataIndex="case"
              key="case"
              render={(v, row: ItemList) => {
                return <>{row.case}</>;
              }}
            />
            <Column
              title=""
              key="action"
              width={100}
              render={(text: any, record: ItemList) => (
                <>
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            onRemove(record.case);
                        }}
                    />
                </>
              )}
            />
          </Table>
        </Col>
        
    </Row>
  );
};


const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const SwitchRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""
    const [list, setList] = useState<string[]>([]);

    useEffect(() => {

        let key = ""
        if (props.data && props.data.list_switch) {
            setList(props.data.list_switch)
        }
        if (props.data && props.data.key) {
            key = props.data.key
        }
        form.setFields([
            {
              name: "type",
              value: "Switch",
            },
            {
                name: "name",
                value: props.label,
            },
            {
                name: "key",
                value: key,
            },
          ])
    }, [props, form])

    const onFinish = (values: any) => {
        let option = {
            key: values.key,
            list_switch: list
        } as any
        props.save(props.id, {
            option: option,
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    const onChangeList = (updateList: string[]) => {
        setList(updateList)
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="key" label="Switch Key" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <EditItemSwitch list={list} onChange={onChangeList} />
            </Col>
            
            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const SwitchRuleInfo: RuleInfo = {
    id: "switch",
    name: "Switch",
    rule: SwitchRule,
    option: SwitchRuleOption,
    infinite: false,
    option_default: {
        operators: {},
    },
}

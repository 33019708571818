import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import React, { useEffect } from "react";
import MainLayout from 'src/components/layout/main-layout';
import HomeUiPage from './home-ui.page';

type P = {

}

export function HomeRouter(props: P) {

    const navigate = useNavigate();
    let location = useLocation();
    useEffect(() => {
        if (location.pathname === "/" || location.pathname === "") {
            navigate("/dashboard")
        }
    })

    return (
        <MainLayout>
            <Routes>
                <Route path={'/dashboard'} element={<HomeUiPage />} />
            </Routes>
        </MainLayout>
    )
}

export default HomeRouter
import { Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import { DefaultOptionType } from "antd/lib/select";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectListPropertyDevice } from "../slice";
import { getPropertyDeviceByIdThunkAction } from "../thunk";

const SelectProperty: React.FC<{
  deviceId: string;
  name: string;
  rules?: Rule[] | undefined;
  disabled?: boolean;
  label?: string
  onChange?: (field: string, option: DefaultOptionType | DefaultOptionType[]) => void
}> = (props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const list = useAppSelector(selectListPropertyDevice);
  const { deviceId } = props

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await dispatch<any>(
        getPropertyDeviceByIdThunkAction(props.deviceId)
      );
      setLoading(false);
    };
    loadData();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deviceId ]);

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      style={{ margin: 0 }}
      rules={props.rules}
    >
      <Select
        size="large"
        disabled={props.disabled}
        loading={loading}
        placeholder="Select Property"
        onChange={props.onChange}
      >
        {list.map((item) => {
          return <Select.Option key={item.key} value={item.key}>{item.key} - {item.type}</Select.Option>;
        })}
       
      </Select>
    </Form.Item>
  );
};

export default SelectProperty;

import { Tabs } from "antd";
import ReactMarkdown from "react-markdown";
import { InputModelRuleInfo } from "../rule-engine/input-model";
import { RuleOptionProps, RuleSelect } from "../rule";
import { ComponentType, useEffect, useState } from "react";
import { ArithmeticRuleInfo } from "../rule-engine/arithmetic";
import { ConditionalRuleInfo } from "../rule-engine/conditional";
import { ForkRuleInfo } from "../rule-engine/fork";
import { SwitchRuleInfo } from "../rule-engine/switch";
import { LogRuleInfo } from "../rule-engine/log";
import { OutputActionRuleInfo } from "../rule-engine/output-action";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { saveOption, selectOptionNodes } from "../../slice";
import { InpuTelemetryRuleInfo } from "../rule-engine/input-telemetry";
import { LinkRule } from "./link-rule";
import { GetLastModelRuleInfo } from "../rule-engine/get-last-mode";
import { OutputModelRuleInfo } from "../rule-engine/output-model";
import { FormatRuleInfo } from "../rule-engine/format-rule";
import { DelayRuleInfo } from "../rule-engine/delay-rule";


const markdown = '# Hi, *Pluto*!'

type OptionRuleTypes = {
    [key: string]: ComponentType<RuleOptionProps>;
}

const engines: OptionRuleTypes  = {
    [InputModelRuleInfo.id]: InputModelRuleInfo.option,
    [ArithmeticRuleInfo.id]: ArithmeticRuleInfo.option,
    [ConditionalRuleInfo.id]: ConditionalRuleInfo.option,
    [ForkRuleInfo.id]: ForkRuleInfo.option,
    [SwitchRuleInfo.id]: SwitchRuleInfo.option,
    [LogRuleInfo.id]: LogRuleInfo.option,
    [OutputActionRuleInfo.id]: OutputActionRuleInfo.option,
    [InpuTelemetryRuleInfo.id]: InpuTelemetryRuleInfo.option,
    [GetLastModelRuleInfo.id]: GetLastModelRuleInfo.option,
    [OutputModelRuleInfo.id]: OutputModelRuleInfo.option,
    [FormatRuleInfo.id]: FormatRuleInfo.option,
    [DelayRuleInfo.id]: DelayRuleInfo.option,
    // "output-alert":"",
    // "output-kafka": "",
    // "fork": "",
    // "create-model":  "",
}

const OptionEngine: React.FC<{
    select: RuleSelect| undefined
    onSave: (nodeId: string, name: string) => void
    onRemoveLink: (nodeId: string, idEdge: string) => void
    onClose: () => void
}> = (props) => {

    const dispatch = useAppDispatch();
    const optionNodes = useAppSelector(selectOptionNodes)
    const [option, setOption] = useState()

    const { select } = props;

    const onChange = (e: any) => {
        console.log(e)
    }

    useEffect(() => {
        if (select && optionNodes[select.id as string]) {
            setOption(optionNodes[select.id as string]);
        }
    }, [select, optionNodes])

    const onSave = (id: String, data: {option: any;   name: string; }) => {
        if (!select) {
            return
        }
        // set option from store
        dispatch(saveOption({
            nodeId: select.id as string,
            option: data.option
        }))
        // update label
        props.onSave(select.id as string, data.name)
    }

    const onCancel = () => {
        props.onClose()
    }

    const onRemoveLink = (id: string) => {
        props.onRemoveLink(select?.id as string, id);
    }

    const renderOption = () => {
        if (select && select?.id) {
            const Component = engines[select?.type as any] as any
            if (Component) {
                // load option from store
                return (<Component 
                    id={select.id}
                    label={select.data.label}
                    data={option}
                    save={onSave}
                    cancel={onCancel}
                />)
            }
        }

        return (<></>)
    }


    return (
        <Tabs defaultActiveKey="1" onChange={onChange}>
            <Tabs.TabPane tab="Option Rule" key="1">
                { 
                    renderOption()
                }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Link" key="2">
                <LinkRule select={props.select} onRemove={onRemoveLink} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Document" key="3">
                <ReactMarkdown>{markdown}</ReactMarkdown>
            </Tabs.TabPane>
        </Tabs>
    )
}

export default OptionEngine;
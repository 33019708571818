import { WidgetComponent } from "src/modules/widget/models/widget"
import EditLine from "./edit-line"
import ViewLine from "./view-line"


const wiget: WidgetComponent = {
    type: "line",
    edit: EditLine,
    icon: "line-chart",
    name: "Line Chart",
    view: ViewLine,
    options: [],
}

export default wiget
import {  EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Row,
  Spin,
  Table,
} from "antd";
import { Typography } from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  Scalars,
  Maybe,
  SourceInput,
  ModelField,
} from "src/types";
import { selectDetailDeviceModel } from "../../slice";
import { getDeviceModelByIdThunkAction } from "../../thunk";
import EditField from "./edit-field";

const { Title } = Typography;

export interface DeviceModelFieldAndModel {
  description?: Maybe<Scalars['String']>;
  field_id: Scalars["Uint64"];
  id?: Maybe<Scalars["Uint64"]>;
  key?: Scalars["String"];
  source_input?: SourceInput;
  model_field: ModelField;
}

const EditDeviceModel: React.FC<{
  assignId: any | null;
  onClose: (loading: boolean) => void;
}> = (props) => {
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetailDeviceModel);
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [field, setField] = useState<DeviceModelFieldAndModel | null>(null);
  const [list, setList] = useState<DeviceModelFieldAndModel[]>([]);

  useEffect(() => {
    if (props.assignId != null && props.assignId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadData = async () => {
    setLoading(true);
    dispatch<any>(getDeviceModelByIdThunkAction(props.assignId));
    setLoading(false);
  };

  useEffect(() => {
    const mapData = new Map<any, DeviceModelFieldAndModel>();

    if (detail?.model?.fields) {
      for (const field of detail?.model?.fields) {
        mapData.set(field.id, {
          field_id: field.id,
          model_field: field,
        });
      }
    }

    if (detail?.fields) {
      for (const field of detail?.fields) {
        const data = mapData.get(field.field_id);
        if (data) {
          data.description = field.description;
          data.id = field.id;
          data.key = field.key;
          data.source_input = field.source_input;
          mapData.set(field.field_id, data);
        }
      }
    }

    const result = [] as DeviceModelFieldAndModel[];

    mapData.forEach((v, k) => {
      result.push(v);
    });

    setList(result);
  }, [detail]);

  const onEdit = (row: DeviceModelFieldAndModel) => {
    setField(row);
    setIsModal(true);
  };


  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Row gutter={[10, 10]} style={{ marginTop: "50px" }}>
        <Col span={24}>
          <Title style={{ textAlign: "center" }} level={5}>
            Field Item Model
          </Title>
        </Col>
        <Col span={24}>
          <Table
            dataSource={list}
            size="middle"
            rowKey={"id"}
            pagination={false}
          >
            <Column
              title="Model Key"
              dataIndex="model_key"
              key="model_key"
              render={(v, row: DeviceModelFieldAndModel) => {
                return <>{row.model_field.key}</>;
              }}
            />
            <Column
              title="Field Type"
              dataIndex="field_type"
              key="field_type"
              render={(text: any, record: DeviceModelFieldAndModel) => {
                return record.model_field.type;
              }}
            />
            <Column
              title="Device Key"
              dataIndex="key"
              key="key"
              render={(text: any, record: DeviceModelFieldAndModel) => {
                if (!record.key) {
                  return "--";
                }
                return record.key;
              }}
            />
                        <Column
              title="Source Input"
              dataIndex="source_input"
              key="source_input"
              render={(text: any, record: DeviceModelFieldAndModel) => {
                if (!record.source_input) {
                  return "--";
                }
                return record.source_input;
              }}
            />
            <Column
              title=""
              key="action"
              width={10}
              render={(text: any, record: DeviceModelFieldAndModel) => (
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    onEdit(record);
                  }}
                />
              )}
            />
          </Table>
        </Col>

        <Modal
          visible={isModal}
          title={"Edit Field"}
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
          <EditField
            assignId={props.assignId}
            data={field}
            deviceId={detail?.device_id}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                loadData();
              }
            }}
          />
        </Modal>
      </Row>
    </Spin>
  );
};

export default EditDeviceModel;

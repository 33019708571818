import { BarsOutlined, DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, Modal, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import { DeviceModelDetail } from "src/types";
import { selectListModelByDeviceDeviceModel } from "../slice";
import { getDeviceModelByDeviceIdThunkAction } from "../thunk";

const ListDeviceModel: React.FC<{ 
    deviceId: string
    onAction: (action: string, item: DeviceModelDetail) => void;
    refLoading: (callback: () => void) => void;
}> = (props) => {
    const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);
    const [item, setSelectitem] = useState<any | null>(null);
  
    const dispatch = useAppDispatch();
    const listData = useAppSelector(selectListModelByDeviceDeviceModel);
  
    props.refLoading(() => {
      dispatch<any>(getDeviceModelByDeviceIdThunkAction(props.deviceId));
    });

    useEffect(() => {
        dispatch<any>(getDeviceModelByDeviceIdThunkAction(props.deviceId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
  
  
    const onMenu = (info: { key: string }, row: DeviceModelDetail) => {
      setSelectitem(row);
      switch (info.key) {
        case "delete": {
          setIsModalVisibleDel(true);
          return;
        }
        default: {
          props.onAction(info.key, row);
        }
      }
    };
  
    const handleOkDelete = () => {
      if (item != null) {
        props.onAction("delete", item);
      }
      setIsModalVisibleDel(false);
    };
  
    return (
      <>
        <Card size="small">
          <Table
            dataSource={listData}
            pagination={false}
            size="middle"
            rowKey={"id"}
          >
            <Column
              title="Model"
              dataIndex="model"
              key="model"
              render={(text: any, record: DeviceModelDetail) => (
                <Button
                  type="link"
                  onClick={() => {
                    props.onAction("detail", record);
                  }}
                >
                  {record.model?.name}
                </Button>
              )}
            />
            <Column
              width={200}
              title="Field"
              dataIndex="field"
              key="field"
              render={(text: any, record: DeviceModelDetail) => <>{ record.fields?.length || 0 }</>}
            />
            <Column width={200} title="Status" dataIndex="status" key="status" />
            <Column
              width={200}
              title="Created At"
              dataIndex="created_at"
              key="created_at"
              render={(text: any, record: DeviceModelDetail) => <Time data={record.created_at} />}
            />
            <Column
              width={200}
              title="Updated At"
              dataIndex="updated_at"
              key="updated_at"
              render={(text: any, record: DeviceModelDetail) => <Time data={record.updated_at} />}
            />
            <Column
              title=""
              key="action"
              width={10}
              render={(text: any, record: any) => (
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(info) => {
                        onMenu(info, record);
                      }}
                    >
                      <Menu.Item key="detail" icon={<BarsOutlined />}>
                        Detail
                      </Menu.Item>
                      <Menu.Item key="edit" icon={<EditOutlined />}>
                        Edit
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="delete" icon={<DeleteOutlined />}>
                        Delete
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    type="text"
                    icon={<MoreOutlined />}
                    onClick={(e) => e.preventDefault()}
                  />
                </Dropdown>
              )}
            />
          </Table>
        </Card>
        <Modal
          title="Delete Model"
          visible={isModalVisibleDel}
          onOk={handleOkDelete}
          onCancel={() => {
            setIsModalVisibleDel(false);
          }}
        >
          <p>
            You do want to delete policy <Tag color={"blue"}>{item?.name}</Tag>!
          </p>
        </Modal>
      </>)
};

export default ListDeviceModel;

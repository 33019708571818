import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
  UnorderedListOutlined,
  ProjectOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, Modal, Switch, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import { Device } from "src/types";
import { selectFilter, selectListDivice } from "../slice";
import { listPaginationDeviceThunkAction } from "../thunk";

const ListDevice: React.FC<{
  onAction: (action: string, item: Device) => void;
  refLoading: (callback: () => void) => void;
}> = (props) => {
  const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);
  const [device, setSelectDevice] = useState<Device | null>(null);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const listDivice = useAppSelector(selectListDivice);

  props.refLoading(() => {
    dispatch<any>(listPaginationDeviceThunkAction(filter));
  });

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  useEffect(() => {
    dispatch<any>(listPaginationDeviceThunkAction(filter));
  }, [dispatch, filter]);

  const onMenu = (info: { key: string }, row: Device) => {
    setSelectDevice(row);
    switch (info.key) {
      case "delete": {
        setIsModalVisibleDel(true);
        return;
      }
      default: {
        props.onAction(info.key, row);
      }
    }
  };

  const handleOkDelete = () => {
    if (device != null) {
      props.onAction("delete", device);
    }
    setIsModalVisibleDel(false);
  };

  return (
    <>
      <Card size="small">
        <Table
          dataSource={
            listDivice != null && listDivice?.data != null
              ? (listDivice.data as Device[])
              : []
          }
          onChange={onChange}
          size="middle"
          rowKey={"id"}
        >
          <Column
            title=""
            key="action_base"
            width={180}
            render={(text: any, row: Device) => (
              <>
                <Button
                  type="link"
                  onClick={() => {
                    props.onAction("detail-data", row);
                  }}
                  title="Data"
                >
                  <UnorderedListOutlined />
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    props.onAction("property", row);
                  }}
                  title="Property"
                >
                  <ContainerOutlined />
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    props.onAction("model", row);
                  }}
                  title="Model"
                >
                  <ProjectOutlined />
                </Button>
              </>
            )}
          />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            render={(text: any, row: Device) => (
              <Button
                type="link"
                onClick={() => {
                  props.onAction("detail", row);
                }}
              >
                {text}
              </Button>
            )}
          />
          <Column title="Device key" dataIndex="key" key="key" />
          <Column
            title="Namespace"
            dataIndex="namespace"
            key="namespace"
            render={(text: any, row: Device) => row.namespace?.name}
          />
          <Column
            width={100}
            title="Is Gateway"
            dataIndex="is_gateway"
            key="is_gateway"
            render={(text: any, row: Device) => {
              return <Switch disabled checked={row.is_gateway}></Switch>;
            }}
          />
          <Column
            width={200}
            title="Created At"
            dataIndex="created_at"
            key="created_at"
            render={(text: any, record: Device) => (
              <Time data={record.created_at} />
            )}
          />
          <Column
            width={200}
            title="Updated At"
            dataIndex="updated_at"
            key="updated_at"
            render={(text: any, record: Device) => (
              <Time data={record.updated_at} />
            )}
          />
          <Column
            title=""
            key="action"
            width={10}
            render={(text: any, record: any) => (
              <Dropdown
                overlay={
                  <Menu
                    onClick={(info) => {
                      onMenu(info, record);
                    }}
                  >
                    <Menu.Item key="property" icon={<ContainerOutlined />}>
                      Property
                    </Menu.Item>
                    <Menu.Item key="detail" icon={<BarsOutlined />}>
                      Detail
                    </Menu.Item>
                    <Menu.Item key="edit" icon={<EditOutlined />}>
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>
            )}
          />
        </Table>
      </Card>
      <Modal
        title="Delete Device"
        visible={isModalVisibleDel}
        onOk={handleOkDelete}
        onCancel={() => {
          setIsModalVisibleDel(false);
        }}
      >
        <p>
          You do want to delete device{" "}
          <Tag color={"blue"}>
            {device?.name} - {device?.key}
          </Tag>{" "}
          !
        </p>
      </Modal>
    </>
  );
};

export default ListDevice;

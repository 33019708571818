import { Routes, Route } from 'react-router-dom'
import React from "react";
import LoginLayout from 'src/components/layout/login-layout';
import LoginPage from './login.page';

type P = {

}

export function LoginRouter(props: P) {
    return (
        <LoginLayout>
            <Routes>
                <Route path={'/'} element={<LoginPage />} />
            </Routes>
        </LoginLayout>
    )
}

export default LoginRouter
import { Alert, Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { ThunkResult, useAppDispatch, useAppSelector } from "src/app/hooks";
import { Widget, WidgetData, WidgetDetail } from "src/types";
import { GetWidgetByIdQuery } from "../graph/getWidgetById.generated";
import { selectFilter, selectMessage } from "../slice";
import {
  createWidgetThunkAction,
  updateWidgetThunkAction,
  getListPaginationWidgetThunkAction,
  getWidgetByIdThunkAction,
  deleteWidgetThunkAction,
} from "../thunk";
import ListWidget from "./list-widget";
import OptionWidget from "./option-data/option";
import WidgetEdit from "./widget-edit/widget-edit";
import QueryDataShowWidget from "./widget/query-data-show-widget";
import { LineWidget } from "./widget/widget";

const Widgets: React.FC<{
  onAction: (action: string, item: any) => void;
  refLoading: (callback: () => void) => void;
}> = (props) => {
  const [add, setAdd] = useState(false);
  
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const message = useAppSelector(selectMessage);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const [detail, setDetail] = useState<WidgetDetail | null>(null);

  props.refLoading(() => {
    loadData();
  });

  const loadData = () => {
    dispatch<any>(getListPaginationWidgetThunkAction(filter));
  };

  const getDetail = async (id: any) => {
    setLoading(true);
    let rs: ThunkResult<GetWidgetByIdQuery, any> = await dispatch<any>(
      getWidgetByIdThunkAction(id)
    );
    if (rs.error) {
      console.log(rs.error);
    } else {
      setDetail(rs.payload.getWidgetById as WidgetDetail);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onAction = async (widget: Widget | null, action: string) => {
    switch (action) {
      case "new": {
        setDetail({
          name: "",
          option: {},
          data: {
            model_id: "",
            namespace_id: "",
            device_id: "",
            source: "model_log",
            time: "5m",
            type: "list",
          },
          created_at: "",
          type: LineWidget.type,
          updated_at: "",
          id: "",
        });
        setAdd(true);
        setEdit(true);
        return;
      }
      case "detail": {
        getDetail(widget?.id);
        break;
      }
      case "edit": {
        getDetail(widget?.id);
        setAdd(false);
        setEdit(true);
        break;
      }
      case "delete": {
        await dispatch<any>(
          deleteWidgetThunkAction(widget?.id)
        );
        loadData();
        return;
      }
    }
    props.onAction(action, widget);
  };

  const onSave = async () => {
    let rs: ThunkResult<boolean, any>;
    if (add && edit && detail !== null) {
      setLoading(true);
      try {
        rs = await dispatch<any>(
          createWidgetThunkAction({
            data: {
              model_id: detail?.data.model_id,
              namespace_id: detail?.data.namespace_id,
              device_id: detail?.data.device_id,
              source: "model_log",
              time: detail?.data.time,
              type: detail?.data.type,
              window: detail?.data.window ? detail?.data.window : "",
              fields: detail?.data.fields,
            },
            name: detail?.name,
            option: detail?.option,
            type: detail?.type,
            type_widget: detail?.type,
          })
        );
        if (!rs.error) {
          setAdd(false);
          setEdit(false);
          setDetail(null);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      loadData();
      return;
    }

    if (edit && detail !== null) {
      setLoading(true);
      try {
        rs = await dispatch<any>(
          updateWidgetThunkAction({
            id: detail.id,
            input: {
              data: {
                model_id: detail?.data.model_id,
                namespace_id: detail?.data.namespace_id,
                device_id: detail?.data.device_id,
                source: "model_log",
                time: detail?.data.time,
                type: detail?.data.type,
                window: detail?.data.window ? detail?.data.window : "",
                fields: detail?.data.fields,
              },
              name: detail?.name,
              option: detail?.option,
              type: detail?.type,
              type_widget: detail?.type,
            },
          })
        );
        if (!rs.error) {
          setAdd(false);
          setEdit(false);
          setDetail(null);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }

    loadData();
  };

  const onChangeOption = (data: WidgetData) => {
    setDetail(Object.assign({}, detail, { data }));
  };

  return (
    <>
      <Row gutter={[2, 2]}>
        <Col span={18}>
          <Card size="small" style={{ height: 550 }}>
            {detail !== null && (
              <QueryDataShowWidget
                widget={detail}
                onData={(value) => {
                  setData(value);
                }}
                height={500}
              />
            )}
          </Card>
        </Col>
        <Col span={6}>
          <Card size="small" style={{ height: 550 }}>
            {!edit && <ListWidget action={onAction} />}
            {detail !== null && edit && (
              <Spin spinning={loading}>
                <Row gutter={[0, 10]}>
                  {message && (
                    <Col span={24}>
                      <Alert type="error" message={message}></Alert>
                    </Col>
                  )}
                  <Col span={24}>
                    <WidgetEdit
                      save={onSave}
                      cancel={() => {
                        setEdit(false);
                      }}
                      change={(item) => {
                        setDetail(item);
                      }}
                      widget={detail}
                    />
                  </Col>
                </Row>
              </Spin>
            )}
          </Card>
        </Col>
        {detail !== null && (
          <Col span={24}>
            <Card size="small">
              <OptionWidget
                option={detail.data}
                data={data}
                submit={onChangeOption}
              />
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Widgets;

import Typography from "antd/lib/typography";
import Title from "antd/lib/typography/Title";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleInfo, RuleOptionProps } from "../rule";
import { Form, Spin, Row, Col, Alert, Input, Button } from "antd";
import { useEffect } from "react";
import SelectModel from "src/modules/model/components/select-model";

// eslint-disable-next-line
const { Text } = Typography;

const OutputKafkaRule: React.FC<NodeProps> = (props) => {
    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
             <Handle
                type="target"
                position={Position.Left}
                id="input"
                title="input"
                style={{ background: 'red' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                <Title level={5}>Output Kafka</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
        </div>
    )
}

export default OutputKafkaRule;




const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const OutputKafkaRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        let action = ""
        let model_id = ""

        if (props.data && props.data.model_id) {
            model_id = props.data.model_id
        }

        form.setFields([
            {
                name: "name",
                value: props.label,
            },
            {
                name: "model_id",
                value: model_id,
            },
            {
                name: "action",
                value: action,
            },
          ])
    }, [props, form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {
                device_id: values.device_id,
                action : values.action
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="model_id"
                    label="Model"
                    style={{ margin: 0 }}
                >
                  <SelectModel name={"model_id"} disabled={false} />
                </Form.Item>
              
            </Col>
            <Col span={24}>
                <Form.Item
                    name="action"
                    label="Action"
                    style={{ margin: 0 }}
                >
                <Input.TextArea size="large" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const OutputActionRuleInfo: RuleInfo = {
    id: "output-action",
    name: "Output Action",
    rule: OutputKafkaRule,
    option: OutputKafkaRuleOption,
    infinite: false,
    option_default: {
        
    },
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { CreateAssignModelForDeviceDocument, CreateAssignModelForDeviceMutation, CreateAssignModelForDeviceMutationVariables } from './graph/createAssignModelForDevice.generated'
import { GetDeviceModelByIdDocument, GetDeviceModelByIdQuery, GetDeviceModelByIdQueryVariables } from './graph/getDeviceModelById.generated'
import { GetDeviceModelByDeviceIdDocument, GetDeviceModelByDeviceIdQuery, GetDeviceModelByDeviceIdQueryVariables } from './graph/getDeviceModelByDeviceId.generated'
import { GetListPaginationDeviceModelDocument, GetListPaginationDeviceModelQuery, GetListPaginationDeviceModelQueryVariables } from './graph/getListPaginationDeviceModel.generated'
import { UpdateFieldDeviceModelDocument, UpdateFieldDeviceModelMutation, UpdateFieldDeviceModelMutationVariables } from './graph/updateFieldDeviceModel.generated'
import { DeleteFieldDeviceModelDocument, DeleteFieldDeviceModelMutation, DeleteFieldDeviceModelMutationVariables } from './graph/deleteFieldDeviceModel.generated'
import { AssignModelInput, DeleteDeviceModelFieldInput, DeviceModelFieldInput, ListPaginationDeviceModelInput } from "src/types";
import { GetDeviceModelAssignDeviceIdDocument, GetDeviceModelAssignDeviceIdQuery, GetDeviceModelAssignDeviceIdQueryVariables } from './graph/getDeviceModelAssignDeviceId.generated'

export const getListPaginationDeviceModelQueryThunkAction = createAsyncThunk<
    GetListPaginationDeviceModelQuery,
    ListPaginationDeviceModelInput,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/getListPaginationDeviceModelQueryThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationDeviceModelQueryVariables, GetListPaginationDeviceModelQuery>(GetListPaginationDeviceModelDocument, { input: data })
        return res;
    }
)

export const createAssignModelForDeviceThunkAction = createAsyncThunk<
    CreateAssignModelForDeviceMutation,
    AssignModelInput,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/createAssignModelForDeviceThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateAssignModelForDeviceMutationVariables, CreateAssignModelForDeviceMutation>(CreateAssignModelForDeviceDocument, { input: data })
        return res;
    }
)



export const getDeviceModelByDeviceIdThunkAction = createAsyncThunk<
    GetDeviceModelByDeviceIdQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/getDeviceModelByDeviceIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetDeviceModelByDeviceIdQueryVariables, GetDeviceModelByDeviceIdQuery>(GetDeviceModelByDeviceIdDocument, { id: id })
        return res;
    }
)

export const getDeviceModelByIdThunkAction = createAsyncThunk<
    GetDeviceModelByIdQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/getDeviceModelByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetDeviceModelByIdQueryVariables, GetDeviceModelByIdQuery>(GetDeviceModelByIdDocument, { id: id })
        return res;
    }
)

export const getDeviceModelAssignDeviceIdThunkAction = createAsyncThunk<
GetDeviceModelAssignDeviceIdQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/getDeviceModelAssignDeviceId',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetDeviceModelAssignDeviceIdQueryVariables, GetDeviceModelAssignDeviceIdQuery>(GetDeviceModelAssignDeviceIdDocument, { id: id })
        return res;
    }
)

export const updateDeviceModelThunkAction = createAsyncThunk<
    UpdateFieldDeviceModelMutation,
    DeviceModelFieldInput,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/updateDeviceModelThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateFieldDeviceModelMutationVariables, UpdateFieldDeviceModelMutation>(UpdateFieldDeviceModelDocument, { input: data })
        return res;
    }
)

export const deleteDeviceModelThunkAction = createAsyncThunk<
    DeleteFieldDeviceModelMutation,
    DeleteDeviceModelFieldInput,
    { extra: { client: GraphQLClient } }
>(
    'DeviceModel/deleteDeviceModelThunkAction',
    async (input, { extra }) => {
        const res = await extra.client.mutate<DeleteFieldDeviceModelMutationVariables, DeleteFieldDeviceModelMutation>(DeleteFieldDeviceModelDocument, { input: input })
        return res;
    }
)


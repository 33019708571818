import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { DeviceModelDetail, ListPaginationDeviceModel, ListPaginationDeviceModelInput } from 'src/types'
import { getDeviceModelAssignDeviceIdThunkAction, getDeviceModelByDeviceIdThunkAction, getDeviceModelByIdThunkAction, getListPaginationDeviceModelQueryThunkAction } from './thunk'

export const DeviceModelSlice = createSlice({
    name: 'device_model',
    initialState: {
        loading: false,
        message: "",
        detail: null as DeviceModelDetail | null,
        list: {} as ListPaginationDeviceModel,
        filter: {} as ListPaginationDeviceModelInput,
        listModelByDevice: []  as DeviceModelDetail[],
        listDeviceModelAssignDevice: []  as DeviceModelDetail[],
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationDeviceModelInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(getListPaginationDeviceModelQueryThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(getListPaginationDeviceModelQueryThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getListPaginationDeviceModelQueryThunkAction.fulfilled, (state, action) => {
            if (action.payload.getListPaginationDeviceModel != null) {
                let list =  action.payload.getListPaginationDeviceModel as  ListPaginationDeviceModel
                state.list = list
            } else {
                state.list.data = []
                state.list.total = 0
            }
            state.loading = false
        })
        builder.addCase(getDeviceModelByIdThunkAction.fulfilled, (state, action) => {
            if (action.payload.getDeviceModelById != null) {
                let detail =  action.payload.getDeviceModelById as  DeviceModelDetail
                state.detail = detail
            } else {
                state.detail = null
            }
            state.loading = false
        })
        builder.addCase(getDeviceModelByDeviceIdThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(getDeviceModelByDeviceIdThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getDeviceModelByDeviceIdThunkAction.fulfilled, (state, action) => {
            if (action.payload.getDeviceModelByDeviceId != null) {
                let list =  action.payload.getDeviceModelByDeviceId as  DeviceModelDetail[]
                state.listModelByDevice = list
            } else {
                state.listModelByDevice = []
            }
            state.loading = false
        })
        builder.addCase(getDeviceModelAssignDeviceIdThunkAction.fulfilled, (state, action) => {
            if (action.payload.getDeviceModelByDeviceId != null) {
                let list =  action.payload.getDeviceModelByDeviceId as  DeviceModelDetail[]
                state.listDeviceModelAssignDevice = list
            } else {
                state.listDeviceModelAssignDevice = []
            }
            state.loading = false
        })
    }
})

export default DeviceModelSlice.reducer
export const { setFilter } = DeviceModelSlice.actions


// select
export const selectDeviceModel = (state: RootState) => state.device_model
export const selectLoading = createSelector(selectDeviceModel,(state) => state.loading )
export const selectFilter = createSelector(selectDeviceModel, (state) => state.filter)
export const selectMessage = createSelector(selectDeviceModel,(state) => state.message )
export const selectListDeviceModel = createSelector(selectDeviceModel,(state) => state.list )
export const selectDetailDeviceModel = createSelector(selectDeviceModel,(state) => state.detail )
export const selectListModelByDeviceDeviceModel = createSelector(selectDeviceModel,(state) => state.listModelByDevice )
export const selectListDeviceModelAssignDevice = createSelector(selectDeviceModel,(state) => state.listDeviceModelAssignDevice )
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { ListPaginationWidget, ListPaginationWidgetInput, WidgetDetail } from 'src/types'
import { deleteWidgetThunkAction, getListPaginationWidgetThunkAction, getSearchWidgetThunkAction, getWidgetByIdThunkAction } from './thunk'

export const WidgetSlice = createSlice({
    name: 'widget',
    initialState: {
        loading: false,
        message: "",
        list: {
            data: [] as any[]
        } as ListPaginationWidget,
        search: {
            data: [] as any[]
        } as ListPaginationWidget,
        detail: null as WidgetDetail | null,
        filter: {
            limit: 50,
            skip: 0,
        } as ListPaginationWidgetInput
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationWidgetInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(getListPaginationWidgetThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(getListPaginationWidgetThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationWidgetThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.list = action.payload.getListPaginationWidget as ListPaginationWidget
        })
        builder.addCase(getWidgetByIdThunkAction.fulfilled, (state, action) => {
            state.detail = action.payload.getWidgetById as WidgetDetail
        })
        builder.addCase(deleteWidgetThunkAction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteWidgetThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(deleteWidgetThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getSearchWidgetThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.search = action.payload.getListPaginationWidget as ListPaginationWidget
        })
    }
})

export default WidgetSlice.reducer
export const { setFilter } = WidgetSlice.actions


// select
export const selectWidget = (state: RootState) => state.widget
export const selectLoading = createSelector(selectWidget,(state) => state.loading )
export const selectMessage = createSelector(selectWidget,(state) => state.message )
export const selectFilter = createSelector(selectWidget,(state) => state.filter )
export const selectListWidget = createSelector(selectWidget,(state) => state.list )
export const selectListWidgetSearch = createSelector(selectWidget,(state) => state.search.data )
export const selectDetail = createSelector(selectWidget,(state) => state.detail )
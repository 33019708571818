import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"

import { ListPaginationWidgetInput, WidgetInput } from 'src/types'

import { GetListPaginationWidgetDocument, GetListPaginationWidgetQuery, GetListPaginationWidgetQueryVariables } from './graph/getListPaginationWidget.generated'
import { GetWidgetByIdDocument, GetWidgetByIdQuery, GetWidgetByIdQueryVariables } from './graph/getWidgetById.generated'
import { CreateWidgetDocument, CreateWidgetMutation, CreateWidgetMutationVariables } from './graph/createWidget.generated'
import { UpdateWidgetDocument, UpdateWidgetMutation, UpdateWidgetMutationVariables } from './graph/updateWidget.generated'
import { DeleteWidgetDocument, DeleteWidgetMutation, DeleteWidgetMutationVariables } from './graph/deleteWidget.generated'

export const getListPaginationWidgetThunkAction = createAsyncThunk<
    GetListPaginationWidgetQuery,
    ListPaginationWidgetInput,
    { extra: { client: GraphQLClient } }
>(
    'Widget/getListPaginationWidgetThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationWidgetQueryVariables, GetListPaginationWidgetQuery>(GetListPaginationWidgetDocument, { input: data })
        return res;
    }
)

export const getSearchWidgetThunkAction = createAsyncThunk<
    GetListPaginationWidgetQuery,
    ListPaginationWidgetInput,
    { extra: { client: GraphQLClient } }
>(
    'Widget/getSearchWidgetThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationWidgetQueryVariables, GetListPaginationWidgetQuery>(GetListPaginationWidgetDocument, { input: data })
        return res;
    }
)


export const getWidgetByIdThunkAction = createAsyncThunk<
    GetWidgetByIdQuery,
    any,
    { extra: { client: GraphQLClient } }
>(
    'Widget/getWidgetByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetWidgetByIdQueryVariables, GetWidgetByIdQuery>(GetWidgetByIdDocument, { id: id })
        return res;
    }
)


export const createWidgetThunkAction = createAsyncThunk<
    CreateWidgetMutation,
    WidgetInput,
    { extra: { client: GraphQLClient } }
>(
    'Widget/createWidgetThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateWidgetMutationVariables, CreateWidgetMutation>(CreateWidgetDocument, { input: data })
        return res;
    }
)

export const updateWidgetThunkAction = createAsyncThunk<
    UpdateWidgetMutation,
    UpdateWidgetMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'Widget/updateWidgetThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateWidgetMutationVariables, UpdateWidgetMutation>(UpdateWidgetDocument, data)
        return res;
    }
)

export const deleteWidgetThunkAction = createAsyncThunk<
    DeleteWidgetMutation,
    number,
    { extra: { client: GraphQLClient } }
>(
    'Widget/createWidgetThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<DeleteWidgetMutationVariables, DeleteWidgetMutation>(DeleteWidgetDocument, { id: data })
        return res;
    }
)


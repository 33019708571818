import { WidgetComponent } from "../../../../models/widget";
import EditRing from "./edit-ring";
import ViewRing from "./view-ring";

const wiget: WidgetComponent = {
    type: "tiny-ringprogess",
    edit: EditRing,
    icon: "tiny-ringprogess-chart",
    name: "Tiny Ring Progess",
    view: ViewRing,
    options: [],
}

export default wiget
import { Col, Row, Tabs } from "antd";
import { useState } from "react";
import ListAlertLog from "src/modules/alert/components/list-alert-log";
import LastTime from "src/modules/device_log/components/last-time";
import ListTelemetry from "src/modules/device_log/components/list-telemetry/list-telemetry";
import ModelLastTime from "src/modules/model_log/components/last-time";
import SelectModelDevice from "src/modules/model_log/components/select-model-device";
import { Device, TypeDeviceLog } from "src/types";

const { TabPane } = Tabs;

const DataDeviceLog: React.FC<{
  device: Device | null;
}> = (props) => {
  const [modelId, setModelId] = useState("");
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Last Telemetry" key="1">
        <LastTime deviceId={props.device?.id} type={TypeDeviceLog.Log} />
      </TabPane>
      <TabPane tab="Last Property" key="2">
        <LastTime deviceId={props.device?.id} type={TypeDeviceLog.Property} />
      </TabPane>
      <TabPane tab="Log Telemetry" key="3">
        <ListTelemetry deviceId={props.device?.id} type={TypeDeviceLog.Log} />
      </TabPane>
      <TabPane tab="Last Log Model" key="4">
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <h3>Model Log of Device</h3>
          </Col>
          <Col span={24}>
            <SelectModelDevice
              deviceId={props.device?.id}
              disabled={false}
              name="model_id"
              onChange={(modelId) => {
                setModelId(modelId);
              }}
            />
          </Col>
          <Col span={24}>
            {modelId !== "" && (
              <ModelLastTime
                deviceId={props.device?.id}
                modelId={modelId}
                showType={true}
              />
            )}
          </Col>
        </Row>
      </TabPane>
      <TabPane tab="Log Alert" key="5">
        <ListAlertLog showAlert={true} deviceId={props.device?.id} filterOption={{ filterAlert: true }} />
      </TabPane>
    </Tabs>
  );
};

export default DataDeviceLog;

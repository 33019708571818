import { DeleteOutlined } from "@ant-design/icons"
import { Row, Col, Table, Button } from "antd"
import { LinkNode, RuleSelect } from "../rule"
import { useEffect, useState } from "react"
import Column from "antd/lib/table/Column"


export const LinkRule: React.FC<{
    select: RuleSelect | undefined
    onRemove: (id: string) => void
}> = (props) => {

    const [list, setList] = useState<LinkNode[]>(props.select?.links || [])

    useEffect(() => {
        if (!props.select) return;
        setList(props.select?.links || [])
    }, [props])

    const onRemove = (id: string) => {
        const newList = list.filter(item => item.id !== id)
        setList(newList)

        props.onRemove(id)
    }

    return (
        <Row gutter={[10, 10]}>
        <Col span={24}>
          <Table
            dataSource={list}
            size="middle"
            rowKey={"id"}
            pagination={false}
          >
            <Column
              title="Name Node"
              dataIndex="node"
              key="node"
            />
            <Column
              title="Type Node"
              dataIndex="type_node"
              key="type_node"
            />
            <Column
              title="Key"
              dataIndex="key"
              key="key"
            />
            <Column
              title="Type"
              dataIndex="type"
              key="type"
            />
            <Column
              title=""
              key="action"
              width={100}
              render={(text: any, record: LinkNode) => (
                <>
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            onRemove(record.id);
                        }}
                    />
                </>
              )}
            />
          </Table>
        </Col>
    </Row>
    )
}
import { WidthProvider, Responsive } from "react-grid-layout";
import WidgetBoxGrid from "src/modules/widget/components/widget-box/widget-box-grid";
import _ from "lodash";
import { UiDetail, WidgetDetail } from "src/types";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayoutView: React.FC<{
  detail: UiDetail;
}> = (props) => {
  const { detail } = props;

  const generateDOM = () => {
    const mapWidget: any = {};

    if (detail.widget_list) {
      for (const item of detail.widget_list) {
        mapWidget[item.id] = item;
      }
    }

    return _.map(detail.layouts, (l, i) => {
      if (!l) {
        return null;
      }
      const widgetId = detail.widgets[l.i];
      if (widgetId === undefined) {
        return null;
      }
      return (
        <div key={l.i} data-grid={l}>
          <WidgetBoxGrid
            label={detail.labels[l.i]}
            widget={mapWidget[widgetId] as WidgetDetail}
          />
        </div>
      );
    });
  };

  return (
    <ResponsiveReactGridLayout
      {...props}
      // WidthProvider option
      measureBeforeMount={false}
      // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
      // and set `measureBeforeMount={true}`.
      useCSSTransforms={true}
      compactType="vertical"
      margin={[2, 2]}
      containerPadding={[5, 0]}
      rowHeight={10}
      isDraggable={false}
      isResizable={false}
    >
      {generateDOM()}
    </ResponsiveReactGridLayout>
  );
};

export default GridLayoutView;

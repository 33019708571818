import { Pie } from "@ant-design/charts";
import React, { Component } from "react";
import { isUndefined } from "lodash";
import { WidgetData } from "src/modules/widget/models/data";
import { WidgetOption } from "src/modules/widget/models/widget";

type P = {
    data: WidgetData
    option:  WidgetOption
};

type S = {

};

class ViewPie extends Component<P, S> {
    state = {}
    render() {
        let { option, data } = this.props

        if(isUndefined(data.list) || data.list == null){
            data.list = []
        }
        var config = {
            appendPadding: 10,
            data: data[option.data.data_key || "list" ] || [],
            angleField: option.angleField || 'val',
            colorField: option.colorField || 'type',
            radius: 0.9,
            point: {
                size: 5,
                shape: 'diamond',
              },
            label: {
                offset: '-30%',
                style: {
                    fill: '#aaa',
                    textAlign: 'center',
                },
            },
        }
        return (
            <Pie {...config} />
        )
    }
}

export default ViewPie

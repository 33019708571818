import { Breadcrumb, Col, Row, Spin } from "antd";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectHome, selectLoading } from "src/modules/ui/slice";
import GridLayoutView from "src/modules/ui/components/grid-layout-view";
import { getUiHomeThunkAction } from "src/modules/ui/thunk";
import Title from "antd/lib/typography/Title";

const HomeUiPage: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectHome);
  const loading = useAppSelector(selectLoading);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMemo(() => {
      dispatch<any>(getUiHomeThunkAction({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="session-main">
      <Spin spinning={loading}>
        <Helmet>
          <title>Dipper - Home Page</title>
        </Helmet>
        <Row gutter={[0, 10]}>
         
          <Col span={24} style={{ paddingLeft: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        
          <Col span={24}>
            {detail && <GridLayoutView detail={detail} />}
            {
              !detail && <Row gutter={[0, 10]}>
                <Col span={24} >
                  <Title level={4}>Not have homepage</Title>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default HomeUiPage;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from 'src/app/store'
import { DeviceLog, ListPaginationDeviceLogInput } from 'src/types'
import { getFieldsDeviceLogThunkAction, getListPaginationDeviceLogThunkAction } from './thunk'

export const DeviceLogSlice = createSlice({
    name: 'device_log',
    initialState: {
        loading: false,
        message: "",
        list: [] as DeviceLog[],
        last_time: [] as DeviceLog[],
        by_time: [] as DeviceLog[],
        fields: [] as string[],
        filter: {
            device_id: 0, // Provide a default value for device_id
            limit: 50,
            end_time: moment().add(1, "minute").toISOString(),
            start_time: moment().subtract(10, "minute").toISOString(),
        } as ListPaginationDeviceLogInput
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationDeviceLogInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(getListPaginationDeviceLogThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
            state.list = []
        })
        builder.addCase(getListPaginationDeviceLogThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationDeviceLogThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            if (action.payload.getListPaginationDeviceLog != null) {
                let list =  action.payload.getListPaginationDeviceLog?.data as DeviceLog[]
                if (list) {
                    list = list.sort((a, b) => moment(b.time).diff(moment(a.time)))

                    state.list = list.map((item, index) => {
                        return Object.assign({}, item, { id: index}) as DeviceLog
                    })
                    return
                } 
            }
            state.list = []
        })
        builder.addCase(getFieldsDeviceLogThunkAction.pending, (state, action) => {
            state.fields = []
        })
        builder.addCase(getFieldsDeviceLogThunkAction.fulfilled, (state, action) => {
            if (action.payload.getFieldsDeviceLog != null) {
                state.fields  = action.payload.getFieldsDeviceLog as string[]
            }
        })
    }
})

export default DeviceLogSlice.reducer
export const { setFilter } = DeviceLogSlice.actions


// select
export const selectDeviceLog = (state: RootState) => state.device_log
export const selectLoading = createSelector(selectDeviceLog, (state) => state.loading)
export const selectMessage = createSelector(selectDeviceLog, (state) => state.message)
export const selectFilter = createSelector(selectDeviceLog, (state) => state.filter)
export const selectListDeviceLog = createSelector(selectDeviceLog, (state) => state.list)
export const selectLastTimeDeviceLog = createSelector(selectDeviceLog, (state) => state.last_time)
export const selectByTimeDeviceLog = createSelector(selectDeviceLog, (state) => state.by_time)
export const selectFieldsDeviceLog = createSelector(selectDeviceLog, (state) => state.fields)
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNamespaceByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetNamespaceByIdQuery = { __typename?: 'Query', getNamespaceById: { __typename?: 'Namespace', id: any, name: string, description?: string | null, owner_id: any, created_at: any, updated_at: any, status: Types.Status, owner?: { __typename?: 'UserBaseView', name: string, email: string } | null } };


export const GetNamespaceByIdDocument = gql`
    query getNamespaceById($id: Uint64!) {
  getNamespaceById(id: $id) {
    id
    name
    description
    owner_id
    created_at
    updated_at
    owner {
      name
      email
    }
    status
  }
}
    `;

/**
 * __useGetNamespaceByIdQuery__
 *
 * To run a query within a React component, call `useGetNamespaceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNamespaceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNamespaceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNamespaceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetNamespaceByIdQuery, GetNamespaceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNamespaceByIdQuery, GetNamespaceByIdQueryVariables>(GetNamespaceByIdDocument, options);
      }
export function useGetNamespaceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNamespaceByIdQuery, GetNamespaceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNamespaceByIdQuery, GetNamespaceByIdQueryVariables>(GetNamespaceByIdDocument, options);
        }
export type GetNamespaceByIdQueryHookResult = ReturnType<typeof useGetNamespaceByIdQuery>;
export type GetNamespaceByIdLazyQueryHookResult = ReturnType<typeof useGetNamespaceByIdLazyQuery>;
export type GetNamespaceByIdQueryResult = Apollo.QueryResult<GetNamespaceByIdQuery, GetNamespaceByIdQueryVariables>;
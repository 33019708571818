import { Col, Form, Input, Row } from "antd";
import React from "react";
import { WidgetOption } from "../../../models/widget";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const Edit: React.FC<{
  option: WidgetOption;
  change: (option: WidgetOption) => void;
}> = (props) => {
  const [form] = Form.useForm<any>();

  const onChange = async () => {
    const values = await form.validateFields();
    props.change(values);
  };

  return (
    <Form {...layout} form={form} onChangeCapture={onChange}>
      <Row style={{ paddingTop: "10px" }}>
        <Col span={24}>
          <Form.Item label="Source Data Key" name="data_key">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item  label="Angle Field" name="angle_field">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Color Field" name="color_field">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Edit;
import React, { useEffect } from "react";
import { menuSet, selectIsAuth, selectMenu } from "src/app/app.slice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useNavigate } from "react-router-dom";
import { Avatar, Col, Divider, Layout, Row } from "antd";
import MenuApp from "./menu-app";
import { UserOutlined } from "@ant-design/icons";
import { selectUserDisplayName } from "src/modules/login/login.slice";
import ToolbarApp from "./toolbar-app";
import './main.css';


const { Header, Content, Footer, Sider } = Layout;

interface P {
  children: any;
  full?: boolean;
  skip_auth?: boolean;
  hide_footer?: boolean
}

export const MainLayout: React.FC<P> = (props: P) => {
  const navigate = useNavigate();


  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(selectIsAuth);
  useEffect(() => {
    if (!isAuth && !props.skip_auth) {
      navigate("/login");
    }
  }, [isAuth, navigate, props]);

  const collapsed = useAppSelector(selectMenu);

  const setCollapsed = (val: boolean) => {
    dispatch(menuSet(val))
  }

  const name = useAppSelector(selectUserDisplayName)


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <Row justify="center" align="middle" style={{ paddingTop: "30px" }}>
          <Col>
            <Avatar size={collapsed ? 50 : 64} icon={<UserOutlined />} />
          </Col>
        </Row>
        <Row justify="center" align="middle" >
          <Col>
            <Divider orientation="center" style={{ color: "white", whiteSpace: "normal", flexDirection: "column" }}>{name}</Divider>
          </Col>
        </Row>
        <MenuApp />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} >
          <ToolbarApp />
        </Header>
        <Content>
          {props.children}
        </Content>
        {
          !props.hide_footer && (
            <Footer style={{ textAlign: 'center' }}>O-Hub ©2024 Created by Overchall</Footer>
          )
        }
       
      </Layout>
    </Layout>
  );
};

export default MainLayout;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { ThunkResult, useAppDispatch } from "src/app/hooks";
import {
  DataModelItem,
  WidgetDataResult,
} from "src/modules/widget/models/data";
import { ModelLog, WidgetDetail } from "src/types";
import { GetListPaginationModelLogQuery } from "../graph/getListPaginationModelLog.generated";
import { GetModelLogLastTimeQuery } from "../graph/getModelLogLastTime.generated";
import {
  getListPaginationModelLogThunkAction,
  getModelLogLastTimeThunkAction,
} from "../thunk";

const QueryDataWidget: React.FC<{
  widgetComponent: typeof React.Component;
  widget: WidgetDetail;
  filter?: any;
  refLoading?: (callback: () => void) => void;
  onData?: (data: any) => void;
  height?: number;
  width?: number;
}> = (props) => {
  const { widget, filter } = props;
  const dispatch = useAppDispatch();

  const [data, setData] = useState<WidgetDataResult>({
    list: [],
  });

  if (props.refLoading) {
    props.refLoading(() => {
      getData(widget, filter);
    });
  }

  useEffect(() => {
    if (widget) {
      getData(widget, filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, filter]);

  const getData = async (widget: WidgetDetail, filter: any) => {
    if (widget.data.type === "last") {
      const rs: ThunkResult<GetModelLogLastTimeQuery, any> =
        await dispatch<any>(
          getModelLogLastTimeThunkAction({
            device_id: widget.data.device_id,
            model_id: widget.data.model_id,
            keys: widget.data.fields?.join(","),
          })
        );
      if (
        rs.payload &&
        rs.payload.getModelLogLastTime &&
        rs.payload.getModelLogLastTime.data
      ) {
        convertData(rs.payload.getModelLogLastTime.data as ModelLog[]);
      } else {
        convertData([]);
      }
      return
    } else {
      let endtime = moment();
      let starttime = moment().subtract(5, "minute");
      switch (widget.data.time) {
        case "5m": {
          starttime = moment().subtract(5, "minute");
          break;
        }
        case "10m": {
          starttime = moment().subtract(10, "minute");
          break;
        }
        case "30m": {
          starttime = moment().subtract(30, "minute");
          break;
        }
        case "1h": {
          starttime = moment().subtract(1, "hour");
          break;
        }
        case "3h": {
          starttime = moment().subtract(3, "hour");
          break;
        }
        case "6h": {
          starttime = moment().subtract(6, "hour");
          break;
        }
        case "12h": {
          starttime = moment().subtract(12, "hour");
          break;
        }
        case "1d": {
          starttime = moment().subtract(1, "day");
          break;
        }
        case "2d": {
          starttime = moment().subtract(2, "day");
          break;
        }
        case "3d": {
          starttime = moment().subtract(3, "day");
          break;
        }
        case "5d": {
          starttime = moment().subtract(5, "day");
          break;
        }
        case "7d": {
          starttime = moment().subtract(7, "day");
          break;
        }
        case "30d": {
          starttime = moment().subtract(30, "day");
          break;
        }
      }
      const rs: ThunkResult<GetListPaginationModelLogQuery, any> =
        await dispatch<any>(
          getListPaginationModelLogThunkAction({
            keys: widget.data.fields?.join(","),
            limit: 10000,
            device_id: widget.data.device_id,
            model_id: widget.data.model_id,
            end_time: endtime.format(),
            start_time: starttime.format(),
          })
        );
      if (
        rs.payload &&
        rs.payload.getListPaginationModelLog &&
        rs.payload.getListPaginationModelLog.data
      ) {
        convertData(rs.payload.getListPaginationModelLog.data as ModelLog[]);
      } else {
        convertData([]);
      }
    }
  };

  const convertData = (list: ModelLog[]) => {
    const rs: DataModelItem[] = list.map((item) => {
      let time = item.time;
      try {
        time =moment(item.time).format("YYYY/MM/DD HH:mm:ss")
      } catch(e) {
        console.log(e);
      }
      return {
        key: item.key,
        time: time,
        value: item.value,
        value_n: item.value_n,
        device_id: item.device_id,
        model_id: item.model_id,
        source_input: item.source_input,
      };
    });

    rs.sort((a, b) => {
      return new Date(a.time).getTime() - new Date(b.time).getTime() 
    })

    setData({
      list: rs,
    });

    if (props.onData) {
      props.onData(rs);
    }
  };

  const Component = props.widgetComponent as any;

  return (
    <Component
      option={widget.option}
      data={data}
      height={props.height}
      width={props.width}
    />
  );
};

export default QueryDataWidget;

import React, { useEffect } from "react";
import { ThunkResult, useAppDispatch } from "src/app/hooks";
import { WidgetDetail } from "src/types";
import { GetWidgetByIdQuery } from "../graph/getWidgetById.generated";
import { getWidgetByIdThunkAction } from "../thunk";

const GetWidget: React.FC<{
  id: any;
  onSuccess?: (data: WidgetDetail) => void;
  children?: any;
  [key: string]: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const { id, children } = props;

  useEffect(() => {
    if (id) {
      const loadWidget = async () => {
        const rs: ThunkResult<GetWidgetByIdQuery, any> = await dispatch<any>(
          getWidgetByIdThunkAction(id)
        );
        if (rs.error) {
          console.log(rs.error);
        } else {
          if (props.onSuccess) {
            props.onSuccess(rs.payload.getWidgetById as WidgetDetail);
          }
        }
      };
      loadWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <>{children}</>;
};

export default GetWidget;

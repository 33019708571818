import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUiMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
  input: Types.UiInput;
}>;


export type UpdateUiMutation = { __typename?: 'Mutation', updateUi: boolean };


export const UpdateUiDocument = gql`
    mutation updateUi($id: Uint64!, $input: UiInput!) {
  updateUi(id: $id, input: $input)
}
    `;
export type UpdateUiMutationFn = Apollo.MutationFunction<UpdateUiMutation, UpdateUiMutationVariables>;

/**
 * __useUpdateUiMutation__
 *
 * To run a mutation, you first call `useUpdateUiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUiMutation, { data, loading, error }] = useUpdateUiMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUiMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUiMutation, UpdateUiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUiMutation, UpdateUiMutationVariables>(UpdateUiDocument, options);
      }
export type UpdateUiMutationHookResult = ReturnType<typeof useUpdateUiMutation>;
export type UpdateUiMutationResult = Apollo.MutationResult<UpdateUiMutation>;
export type UpdateUiMutationOptions = Apollo.BaseMutationOptions<UpdateUiMutation, UpdateUiMutationVariables>;
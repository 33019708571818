import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWidgetByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetWidgetByIdQuery = { __typename?: 'Query', getWidgetById: { __typename?: 'WidgetDetail', id: any, name: string, type: string, option: any, updated_at: any, created_at: any, data: { __typename?: 'WidgetData', source: string, type: string, model_id: any, namespace_id: any, device_id: any, time: string, window?: string | null, fields?: Array<string> | null, model?: { __typename?: 'Model', name: string } | null, namespace?: { __typename?: 'Namespace', name: string } | null, device?: { __typename?: 'Device', name: string } | null } } };


export const GetWidgetByIdDocument = gql`
    query getWidgetById($id: Uint64!) {
  getWidgetById(id: $id) {
    id
    name
    type
    data {
      source
      type
      model_id
      namespace_id
      device_id
      time
      window
      fields
      model {
        name
      }
      namespace {
        name
      }
      device {
        name
      }
    }
    option
    updated_at
    created_at
  }
}
    `;

/**
 * __useGetWidgetByIdQuery__
 *
 * To run a query within a React component, call `useGetWidgetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWidgetByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWidgetByIdQuery, GetWidgetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWidgetByIdQuery, GetWidgetByIdQueryVariables>(GetWidgetByIdDocument, options);
      }
export function useGetWidgetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWidgetByIdQuery, GetWidgetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWidgetByIdQuery, GetWidgetByIdQueryVariables>(GetWidgetByIdDocument, options);
        }
export type GetWidgetByIdQueryHookResult = ReturnType<typeof useGetWidgetByIdQuery>;
export type GetWidgetByIdLazyQueryHookResult = ReturnType<typeof useGetWidgetByIdLazyQuery>;
export type GetWidgetByIdQueryResult = Apollo.QueryResult<GetWidgetByIdQuery, GetWidgetByIdQueryVariables>;
import moment from 'moment'
import React from 'react'

export const Time: React.FC<{
    data: any;
    second?: boolean
}> = (props) => {
    const timeData = moment(props.data)
    if (props.second) {
        return (
            <>
            {timeData.format("YYYY/MM/DD HH:mm:ss")}
            </>
        )
    }
    return (
        <>
        {timeData.format("YYYY/MM/DD HH:mm")}
        </>
    )
}

export default Time;
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { ListPaginationUi, ListPaginationUiInput, UiDetail } from 'src/types'
import { deleteUiThunkAction, getListPaginationUiThunkAction, getSearchUiThunkAction, getUiByIdThunkAction, getUiHomeThunkAction } from './thunk'

export const UiSlice = createSlice({
    name: 'ui',
    initialState: {
        loading: false,
        message: "",
        list: {
            data: [] as any[]
        } as ListPaginationUi,
        search: {
            data: [] as any[]
        } as ListPaginationUi,
        detail: null as UiDetail | null,
        home: null as UiDetail | null,
        filter: {
            limit: 50,
            skip: 0,
        } as ListPaginationUiInput
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationUiInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(getListPaginationUiThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(getListPaginationUiThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationUiThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.list = action.payload.getListPaginationUi as ListPaginationUi
        })
        builder.addCase(getUiByIdThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(getUiByIdThunkAction.rejected, (state, action) => {
            state.loading = true
            state.message = action.error?.message as string
        })
        builder.addCase(getUiByIdThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.detail = action.payload.getUiById as UiDetail
        })
        builder.addCase(getUiHomeThunkAction.rejected, (state, action) => {
            state.loading = true
            state.message = action.error?.message as string
        })
        builder.addCase(getUiHomeThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.home = action.payload.getUiHome as UiDetail
        })
        builder.addCase(deleteUiThunkAction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteUiThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(deleteUiThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getSearchUiThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.search = action.payload.getListPaginationUi as ListPaginationUi
        })
    }
})

export default UiSlice.reducer
export const { setFilter } = UiSlice.actions


// select
export const selectUi = (state: RootState) => state.ui
export const selectLoading = createSelector(selectUi,(state) => state.loading )
export const selectMessage = createSelector(selectUi,(state) => state.message )
export const selectFilter = createSelector(selectUi,(state) => state.filter )
export const selectListUi = createSelector(selectUi,(state) => state.list )
export const selectListUiSearch = createSelector(selectUi,(state) => state.list.data )
export const selectDetail = createSelector(selectUi,(state) => state.detail )
export const selectHome = createSelector(selectUi,(state) => state.home )
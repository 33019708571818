import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRuleChanQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetRuleChanQuery = { __typename?: 'Query', getDetailRuleChan: { __typename?: 'DetailChan', chan: { __typename?: 'Chan', id: any, name: string, description?: string | null, user_id: any, status?: Types.ChanStatus | null, created_at: any, updated_at: any }, nodes?: Array<{ __typename?: 'RuleNode', id: any, name: string, chain_id: any, node_id: string, rule_id: string, connect_to?: Array<string | null> | null, infinite: boolean, option: any, debug: boolean, end: boolean, created_at: any, updated_at?: any | null } | null> | null } };


export const GetRuleChanDocument = gql`
    query getRuleChan($id: Uint64!) {
  getDetailRuleChan(id: $id) {
    chan {
      id
      name
      description
      user_id
      status
      created_at
      updated_at
    }
    nodes {
      id
      name
      chain_id
      node_id
      rule_id
      connect_to
      infinite
      option
      debug
      end
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useGetRuleChanQuery__
 *
 * To run a query within a React component, call `useGetRuleChanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRuleChanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRuleChanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRuleChanQuery(baseOptions: Apollo.QueryHookOptions<GetRuleChanQuery, GetRuleChanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRuleChanQuery, GetRuleChanQueryVariables>(GetRuleChanDocument, options);
      }
export function useGetRuleChanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRuleChanQuery, GetRuleChanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRuleChanQuery, GetRuleChanQueryVariables>(GetRuleChanDocument, options);
        }
export type GetRuleChanQueryHookResult = ReturnType<typeof useGetRuleChanQuery>;
export type GetRuleChanLazyQueryHookResult = ReturnType<typeof useGetRuleChanLazyQuery>;
export type GetRuleChanQueryResult = Apollo.QueryResult<GetRuleChanQuery, GetRuleChanQueryVariables>;
import { WidgetComponent } from "src/modules/widget/models/widget"
import Edit from "./edit-area";
import View from "./view-area";

const widget: WidgetComponent = {
    type:  "area",
    edit: Edit,
    icon: "area-chart",
    name: "Area chart",
    view: View,
    options: [],
}

export default widget
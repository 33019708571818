import { Alert, Button, Col, Form, Input, Row, Spin, Typography } from "antd";
import { useEffect } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { RuleOptionProps, RuleInfo } from "../rule";


const { Text, Title } = Typography;

const DelayRule: React.FC<NodeProps> = (props) => {
    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">

<Handle
                type="target"
                position={Position.Left}
                id="input"
                title="input"
                style={{ background: 'red' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
            <div>
                <Title level={5}>Delay</Title>
                <Text italic>{ props.data.label } </Text>
            </div>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                title="output"
                style={{  background: 'green' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}

export default DelayRule;



const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const DelayRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        let delay = 1
        if (props.data && props.data.delay) {
            delay = props.data.delay
        } 
        form.setFields([
            {
                name: "name",
                value: props.label,
            },
            {
                name: "delay",
                value: delay,
            },
          ])
    }, [props,form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {
                delay: values.delay,
            },
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
             <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="delay" label="Delay (s)" style={{ margin: 0 }}>
                    <Input type="number" size="large" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}



export const DelayRuleInfo: RuleInfo = {
    id: "delay",
    name: "Delay time",
    rule: DelayRule,
    option: DelayRuleOption,
    infinite: false,
    option_default: {
        delay: 1,
    },
    // tabs: [{
    //     tab_name: "Log",
    //     tab: LogTab
    // }]
}

import { Liquid, Datum } from '@ant-design/plots';
import { isUndefined } from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { WidgetData } from 'src/modules/widget/models/data';
import { WidgetOption } from 'src/modules/widget/models/widget';


const View: React.FC<{
  data: WidgetData
  option: WidgetOption
  height?: number
  width?: number
}> = (props) => {

  let {  data, option, height, width } = props
  if (height) {
    height = 300;
  }
  if (width) {
    width = 300;
  }


  const chartRef = useRef();
  
  const formatterValue = (v: Number, total: Number) => {
    
    let rs = ""

    var percent = v
    if (option["text"]) {
      rs = `${option["text"]} ${percent}`
    } else {
      rs = `${percent}`
    }

    if (option["unit"]) {
      rs = `${rs} ${option["unit"]}`
    }
    return rs;
  }

  const [dataChart,setDataChart] = useState<Datum>({
    list: [],
    target:  0 ,
    total:  100,
    name: 'score',
  })

  useEffect(() => {
    let target: Number = 0;
    if (!isUndefined(data.list) && data.list != null && data.list.length > 0) {
      const item = data.list[data.list.length - 1] as any;
      const val = item[option["data_val"]];
      if ( val && !isUndefined(val)) {
        target = parseFloat(`${val}`);
      }
    }

    const dataChart = {
      target: target,
      total:  100,
      name: 'score',
    }
    setDataChart(dataChart);
  }, [props, setDataChart, data.list, option])

  const style = {
    textContent: formatterValue,
  }

 const  scale = {
    color: {
      range: [option["color"]|| "green"],
    },
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ref = chartRef.current as any
      ref.changeSize(width, height)
    }
  }, [height, width])


  return (
    <Liquid scale={scale} style={style} autoFit={false} legend={false} data={dataChart}  padding='auto'/>
  );
}

export default View;
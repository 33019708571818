import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationDeviceQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListPaginationDeviceInput>;
}>;


export type GetListPaginationDeviceQuery = { __typename?: 'Query', getListPaginationDevice: { __typename?: 'ListPaginationDevice', limit: number, skip: number, total: number, data?: Array<{ __typename?: 'Device', id: any, key: string, name: string, is_gateway: boolean, is_create_device: boolean, namespace_id: any, created_at: any, updated_at: any, namespace?: { __typename?: 'Namespace', id: any, name: string } | null }> | null } };


export const GetListPaginationDeviceDocument = gql`
    query getListPaginationDevice($input: ListPaginationDeviceInput) {
  getListPaginationDevice(input: $input) {
    limit
    skip
    total
    data {
      id
      key
      name
      is_gateway
      is_create_device
      namespace_id
      namespace {
        id
        name
      }
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useGetListPaginationDeviceQuery__
 *
 * To run a query within a React component, call `useGetListPaginationDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationDeviceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationDeviceQuery(baseOptions?: Apollo.QueryHookOptions<GetListPaginationDeviceQuery, GetListPaginationDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationDeviceQuery, GetListPaginationDeviceQueryVariables>(GetListPaginationDeviceDocument, options);
      }
export function useGetListPaginationDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationDeviceQuery, GetListPaginationDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationDeviceQuery, GetListPaginationDeviceQueryVariables>(GetListPaginationDeviceDocument, options);
        }
export type GetListPaginationDeviceQueryHookResult = ReturnType<typeof useGetListPaginationDeviceQuery>;
export type GetListPaginationDeviceLazyQueryHookResult = ReturnType<typeof useGetListPaginationDeviceLazyQuery>;
export type GetListPaginationDeviceQueryResult = Apollo.QueryResult<GetListPaginationDeviceQuery, GetListPaginationDeviceQueryVariables>;
import { Alert as AAn, Button, Checkbox, Col, Form, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  RequestStatus,
  ThunkResult,
  useAppDispatch,
  useAppSelector,
} from "src/app/hooks";
import { Alert, InputAlert, Status, TypeCertificate } from "src/types";
import { selectDetail } from "../../slice";
import SelectModel from "src/modules/model/components/select-model";
import { createAlertThunkAction, getAlertByIdThunkAction, updateAlertThunkAction } from "../../thunk";
import EditListRule from "./edit-rule";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const EditAlert: React.FC<{
  data: Alert | null;
  isEdit: boolean;
  onClose: (loading: boolean) => void;
  onEdit: () => void;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, selectMessage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetail);
  const [rules, setRules] = useState<string[]>([])

  useEffect(() => {
    if (props.data == null) {
      form.setFields([
        {
          name: "status",
          value: Status.Active,
        },
        {
          name: "certificate_type",
          value: TypeCertificate.Certificate,
        },
      ]);
    } else {
      setLoading(true);

      dispatch<any>(getAlertByIdThunkAction(props.data.id))
      .then(() => {
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
      });

      form.setFieldsValue(props.data);
    }
  }, [props, form, dispatch]);

  useEffect(() => {
    if (props.data == null) {
      form.setFields([
        {
          name: "name",
          value: "",
        },
        {
          name: "description",
          value: "",
        },
        {
          name: "model_id",
          value: "",
        },
        {
          name: "enable",
          value: true,
        },
        {
          name: "message_template",
          value: "",
        },
      ]);
      setRules([])
      return;
    }
    if (detail != null) {
      form.setFieldsValue(detail);
      form.setFields([
        {
          name: "name",
          value: detail.name,
        },
        {
          name: "description",
          value: detail.description,
        },
        {
          name: "model_id",
          value: detail.model_id,
        },
        {
          name: "enable",
          value: detail.enable,
        },
        {
          name: "message_template",
          value: detail.message_template,
        },
      ]);
      setRules(detail.rules || [])
    }
  }, [detail, form, props]);

  const onChangeRule = function (rules: string[]): void {
    setRules(rules);
  }

  const onFinish = async (values: any) => {
    let res: ThunkResult<boolean, any>;
    try {
      let alertInput: InputAlert = {
        name: values.name,
        description: values.description,
        model_id: values.model_id,
        action: values.action || "",
        enable: values.enable,
        rules: rules,
        message_template: values.message_template,
      };

      if (props.data == null) {
        res = await dispatch<any>(createAlertThunkAction(alertInput));
      } else {
        res = await dispatch<any>(
          updateAlertThunkAction({ alertId: props.data.id, input: alertInput })
        );
      }
      setLoading(false);

      if (res.meta.requestStatus === RequestStatus.Fulfilled) {
        props.onClose(true);
      } else {
        selectMessage(res.error.message);
      }
      console.log(res);
    
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row gutter={[10, 10]}>
          {message !== "" && (
            <Col span={24}>
              <AAn type="error" message={message}></AAn>
            </Col>
          )}

          <Col span={24}>
            <Row justify="end" gutter={[10, 10]}>
              {props.isEdit && (
                <Col style={{ width: 100 }}>
                  <Input size="large" type="submit" value="Save" />
                </Col>
              )}
              {!props.isEdit && props.data != null && (
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onEdit();
                  }}
                >
                  Edit
                </Button>
              )}
              <Col>
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>


            </Row>
          </Col>

            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                style={{ margin: 0 }}
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input size="large" disabled={!props.isEdit} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                style={{ margin: 0 }}
              >
                <Input.TextArea size="large" disabled={!props.isEdit} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <SelectModel disabled={!props.isEdit} name={"model_id"}   rules={[{ required: true, message: "Please input model!" }]}></SelectModel>
            </Col>

            <Col span={24}>
              <Form.Item
                name="message_template"
                label="Message TEM"
                style={{ margin: 0 }}
                rules={[{ required: true, message: "Please input message template!" }]}
              >
                <Input.TextArea size="large" disabled={!props.isEdit} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="enable"
                label="Enable"
                valuePropName="checked"
                style={{ margin: 0 }}
              >
                <Checkbox disabled={!props.isEdit} ></Checkbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="action"
                label="Action Device"
                style={{ margin: 0 }}
              >
                <Input.TextArea size="large" disabled={!props.isEdit} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <EditListRule rules={rules} isEdit={props.isEdit} onChange={onChangeRule} ></EditListRule>
            </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default EditAlert;

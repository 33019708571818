import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RuleChanQueryVariables = Types.Exact<{
  input: Types.ListPaginationRuleChanInput;
}>;


export type RuleChanQuery = { __typename?: 'Query', getListPaginationRuleChan: { __typename?: 'ListChan', list?: Array<{ __typename?: 'Chan', id: any, name: string, description?: string | null, user_id: any, root_node?: string | null, status?: Types.ChanStatus | null, infinite?: boolean | null } | null> | null } };


export const RuleChanDocument = gql`
    query ruleChan($input: ListPaginationRuleChanInput!) {
  getListPaginationRuleChan(input: $input) {
    list {
      id
      name
      description
      user_id
      root_node
      status
      infinite
    }
  }
}
    `;

/**
 * __useRuleChanQuery__
 *
 * To run a query within a React component, call `useRuleChanQuery` and pass it any options that fit your needs.
 * When your component renders, `useRuleChanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRuleChanQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRuleChanQuery(baseOptions: Apollo.QueryHookOptions<RuleChanQuery, RuleChanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RuleChanQuery, RuleChanQueryVariables>(RuleChanDocument, options);
      }
export function useRuleChanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RuleChanQuery, RuleChanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RuleChanQuery, RuleChanQueryVariables>(RuleChanDocument, options);
        }
export type RuleChanQueryHookResult = ReturnType<typeof useRuleChanQuery>;
export type RuleChanLazyQueryHookResult = ReturnType<typeof useRuleChanLazyQuery>;
export type RuleChanQueryResult = Apollo.QueryResult<RuleChanQuery, RuleChanQueryVariables>;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GraphQLClient } from "src/app/apollo"
import { LastTimeModelInput, ListPaginationModelLogInput, TimeModelInput } from 'src/types'
import { GetListPaginationModelLogDocument, GetListPaginationModelLogQuery, GetListPaginationModelLogQueryVariables } from './graph/getListPaginationModelLog.generated'
import { GetModelLogByTimeDocument, GetModelLogByTimeQueryVariables, GetModelLogByTimeQuery } from './graph/getModelLogByTime.generated'
import { GetModelLogLastTimeDocument, GetModelLogLastTimeQueryVariables, GetModelLogLastTimeQuery } from './graph/getModelLogLastTime.generated'
import { GeListModeIdHaveLogByDeviceIdDocument, GeListModeIdHaveLogByDeviceIdQuery, GeListModeIdHaveLogByDeviceIdQueryVariables } from "./graph/geListModeIdHaveLogByDeviceId.generated";

export const getListPaginationModelLogThunkAction = createAsyncThunk<
    GetListPaginationModelLogQuery,
    ListPaginationModelLogInput,
    { extra: { client: GraphQLClient } }
>(
    'model_log/getListPaginationModelLogThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationModelLogQueryVariables, GetListPaginationModelLogQuery>(GetListPaginationModelLogDocument, { input: data })
        return res;
    }
)

export const getModelLogByTimeThunkAction = createAsyncThunk<
    GetModelLogByTimeQuery,
    TimeModelInput,
    { extra: { client: GraphQLClient } }
>(
    'model_log/getModelLogByTimeThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetModelLogByTimeQueryVariables, GetModelLogByTimeQuery>(GetModelLogByTimeDocument, { input: data })
        return res;
    }
)


export const getModelLogLastTimeThunkAction = createAsyncThunk<
    GetModelLogLastTimeQuery,
    LastTimeModelInput,
    { extra: { client: GraphQLClient } }
>(
    'model_log/getModelLogLastTimeThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetModelLogLastTimeQueryVariables, GetModelLogLastTimeQuery>(GetModelLogLastTimeDocument, { input: data })
        return res;
    }
)


export const geListModeIdHaveLogByDeviceIdThunkAction = createAsyncThunk<
    GeListModeIdHaveLogByDeviceIdQuery,
    GeListModeIdHaveLogByDeviceIdQueryVariables,
    { extra: { client: GraphQLClient } }
>(
    'model_log/geListModeIdHaveLogByDeviceIdThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GeListModeIdHaveLogByDeviceIdQueryVariables, GeListModeIdHaveLogByDeviceIdQuery>(GeListModeIdHaveLogByDeviceIdDocument, data )
        return res;
    }
)
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetDeviceByIdQuery = { __typename?: 'Query', getDeviceById: { __typename?: 'DeviceDetail', id: any, key: string, name: string, is_gateway: boolean, is_create_device: boolean, namespace_id: any, created_at: any, updated_at: any, description?: string | null, namespace?: { __typename?: 'Namespace', id: any, name: string } | null, certificate: { __typename?: 'DeviceCertificate', id: any, type: Types.TypeCertificate, username?: string | null, password?: string | null, token?: string | null, client_id?: string | null, gateway_id?: any | null } } };


export const GetDeviceByIdDocument = gql`
    query getDeviceById($id: Uint64!) {
  getDeviceById(id: $id) {
    id
    key
    name
    is_gateway
    is_create_device
    namespace_id
    namespace {
      id
      name
    }
    created_at
    updated_at
    description
    certificate {
      id
      type
      username
      password
      token
      client_id
      gateway_id
    }
  }
}
    `;

/**
 * __useGetDeviceByIdQuery__
 *
 * To run a query within a React component, call `useGetDeviceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>(GetDeviceByIdDocument, options);
      }
export function useGetDeviceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>(GetDeviceByIdDocument, options);
        }
export type GetDeviceByIdQueryHookResult = ReturnType<typeof useGetDeviceByIdQuery>;
export type GetDeviceByIdLazyQueryHookResult = ReturnType<typeof useGetDeviceByIdLazyQuery>;
export type GetDeviceByIdQueryResult = Apollo.QueryResult<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>;
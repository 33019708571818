import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetListPaginationModelLogQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ListPaginationModelLogInput>;
}>;


export type GetListPaginationModelLogQuery = { __typename?: 'Query', getListPaginationModelLog: { __typename?: 'ListPaginationModelLog', limit: number, data?: Array<{ __typename?: 'ModelLog', device_id: any, model_id: any, field_id: any, source_input: Types.SourceInput, key: string, value: string, value_n: number, is_number: boolean, time: any }> | null } };


export const GetListPaginationModelLogDocument = gql`
    query getListPaginationModelLog($input: ListPaginationModelLogInput) {
  getListPaginationModelLog(input: $input) {
    limit
    data {
      device_id
      model_id
      field_id
      source_input
      key
      value
      value_n
      is_number
      time
    }
  }
}
    `;

/**
 * __useGetListPaginationModelLogQuery__
 *
 * To run a query within a React component, call `useGetListPaginationModelLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPaginationModelLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPaginationModelLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPaginationModelLogQuery(baseOptions?: Apollo.QueryHookOptions<GetListPaginationModelLogQuery, GetListPaginationModelLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPaginationModelLogQuery, GetListPaginationModelLogQueryVariables>(GetListPaginationModelLogDocument, options);
      }
export function useGetListPaginationModelLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPaginationModelLogQuery, GetListPaginationModelLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPaginationModelLogQuery, GetListPaginationModelLogQueryVariables>(GetListPaginationModelLogDocument, options);
        }
export type GetListPaginationModelLogQueryHookResult = ReturnType<typeof useGetListPaginationModelLogQuery>;
export type GetListPaginationModelLogLazyQueryHookResult = ReturnType<typeof useGetListPaginationModelLogLazyQuery>;
export type GetListPaginationModelLogQueryResult = Apollo.QueryResult<GetListPaginationModelLogQuery, GetListPaginationModelLogQueryVariables>;
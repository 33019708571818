import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceModelByDeviceIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetDeviceModelByDeviceIdQuery = { __typename?: 'Query', getDeviceModelByDeviceId?: Array<{ __typename?: 'DeviceModelDetail', device_id: any, assign_id: any, model_id: any, status?: Types.StatusAssign | null, created_by?: any | null, updated_by?: any | null, updated_at: any, created_at: any, model?: { __typename?: 'ModelDetail', id: any, name: string, description?: string | null, status?: Types.StatusModel | null } | null, fields?: Array<{ __typename?: 'DeviceModelField', id?: any | null, key: string, field_id: any, description?: string | null, assign_id: any, source_input: Types.SourceInput }> | null }> | null };


export const GetDeviceModelByDeviceIdDocument = gql`
    query getDeviceModelByDeviceId($id: Uint64!) {
  getDeviceModelByDeviceId(id: $id) {
    device_id
    assign_id
    model_id
    status
    created_by
    updated_by
    updated_at
    created_at
    model {
      id
      name
      description
      status
    }
    fields {
      id
      key
      field_id
      description
      assign_id
      source_input
    }
  }
}
    `;

/**
 * __useGetDeviceModelByDeviceIdQuery__
 *
 * To run a query within a React component, call `useGetDeviceModelByDeviceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceModelByDeviceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceModelByDeviceIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceModelByDeviceIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceModelByDeviceIdQuery, GetDeviceModelByDeviceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceModelByDeviceIdQuery, GetDeviceModelByDeviceIdQueryVariables>(GetDeviceModelByDeviceIdDocument, options);
      }
export function useGetDeviceModelByDeviceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceModelByDeviceIdQuery, GetDeviceModelByDeviceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceModelByDeviceIdQuery, GetDeviceModelByDeviceIdQueryVariables>(GetDeviceModelByDeviceIdDocument, options);
        }
export type GetDeviceModelByDeviceIdQueryHookResult = ReturnType<typeof useGetDeviceModelByDeviceIdQuery>;
export type GetDeviceModelByDeviceIdLazyQueryHookResult = ReturnType<typeof useGetDeviceModelByDeviceIdLazyQuery>;
export type GetDeviceModelByDeviceIdQueryResult = Apollo.QueryResult<GetDeviceModelByDeviceIdQuery, GetDeviceModelByDeviceIdQueryVariables>;
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/app/store'
import { ListPaginationModel, ListPaginationModelInput, Model, ModelDetail } from 'src/types'
import { deleteModelThunkAction, getListPaginationModelThunkAction, getModelByIdThunkAction, getSearchModelThunkAction } from './thunk'

export const ModelSlice = createSlice({
    name: 'model',
    initialState: {
        loading: false,
        message: "",
        list: {
            data: [] as any[]
        } as ListPaginationModel,
        detail: null as ModelDetail | null,
        filter: {
            limit: 50,
            skip: 0,
        } as ListPaginationModelInput,
        dataSearch: [] as Model[],
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationModelInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(getListPaginationModelThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
        })
        builder.addCase(getListPaginationModelThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationModelThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            state.list = action.payload.getListPaginationModel as ListPaginationModel
        })
        builder.addCase(deleteModelThunkAction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteModelThunkAction.pending, (state, action) => {
            state.loading = true
            state.message = ""
        })
        builder.addCase(deleteModelThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = action.error.message as string
        })
        builder.addCase(getSearchModelThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            if (action.payload.getListPaginationModel && action.payload.getListPaginationModel.data) {
                state.dataSearch = action.payload.getListPaginationModel.data
            } else {
                state.dataSearch = []
            }
        })
        builder.addCase(getModelByIdThunkAction.fulfilled, (state, action) => {
            state.message = ""
            state.detail = action.payload.getModelById as ModelDetail
        })
        builder.addCase(getModelByIdThunkAction.pending, (state, action) => {
            state.message = ""
            state.detail = null
        })
    }
})

export default ModelSlice.reducer
export const { setFilter } = ModelSlice.actions


// select
export const selectModel = (state: RootState) => state.model
export const selectLoading = createSelector(selectModel, (state) => state.loading)
export const selectMessage = createSelector(selectModel, (state) => state.message)
export const selectFilter = createSelector(selectModel, (state) => state.filter)
export const selectListModel = createSelector(selectModel, (state) => state.list)
export const selectListModelSearch = createSelector(selectModel, (state) => state.dataSearch)
export const selectDetail = createSelector(selectModel, (state) => state.detail)
import { WidgetComponent } from "src/modules/widget/models/widget";
import EditTable  from "./edit-table";
import ViewTable  from "./view-table";

const wiget: WidgetComponent = {
    type: "table",
    edit: EditTable,
    icon: "table-chart",
    name: "Table Chart",
    view: ViewTable,
    options: [],
}

export default wiget;
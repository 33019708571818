import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceModelByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
}>;


export type GetDeviceModelByIdQuery = { __typename?: 'Query', getDeviceModelById: { __typename?: 'DeviceModelDetail', device_id: any, assign_id: any, model_id: any, status?: Types.StatusAssign | null, created_by?: any | null, updated_by?: any | null, updated_at: any, created_at: any, model?: { __typename?: 'ModelDetail', id: any, name: string, description?: string | null, status?: Types.StatusModel | null, fields?: Array<{ __typename?: 'ModelField', id: any, key: string, type: Types.TypeField, field_type_id?: any | null, field_type?: { __typename?: 'FieldType', name: string, type: Types.TypeField, is_public: boolean } | null }> | null } | null, fields?: Array<{ __typename?: 'DeviceModelField', id?: any | null, key: string, field_id: any, description?: string | null, assign_id: any, source_input: Types.SourceInput }> | null } };


export const GetDeviceModelByIdDocument = gql`
    query getDeviceModelById($id: Uint64!) {
  getDeviceModelById(id: $id) {
    device_id
    assign_id
    model_id
    status
    created_by
    updated_by
    updated_at
    created_at
    model {
      id
      name
      description
      status
      fields {
        id
        key
        type
        field_type_id
        field_type {
          name
          type
          is_public
        }
      }
    }
    fields {
      id
      key
      field_id
      description
      assign_id
      source_input
    }
  }
}
    `;

/**
 * __useGetDeviceModelByIdQuery__
 *
 * To run a query within a React component, call `useGetDeviceModelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceModelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceModelByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceModelByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceModelByIdQuery, GetDeviceModelByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceModelByIdQuery, GetDeviceModelByIdQueryVariables>(GetDeviceModelByIdDocument, options);
      }
export function useGetDeviceModelByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceModelByIdQuery, GetDeviceModelByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceModelByIdQuery, GetDeviceModelByIdQueryVariables>(GetDeviceModelByIdDocument, options);
        }
export type GetDeviceModelByIdQueryHookResult = ReturnType<typeof useGetDeviceModelByIdQuery>;
export type GetDeviceModelByIdLazyQueryHookResult = ReturnType<typeof useGetDeviceModelByIdLazyQuery>;
export type GetDeviceModelByIdQueryResult = Apollo.QueryResult<GetDeviceModelByIdQuery, GetDeviceModelByIdQueryVariables>;
import "./header.css";

const Header: React.FC<{ extra: any }> = (props) => {
  const { extra } = props;
  return (
    <div className="header-form" >
      <div><h3>Query Data</h3></div>
      <div style={{ display: "flex" }}>{extra}</div>
    </div>
  );
};

export default Header;

import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUiMutationVariables = Types.Exact<{
  input: Types.UiInput;
}>;


export type CreateUiMutation = { __typename?: 'Mutation', createUi: boolean };


export const CreateUiDocument = gql`
    mutation createUi($input: UiInput!) {
  createUi(input: $input)
}
    `;
export type CreateUiMutationFn = Apollo.MutationFunction<CreateUiMutation, CreateUiMutationVariables>;

/**
 * __useCreateUiMutation__
 *
 * To run a mutation, you first call `useCreateUiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUiMutation, { data, loading, error }] = useCreateUiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUiMutation(baseOptions?: Apollo.MutationHookOptions<CreateUiMutation, CreateUiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUiMutation, CreateUiMutationVariables>(CreateUiDocument, options);
      }
export type CreateUiMutationHookResult = ReturnType<typeof useCreateUiMutation>;
export type CreateUiMutationResult = Apollo.MutationResult<CreateUiMutation>;
export type CreateUiMutationOptions = Apollo.BaseMutationOptions<CreateUiMutation, CreateUiMutationVariables>;
import { Button, Card, Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import Logo from '../logo';
import { LogoutOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { logoutThunkAction } from 'src/modules/login/login.thunk';
import { DeviceType } from 'src/types';
import { selectDeviceId } from 'src/modules/login/login.slice';


type PStore = {
    name: string
    icon?: React.ReactElement
    title: string
    onClick: (name: string) => void
}

export const ButtonStore: React.FC<PStore> = (props: PStore) => {

    return (
        <Button type='default' style={{ height: "auto", width: "100%", borderRadius: 10 }} onClick={() => { props.onClick(props.name) }}>
            <Row>
                <Col span={24}>
                    {
                        props.icon && (
                            <>
                                {props.icon}
                            </>
                        )
                    }
                    {
                        !props.icon && (
                            <Logo style={{ height: 50, width: 50 }} />
                        )
                    }
                </Col>
                <Col span={24}>
                    <Title level={5}>{props.title}</Title>
                </Col>
            </Row>
        </Button>
    )
}

type P = {
    close?: () => void
}

const MenuAppStore: React.FC<P> = (props: P) => {
    const dispatch = useAppDispatch()
    const deviceId = useAppSelector(selectDeviceId);


    const handleClick = (name: string) => {
        if (name === "home") {
            window.open("https://overchall.com", "_blank")
        }
        

        if (props.close) {
            props.close()
        }
        console.log(name)
    }

    const handleLogout = () => {
        dispatch<any>(logoutThunkAction({
            deviceId: deviceId,
            deviceType: DeviceType.Web
        }))
        if (props.close) {
            props.close()
        }
    }

    return (
        <Card style={{ width: 400 }}>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <Divider orientation='center'>Application</Divider>
                </Col>
                <Col span={8}>
                    <ButtonStore name='hub' onClick={handleClick} icon={<Logo style={{ height: 50, width: 50 }} />} title="O-Hub" />
                </Col>
                <Col span={8}>
                    <ButtonStore name='home' onClick={handleClick} icon={
                        <h1 className='icon-text'>H</h1>
                    } title="Home" />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Button onClick={handleLogout} type='primary' size='large' style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <LogoutOutlined />
                        Logout
                    </Button>
                </Col>
            </Row>
        </Card>
    )
}

export default MenuAppStore;
import { WidgetComponent } from "src/modules/widget/models/widget"
import  Edit from "./edit-pie"
import View from "./view-pie"

const wiget: WidgetComponent = {
    type: "pie",
    edit: Edit,
    icon: "pie-chart",
    name: "Pie Chart",
    options: [],
    view: View
}

export default wiget
import { Breadcrumb, Button, Card, Col, PageHeader, Row } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
// import EditUi from "src/modules/ui/components/edit";
import FilterUi from "src/modules/ui/components/filter";
import ListUi from "src/modules/ui/components/list";
import { deleteUiThunkAction } from "src/modules/ui/thunk";
import { Ui } from "src/types";

const ListUiPage: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const onAction = async (action: string, row: Ui) => {
    switch (action) {
      case "detail": {
        nav(`/ui/${row.id}`);
        return;
      }
      case "edit": {
        nav(`/ui/${row.id}/edit`);
        return;
      }
      case "delete": {
        await dispatch<any>(deleteUiThunkAction(row.id));
        callbackLoadData();
        return;
      }
    }
  };

  const onWidget = () => {
    nav("/ui/widgets");
  };

  const newUi = () => {
    nav("/ui/new");
  };

  let callbackLoadData = () => {};

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - List Ui</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Ui</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Card size={"small"}>
            <PageHeader
              className="page-header-responsive"
              title="Ui"
              extra={
                <>
                  <FilterUi />
                  <Button type="primary" onClick={newUi}>
                    Add Ui
                  </Button>
                  <Button type="primary" onClick={onWidget}>
                    Widget
                  </Button>
                </>
              }
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>
          <ListUi
            onAction={onAction}
            refLoading={(callback) => {
              callbackLoadData = callback;
            }}
          />
        </Col>
        {/* <Modal
          visible={isModal}
          title={
            !isEdit
              ? "Detail Ui"
              : add
              ? "Add Ui"
              : "Edit Ui"
          }
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
          <EditUi
            data={item}
            isEdit={isEdit}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                callbackLoadData();
              }
            }}
            onEdit={() => {
              setAdd(false);
              setIsEdit(true);
            }}
          />
        </Modal> */}
      </Row>
    </div>
  );
};

export default ListUiPage;

import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePropertyDeviceMutationVariables = Types.Exact<{
  input: Types.PropertyDeviceInput;
}>;


export type CreatePropertyDeviceMutation = { __typename?: 'Mutation', createPropertyDevice: boolean };


export const CreatePropertyDeviceDocument = gql`
    mutation createPropertyDevice($input: PropertyDeviceInput!) {
  createPropertyDevice(input: $input)
}
    `;
export type CreatePropertyDeviceMutationFn = Apollo.MutationFunction<CreatePropertyDeviceMutation, CreatePropertyDeviceMutationVariables>;

/**
 * __useCreatePropertyDeviceMutation__
 *
 * To run a mutation, you first call `useCreatePropertyDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyDeviceMutation, { data, loading, error }] = useCreatePropertyDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePropertyDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyDeviceMutation, CreatePropertyDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyDeviceMutation, CreatePropertyDeviceMutationVariables>(CreatePropertyDeviceDocument, options);
      }
export type CreatePropertyDeviceMutationHookResult = ReturnType<typeof useCreatePropertyDeviceMutation>;
export type CreatePropertyDeviceMutationResult = Apollo.MutationResult<CreatePropertyDeviceMutation>;
export type CreatePropertyDeviceMutationOptions = Apollo.BaseMutationOptions<CreatePropertyDeviceMutation, CreatePropertyDeviceMutationVariables>;
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRuleChanNodesMutationVariables = Types.Exact<{
  chanId: Types.Scalars['Uint64'];
  inputs?: Types.InputMaybe<Array<Types.InputRuleNode> | Types.InputRuleNode>;
}>;


export type UpdateRuleChanNodesMutation = { __typename?: 'Mutation', updateRuleNodes: boolean };


export const UpdateRuleChanNodesDocument = gql`
    mutation updateRuleChanNodes($chanId: Uint64!, $inputs: [InputRuleNode!]) {
  updateRuleNodes(chanId: $chanId, inputs: $inputs)
}
    `;
export type UpdateRuleChanNodesMutationFn = Apollo.MutationFunction<UpdateRuleChanNodesMutation, UpdateRuleChanNodesMutationVariables>;

/**
 * __useUpdateRuleChanNodesMutation__
 *
 * To run a mutation, you first call `useUpdateRuleChanNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRuleChanNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuleChanNodesMutation, { data, loading, error }] = useUpdateRuleChanNodesMutation({
 *   variables: {
 *      chanId: // value for 'chanId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateRuleChanNodesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRuleChanNodesMutation, UpdateRuleChanNodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRuleChanNodesMutation, UpdateRuleChanNodesMutationVariables>(UpdateRuleChanNodesDocument, options);
      }
export type UpdateRuleChanNodesMutationHookResult = ReturnType<typeof useUpdateRuleChanNodesMutation>;
export type UpdateRuleChanNodesMutationResult = Apollo.MutationResult<UpdateRuleChanNodesMutation>;
export type UpdateRuleChanNodesMutationOptions = Apollo.BaseMutationOptions<UpdateRuleChanNodesMutation, UpdateRuleChanNodesMutationVariables>;
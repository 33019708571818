import { Breadcrumb, Button, Card, Col, Modal, PageHeader, Row } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import EditNamespace from "src/modules/namespace/components/edit";
import FilterNamespace from "src/modules/namespace/components/filter";
import ListNamespace from "src/modules/namespace/components/list";
import { deleteNamespaceThunkAction } from "src/modules/namespace/thunk";
import { Namespace } from "src/types";

const ListNamespacePage: React.FC<{}> = (props) => {
  const [item, setSelectItem] = useState<Namespace | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [add, setAdd] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onAction = async (action: string, row: Namespace) => {
    setSelectItem(row);
    switch (action) {
      case "detail": {
        setIsEdit(false);
        setIsModal(true);
        return;
      }
      case "edit": {
        setAdd(false);
        setIsEdit(true);
        setIsModal(true);
        return;
      }
      case "delete": {
        await dispatch<any>(deleteNamespaceThunkAction(row.id));
        callbackLoadData();
        console.log("del namespace", item);
        return;
      }
    }
  };

  const onAdd = () => {
    setAdd(true);
    setIsEdit(true);
    setSelectItem(null);
    setIsModal(true);
  };

  let callbackLoadData = () => {};

  return (
    <div className="session-main">
      <Helmet>
        <title>Dipper - List Namespace</title>
      </Helmet>
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ paddingLeft: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Namespace</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Card size={"small"}>
            <PageHeader
              className="page-header-responsive"
              title="Namespace"
              extra={
                <>
                  <FilterNamespace />
                  <Button type="primary" onClick={onAdd}>
                    Add Namespace
                  </Button>
                </>
              }
            ></PageHeader>
          </Card>
        </Col>
        <Col span={24}>
          <ListNamespace
            onAction={onAction}
            refLoading={(callback) => {
              callbackLoadData = callback;
            }}
          />
        </Col>
        <Modal
          visible={isModal}
          title={
            !isEdit
              ? "Detail Namespace"
              : add
              ? "Add Namespace"
              : "Edit Namespace"
          }
          maskClosable={false}
          onCancel={() => {
            setIsModal(false);
          }}
          footer={null}
          closable={false}
        >
          <EditNamespace
            data={item}
            isEdit={isEdit}
            onClose={(loading) => {
              setIsModal(false);
              if (loading) {
                callbackLoadData();
              }
            }}
            onEdit={() => {
              setAdd(false);
              setIsEdit(true);
            }}
          />
        </Modal>
      </Row>
    </div>
  );
};

export default ListNamespacePage;

import { Col, Form, Input, Row, Switch } from "antd";
import React, { useState } from "react";
import SelectNamespace from "src/modules/namespace/components/select-namespace";

const EditDeviceGeneral: React.FC<{
  isEdit: boolean;
  namespaceId?: string
}> = (props) => {
  const [isGateway, setIsGateway] = useState(false);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Form.Item
          name="name"
          label="Name"
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <Input size="large" disabled={!props.isEdit} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="key"
          label="Key"
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please input key!" }]}
        >
          <Input size="large" disabled={!props.isEdit} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="description"
          label="Description"
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please input description!" }]}
        >
          <Input.TextArea size="large" disabled={!props.isEdit} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <SelectNamespace
          name="namespace_id"
          label="Namespace"
          disabled={!props.isEdit}
          value={props.namespaceId}
          rules={[{ required: true, message: "Please select namespace!" }]}
        />
      </Col>

      <Col span={24}>
        <Form.Item name={"is_gateway"} label="Is gateway" style={{ margin: 0 }}>
          <Switch  disabled={!props.isEdit} onChange={(val) => setIsGateway(val)}></Switch>
        </Form.Item>
      </Col>
      {isGateway && (
        <Col span={24}>
          <Form.Item
            name={"is_create_device"}
            label="Is Create Device"
            style={{ margin: 0 }}
          >
            <Switch  disabled={!props.isEdit}></Switch>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default EditDeviceGeneral;

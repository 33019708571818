import { Spin, Table } from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import { AlertLog } from "src/types";
import { selectFilterLog, selectListAlertLog } from "../slice";
import { listPaginationAlertLogThunkAction } from "../thunk";
import FilterLog from "./filte-log";

const ListAlertLog: React.FC<{
  alertId?:  string;
  deviceId?:  string;
  showDevice?: boolean;
  showAlert?: boolean;
  showmModel?: boolean;
  filterOption?: {
    filterDevice?: boolean
    filterModel?: boolean
    filterAlert?: boolean
  }
}> = (props) => {
  const [loading, ] = useState(false);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilterLog);
  const listAlertLog = useAppSelector(selectListAlertLog);

  useEffect(() => {
    console.log("listPaginationAlertLogThunkAction ", filter)
    dispatch<any>(listPaginationAlertLogThunkAction(filter));
  }, [dispatch, filter]);


  return (
    <Spin tip="Loading..." spinning={loading}>
      <FilterLog { ...props.filterOption } />
      <Table dataSource={listAlertLog?.data || []} size="middle" rowKey={"key"} pagination={false}>
        <Column
          width={200}
          title="Time"
          dataIndex="time"
          key="time"
          render={(text: any, record: AlertLog) => (
            <Time second={true} data={record.ts} />
          )}
        />
        <Column
          title="Message"
          dataIndex="message"
          key="message"
        />
        {props.showAlert && (
          <Column
            width={120}
            title="Alert"
            dataIndex="alert"
            key="alert"
            render={(value: any, row: AlertLog) => {
              if (!row.alert) {
                return "--";
              }
              return row.alert?.name;
            }}
          />
        )}
        {props.showDevice && (
          <Column
            width={120}
            title="Device"
            dataIndex="device"
            key="device"
            render={(text: any, record: AlertLog) => {
              if (!record.device) {
                return "--";
              }

              return record.device.name;
            }}
          />
        )}
        {props.showmModel && (
          <Column
            width={100}
            title="Model"
            dataIndex="model"
            key="model"
            render={(text: any, record: AlertLog) => {
              if (!record.model) {
                return "--";
              }
              return record.model.name;
            }}
          />
        )}
      
      </Table>
    </Spin>
  );
};

export default ListAlertLog;

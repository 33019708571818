import { createAsyncThunk } from "@reduxjs/toolkit"
import { GraphQLClient } from "src/app/apollo"
import { InputAlert, ListPaginationAlertInput, ListPaginationAlertLogInput } from "src/types"
import { CreateAlertDocument, CreateAlertMutation, CreateAlertMutationVariables } from "./graph/createAlert.generated"
import { DeleteAlertDocument, DeleteAlertMutation, DeleteAlertMutationVariables } from "./graph/deleteAlert.generated"
import { GetListPaginationAlertDocument, GetListPaginationAlertQuery, GetListPaginationAlertQueryVariables } from './graph/getListPaginationAlert.generated'
import { UpdateAlertDocument, UpdateAlertMutation, UpdateAlertMutationVariables } from "./graph/updateAlert.generated"
import { GetAlertDocument, GetAlertQuery, GetAlertQueryVariables } from "./graph/getAlert.generated"
import { GetListPaginationAlertLogDocument, GetListPaginationAlertLogQuery, GetListPaginationAlertLogQueryVariables } from "./graph/getListPaginationAlertLog.generated"

export const listPaginationAlertThunkAction = createAsyncThunk<
    GetListPaginationAlertQuery,
    ListPaginationAlertInput,
    { extra: { client: GraphQLClient } }
>(
    'alert/listPaginationAlertThunk',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationAlertQueryVariables, GetListPaginationAlertQuery>(GetListPaginationAlertDocument, { input: data })
        return res
    }
)

export const listPaginationAlertLogThunkAction = createAsyncThunk<
    GetListPaginationAlertLogQuery,
    ListPaginationAlertLogInput,
    { extra: { client: GraphQLClient } }
>(
    'alert/listPaginationAlertLogThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationAlertLogQueryVariables, GetListPaginationAlertLogQuery>(GetListPaginationAlertLogDocument, { input: data })
        return res
    }
)


export const getSearchAlertThunkAction = createAsyncThunk<
    GetListPaginationAlertQuery,
    ListPaginationAlertInput,
    { extra: { client: GraphQLClient } }
>(
    'alert/getSearchAlertThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<GetListPaginationAlertQueryVariables, GetListPaginationAlertQuery>(GetListPaginationAlertDocument, { input: data })
        return res
    }
)

export const getAlertByIdThunkAction = createAsyncThunk<
    GetAlertQuery,
    string,
    { extra: { client: GraphQLClient } }
>(
    'alert/getAlertByIdThunkAction',
    async (id, { extra }) => {
        const res = await extra.client.mutate<GetAlertQueryVariables, GetAlertQuery>(GetAlertDocument, { input: id })
        return res;
    }
)


export const createAlertThunkAction = createAsyncThunk<
    CreateAlertMutation,
    InputAlert,
    { extra: { client: GraphQLClient } }
>(
    'alert/createAlertThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<CreateAlertMutationVariables, CreateAlertMutation>(CreateAlertDocument, { input: data })
        return res;
    }
)

export const updateAlertThunkAction = createAsyncThunk<
    UpdateAlertMutation,
    UpdateAlertMutationVariables,
    { extra: { client: GraphQLClient } }
>(
    'alert/updateAlertThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<UpdateAlertMutationVariables, UpdateAlertMutation>(UpdateAlertDocument, data)
        return res;
    }
)

export const deleteAlertThunkAction = createAsyncThunk<
    DeleteAlertMutation,
    string,
    { extra: { client: GraphQLClient } }
>(
    'alert/deleteAlertThunkAction',
    async (data, { extra }) => {
        const res = await extra.client.mutate<DeleteAlertMutationVariables, DeleteAlertMutation>(DeleteAlertDocument, { alertId : data })
        return res;
    }
)


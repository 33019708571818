import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginRouter from "./features/login/login.router";
import HomeRouter from "./features/home/home.router";
import DeviceRouter from "./features/device/device.router";
import NamespaceRouter from "./features/namespace/namespace.router";
import { useAppDispatch } from "./app/hooks";
import { verifyThunkAction } from "./modules/login/login.thunk";
import Loading from "./components/loading";
import { ConfigProvider } from 'antd';
import ModelRouter from "./features/model/model.router";
import UiRouter from "./features/ui/ui.router";
import RuleChanRouter from "./features/rule_chan/rule-chan.router";
import { loadDeviecId } from "./modules/login/login.slice";
import AlerRouter from "./features/alert/alert.router";
import ConnectorRouter from "./features/connector/connector.router";
import StorageRouter from "./features/storage/storage.router";

ConfigProvider.config({
  prefixCls: 'custom',
  theme: {
  }
});

function App() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch<any>(loadDeviecId(null))
    dispatch<any>(verifyThunkAction({})).finally(() => {
      setLoading(false)
    })
  }, [dispatch])


  if (loading) {
    return (
      <div className="app-loading" >
        <Loading size={1.5} />
      </div>
    )
  }

  return (
    <ConfigProvider>
      <Router>
        <Routes>
          <Route path="/connector/*" element={<ConnectorRouter />} />
          <Route path="/storage/*" element={<StorageRouter />} />
          <Route path="/alert/*" element={<AlerRouter />} />
          <Route path="/namespace/*" element={<NamespaceRouter />} />
          <Route path="/ui/*" element={<UiRouter />} />
          <Route path="/rule_chan/*" element={<RuleChanRouter />} />
          <Route path="/model/*" element={<ModelRouter />} />
          <Route path="/device/*" element={<DeviceRouter />} />
          <Route path="/*" element={<HomeRouter />} />
          <Route path="/login" element={<LoginRouter />} />
          <Route path="*" element={<HomeRouter />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;

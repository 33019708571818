import { Breadcrumb, Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDetail } from "src/modules/ui/slice";
import GridLayoutEdit from "src/modules/ui/components/grid-layout-edit";
import { getUiByIdThunkAction } from "src/modules/ui/thunk";

const EditUiPage: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const detail = useAppSelector(selectDetail);
  const nav = useNavigate();

  let { id } = useParams<{ id: string }>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (id) {
      dispatch<any>(getUiByIdThunkAction(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="session-main">
      <Spin spinning={detail == null}>
        {detail && (
          <Helmet>
            <title>Dipper - Edit Ui - {detail?.name}</title>
          </Helmet>
        )}
        <Row gutter={[0, 10]}>
          <Col span={24} style={{ paddingLeft: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/ui"}>Ui</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{detail?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24}>
            {detail && (
              <GridLayoutEdit
                detail={detail}
                onBack={() => {
                  nav("/ui");
                }}
                onSuccess={() => {
                  nav("/ui");
                }}
              />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default EditUiPage;

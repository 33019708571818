import React from 'react'

interface P {
    size: number
}

const Loading: React.FC<P> = (props: P) => {

    const sizeSvg = `${props.size * 55}pt`
    const sizeDiv = `${props.size * 80}px`

    return (
        <div className="lds-dual-ring" style={{ height: sizeDiv, width: sizeDiv }}>
        <svg className="logo" viewBox="0 0 200 200"
        shape-rendering="geometricPrecision" text-rendering="geometricPrecision"   style={{ height: sizeSvg, width: sizeSvg }}>
             <g transform="matrix(.3 0 0 0.3-5.371512-1.558359)">
                <g transform="translate(.000001 0.000001)">
                    <g transform="matrix(.05 0 0-.05 82.22034 525.72469)">
                        <path
                            d="M4970,5056l-385-31-27-104c-76-295-118-613-125-959-9-437,29-754,136-1146l29-108-31,6c-18,4-141,59-274,122-133,64-245,114-250,111s-23-25-40-50l-32-44l41-39c22-21,85-88,141-149c55-60,172-185,258-276c87-92,155-170,151-174-4-3,0-5,8-3c8,1,14-2,13-9-3-13,220-227,342-328c229-190,585-369,698-351c82,13,97,35,198,287c104,262,235,571,366,864c156,347,167,405,76,405-39,0-97-50-213-183-36-42-66-74-68-72-3,3,79,192,157,359c28,59,51,112,51,116c0,8-100,74-104,68-1-2-65-77-143-168-139-163-248-275-310-318-29-20-32-21-31-5c0,10-8,81-18,158-14,109-18,202-18,425c1,361,21,593,95,1083c27,178,30,214,20,248-17,55-59,81-129,77-61-4-60-8-37,122c8,47,15,88,15,93c0,13-166,5-560-27Z"
                            transform="translate(-121.1265 3216.902025)"/>
                        <path
                            d="M7215,4882c-157-402-377-914-540-1256-36-77-64-145-61-151c2-7,25-24,51-40c39-22,49-25,59-14c7,8,54,62,105,122c92,106,125,137,78,75-35-47-89-176-95-226-3-32,0-47,13-62c43-48,85-21,241,151c129,142,225,232,264,244c11,3,20,11,20,18s3,8,10,1c13-13,37-160,51-315c16-188,6-650-20-899-24-221-61-490-97-693-13-76-24-142-24-148c0-8,29-9,96-4c93,7,95,6,83-12-17-27-3-90,25-118c14-14,41-26,67-30c50-7,664,40,721,56c44,11,93,50,114,90c19,37,57,208,84,370c85,528,77,1002-25,1504-19,93-35,185-35,205c0,99,68,106,240,26c64-30,135-59,159-65c110-24,139,54,57,154-19,23-33,45-29,48c6,7-464,504-597,630-257,245-502,400-766,486-66,21-131,41-146,45-26,6-27,4-103-192Z"
                            transform="translate(-1566.55024-2302.27301)"/>
                        <path
                            d="M1050,4357c-63-62-204-197-313-301-341-325-512-547-627-813-37-86-105-301-98-309c2-2,49-22,103-44c96-40,132-60,108-60-12,0-3-52,15-87c18-33,61-55,281-143c342-136,654-269,961-410c159-72,223-91,263-78c57,21,43,83-37,156-41,37-45,45-35,63c6,12,7,25,2,29-4,4-60,51-123,105s-138,120-166,147l-52,50l15,43c18,53,41,69,135,94c168,46,291,56,678,56c408,0,525-9,955-73c138-20,283-37,323-37c68,0,76,2,97,27c13,15,26,44,30,66c8,52-42,664-60,729-17,58-55,96-114,114-37,11-39,14-45,63-3,28-6,65-6,82v31l-112,27c-171,40-347,72-534,97-147,20-213,23-504,24-357,0-417-5-719-56-96-16-176-27-179-24s0,5,7,5c19,0,74,127,79,182c8,87-47,114-126,61-47-32-131-110-149-139-18-28,25,71,97,220c33,70,59,133,57,140-3,6-24,25-49,43l-44,31-114-111Z"
                            transform="translate(6404.4583 660.93134)"/>
                        <path
                            d="M11388,4426c-26-17-48-34-48-39c0-4,23-57,50-117c53-115,56-125,40-115-6,4-10-11-10-37c0-30,15-74,49-148c28-58,46-106,41-108-4-2-52,8-106,22-534,135-1061,158-1629,71-195-30-486-93-501-109-9-8-85-853-84-933c0-7,10-13,23-13c20,0,23-4,19-29-2-16,5-49,16-74c33-74,56-77,309-38c498,75,723,94,1093,94c303,0,454-12,622-50c40-9,83-16,95-17c18-1,19-4,10-21-17-32-173-181-320-305l-136-114l20-36c11-19,26-43,33-53c15-19,12-20,121,27c33,14,51,20,40,13-39-24-102-95-109-122-5-18-1-35,10-52c29-45,76-31,449,134c354,157,592,257,826,348c239,93,269,117,269,211c0,31-4,64-10,74-7,13-6,19,5,24c23,8,19,34-25,168-88,268-219,481-459,743-76,83-249,250-541,524l-115,108-47-31Z"
                            transform="translate(-8325.73179 786.76928)"/>
                    </g>
                    <ellipse rx="300" ry="300" transform="translate(351.238372 338.527868)" fill="#d2dbed"
                                fill-opacity="0" stroke="#f00" stroke-width="10"/>
                </g>
                <polygon
                    points="12.827735,-30.323048 25.703442,-2.891413 54.494401,1.507453 33.661068,22.860003 38.57915,53.010286 12.827735,38.775254 -12.923681,53.010286 -8.005599,22.860003 -28.838932,1.507453 -0.047973,-2.891413 12.827735,-30.323048"
                    transform="matrix(-.74954 0.661959-.661959-.74954 374.305946 344.853417)" fill="#f10732"
                    stroke-width="0"/>
            </g>
        </svg>
    </div>
    )
}

export default Loading
import { Routes, Route, useLocation } from "react-router-dom";
import React from "react";
import MainLayout from "../../components/layout/main-layout";
import EditRuleChan from "./edit-rule-chan.page";
import ListRuleChanPage from "./list-rule-chan.page";

export function RuleChanRouter() {

  let localtion = useLocation();
  
  return (
    <MainLayout skip_auth={true} hide_footer={localtion.pathname.includes("edit")}>
      <Routes>
        <Route key={"list-rule-chan"} path={"/"} element={<ListRuleChanPage />} />
        <Route key={"edit-rule-chan"} path={"/:id/edit"} element={<EditRuleChan/>} />
      </Routes>
    </MainLayout>
  );
}

export default RuleChanRouter;

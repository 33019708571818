import { Col, Row, Spin, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import { DeviceLog, TypeDeviceLog } from "src/types";
import { selectFilter, selectListDeviceLog, selectLoading, setFilter } from "../../slice";
import { getListPaginationDeviceLogThunkAction } from "../../thunk";
import FilterLog from "./filter";

const ListTelemetry: React.FC<{
  deviceId: number;
  type: TypeDeviceLog;
  showDevice?: boolean;
  showType?: boolean;
}> = (props) => {
  const filter = useSelector(selectFilter);
  const dispatch = useAppDispatch();

  const list = useAppSelector(selectListDeviceLog);
  const loading = useAppSelector(selectLoading);
  const { deviceId, type} = props

  useEffect(() => {
    dispatch<any>(getListPaginationDeviceLogThunkAction(filter));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    dispatch<any>(setFilter({
      ...filter,
      device_id: deviceId, 
      type,
      limit: 100
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, type])

  return (
    <Row>
      <Col span={24}>
        <FilterLog deviceId={deviceId} type={props.type} />
      </Col>
      <Col span={24}>
        <Spin tip="Loading..." spinning={loading}>
          <Table dataSource={list} size="middle" rowKey={"id"}>
            <Column title="Key" dataIndex="key" key="key" />
            <Column
              title="Value"
              dataIndex="value"
              key="value"
              render={(value: any, row: DeviceLog) => {
                if (row.key === "status") {
                  return row.value === "1" ? "On" : "Off";
                }
                return row.value;
              }}
            />
            {props.showType && (
              <Column width={120} title="Type" dataIndex="type" key="type" />
            )}
            {props.showDevice && (
              <Column
                width={120}
                title="Device Name"
                dataIndex="device_name"
                key="device_name"
                render={(text: any, record: DeviceLog) => {
                  if (!record.device) {
                    return "--";
                  }

                  return record.device.name;
                }}
              />
            )}
            <Column
              width={200}
              title="Time"
              dataIndex="time"
              key="time"
              render={(text: any, record: DeviceLog) => (
                <Time second={true} data={record.time} />
              )}
            />
          </Table>
        </Spin>
      </Col>
    </Row>
  );
};

export default ListTelemetry;

import { Handle, NodeProps, Position } from "reactflow";


const CreateModelRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                Create Model
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="b"
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}

export default CreateModelRule;
import { Alert, Button, Col, Form, Input, Row, Spin } from "antd";
import { useState } from "react";
import {
  RequestStatus,
  ThunkResult,
  useAppDispatch,
} from "src/app/hooks";
import SelectModel from "src/modules/model/components/select-model";
import { createAssignModelForDeviceThunkAction } from "../thunk";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const CreateAssign: React.FC<{
  deviceId: string;
  onClose: (loading: boolean) => void;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, selectMessage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish = async (values: any) => {
    let res: ThunkResult<boolean, any>;
    try {
      setLoading(true);

      res = await dispatch<any>(
        createAssignModelForDeviceThunkAction({
          device_id: props.deviceId,
          model_id: values.model_id,
        })
      );

      setLoading(false);

      if (res.meta.requestStatus === RequestStatus.Fulfilled) {
        props.onClose(true);
      } else {
        selectMessage(res.error.message);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin tip="Proccess..." spinning={loading}>
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row gutter={[10, 10]}>
          {message !== "" && (
            <Col span={24}>
              <Alert type="error" message={message}></Alert>
            </Col>
          )}
          <Col span={24}>
            <SelectModel name="model_id" disabled={false} />
          </Col>

          <Col span={24}>
            <Row justify="center" gutter={[10, 10]}>
              <Col style={{ width: 100 }}>
                <Input size="large" type="submit" value="Add" />
              </Col>
              <Col>
                <Button
                  size="large"
                  style={{ width: 100 }}
                  onClick={() => {
                    props.onClose(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateAssign;

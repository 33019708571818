import { Form, Select } from "antd"

const TypeSource: React.FC<{
    onChange?: (data: string) => void
}> = (props) => {

    return (
        <Form.Item name={"type"} label="Type Source" rules={[{required: true}]}>
            <Select onChange={props.onChange}>
                <Select.Option value={"list"}>List Record</Select.Option>
                <Select.Option value={"last"}>Last Time</Select.Option>
            </Select>
        </Form.Item>
    )
}

export default TypeSource
import { isUndefined } from "lodash";


export class HttpClient {

    public token: string = ""

    async get(url: string) {
        var headers = {
            'Content-Type': 'application/json', // Set the content type to JSON
        } as any
        if (!isUndefined(this.token) && this.token !== "" && this.token !== "undefined") {
            headers["Authorization"] = this.token as any
        }
        const response = await fetch(url, {
            method: 'GET', // Specify that this is a POST request
            headers: headers,
        });
        const result = await response.json();
        return result;
    }

    async post(url: string, payload: any) {
        var headers = {
            'Content-Type': 'application/json', // Set the content type to JSON
        } as any
        if (!isUndefined(this.token) && this.token !== "" && this.token !== "undefined") {
            headers["Authorization"] = this.token as any
        }
        const response = await fetch(url, {
            method: 'POST', // Specify that this is a POST request
            headers: headers,
            body: JSON.stringify(payload), // Convert the payload to JSON format
        });
        const result = await response.json();
        return result;
    }
}
var client = new HttpClient()

export const createHTTPClient = (): HttpClient => {
    let token = localStorage.getItem("token")
    if (token == null) {
      token = ""
    }
    client.token = token;
    return client;
}

export const setTokenHttp = (token: string) => {
    client.token = token;
    console.log("setTokenHttp", client.token , token)
}
  
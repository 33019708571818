import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePropertyDeviceMutationVariables = Types.Exact<{
  id: Types.Scalars['Uint64'];
  deviceId: Types.Scalars['Uint64'];
}>;


export type DeletePropertyDeviceMutation = { __typename?: 'Mutation', deletePropertyDevice: boolean };


export const DeletePropertyDeviceDocument = gql`
    mutation deletePropertyDevice($id: Uint64!, $deviceId: Uint64!) {
  deletePropertyDevice(id: $id, deviceId: $deviceId)
}
    `;
export type DeletePropertyDeviceMutationFn = Apollo.MutationFunction<DeletePropertyDeviceMutation, DeletePropertyDeviceMutationVariables>;

/**
 * __useDeletePropertyDeviceMutation__
 *
 * To run a mutation, you first call `useDeletePropertyDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyDeviceMutation, { data, loading, error }] = useDeletePropertyDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeletePropertyDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyDeviceMutation, DeletePropertyDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePropertyDeviceMutation, DeletePropertyDeviceMutationVariables>(DeletePropertyDeviceDocument, options);
      }
export type DeletePropertyDeviceMutationHookResult = ReturnType<typeof useDeletePropertyDeviceMutation>;
export type DeletePropertyDeviceMutationResult = Apollo.MutationResult<DeletePropertyDeviceMutation>;
export type DeletePropertyDeviceMutationOptions = Apollo.BaseMutationOptions<DeletePropertyDeviceMutation, DeletePropertyDeviceMutationVariables>;
import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { Status, TypeField } from "src/types";
import { ModelFieldInputIndex } from "./fields";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const EditField: React.FC<{
  data: ModelFieldInputIndex | null;
  onClose: () => void;
  onChange: (data: ModelFieldInputIndex) => void;
}> = (props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (props.data == null) {
      form.resetFields();
      form.setFields([
        {
          name: "status",
          value: Status.Active,
        },
      ]);
    } else {
      form.setFieldsValue(props.data);
    }
  }, [props, form]);

  const onFinish = async (values: any) => {
    const dataTime = new Date();
    props.onChange({
      index: props.data?.index ? props.data?.index : dataTime.getTime(),
      id: values.id,
      key: values.key,
      type: values.type,
      description: values.description,
      field_type_id: values.field_type_id,
    });
  };

  return (
    <Form {...layout} onFinish={onFinish} form={form}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Form.Item
            name="key"
            label="Key"
            style={{ margin: 0 }}
            rules={[{ required: true, message: "Please input key!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="description"
            label="Description"
            style={{ margin: 0 }}
            rules={[{ required: true, message: "Please input description!" }]}
          >
            <Input.TextArea size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={"type"}
            label="Type Data"
            style={{ margin: 0 }}
            rules={[{ required: true, message: "Please select type data!" }]}
          >
            <Select size="large">
              <Select.Option value={TypeField.Number}>Number</Select.Option>
              <Select.Option value={TypeField.String}>String</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="center" gutter={[10, 10]}>
            <Col style={{ width: 100 }}>
              <Input size="large" type="submit" value="Save" />
            </Col>

            <Col>
              <Button
                size="large"
                style={{ width: 100 }}
                onClick={() => {
                  props.onClose();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EditField;

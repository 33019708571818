import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFieldDeviceModelMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.DeleteDeviceModelFieldInput>;
}>;


export type DeleteFieldDeviceModelMutation = { __typename?: 'Mutation', deleteFieldDeviceModel: boolean };


export const DeleteFieldDeviceModelDocument = gql`
    mutation deleteFieldDeviceModel($input: DeleteDeviceModelFieldInput) {
  deleteFieldDeviceModel(input: $input)
}
    `;
export type DeleteFieldDeviceModelMutationFn = Apollo.MutationFunction<DeleteFieldDeviceModelMutation, DeleteFieldDeviceModelMutationVariables>;

/**
 * __useDeleteFieldDeviceModelMutation__
 *
 * To run a mutation, you first call `useDeleteFieldDeviceModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldDeviceModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldDeviceModelMutation, { data, loading, error }] = useDeleteFieldDeviceModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFieldDeviceModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldDeviceModelMutation, DeleteFieldDeviceModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldDeviceModelMutation, DeleteFieldDeviceModelMutationVariables>(DeleteFieldDeviceModelDocument, options);
      }
export type DeleteFieldDeviceModelMutationHookResult = ReturnType<typeof useDeleteFieldDeviceModelMutation>;
export type DeleteFieldDeviceModelMutationResult = Apollo.MutationResult<DeleteFieldDeviceModelMutation>;
export type DeleteFieldDeviceModelMutationOptions = Apollo.BaseMutationOptions<DeleteFieldDeviceModelMutation, DeleteFieldDeviceModelMutationVariables>;
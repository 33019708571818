import { Handle, NodeProps, Position } from "reactflow";
import { RuleInfo, RuleOptionProps } from "../rule";
import { Alert, Button, Col, Form, Input, Row, Spin } from "antd";
import { useEffect } from "react";


const ForkRule: React.FC<NodeProps> = (props) => {

    const {  isConnectable } = props

    return (
        <div className="react-flow__node-output node__input_telemetry">
            <Handle
                type="target"
                id="input"
                title="input"
                position={Position.Left}
                style={{ background: 'red' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                Fork
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                title="output"
                style={{ background: 'green' }}
                isConnectable={isConnectable}
            />
        </div>
    )
}


export default ForkRule;





const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const ForkRuleOption : React.FC<RuleOptionProps> = (props) => {

    const [form] = Form.useForm();
    const message = ""

    useEffect(() => {
        form.setFields([
            {
              name: "type",
              value: "Input Model",
            },
            {
                name: "name",
                value: props.label,
            },
          ])
    }, [props,form])

    const onFinish = (values: any) => {
        props.save(props.id, {
            option: {},
            name: values.name,
        })
    }

    const onCancel = () => {
        props.cancel()
    }

    return (
    <Spin tip="Proccess..." spinning={false}>
        <Form {...layout} onFinish={onFinish} form={form}>
            <Row gutter={[10, 10]}>
            {message !== "" && (
                <Col span={24}>
                    <Alert type="error" message={message}></Alert>
                </Col>
            )}
            <Col span={24}>
                <Form.Item name="name" label="Name" style={{ margin: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Row justify="center" gutter={[10, 10]}>
                <Col style={{ width: 100 }}>
                    <Input size="large" type="submit" value="Save" />
                </Col>

                <Col>
                    <Button
                    size="large"
                    style={{ width: 100 }}
                    onClick={onCancel}
                    >
                    Cancel
                    </Button>
                </Col>
                </Row>
            </Col>
            </Row>
        </Form>
    </Spin>
    )
}


export const ForkRuleInfo: RuleInfo = {
    id: "fork",
    name: "Fork",
    rule: ForkRule,
    option: ForkRuleOption,
    infinite: false,
    option_default: {
        operators: {},
    },
}

import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRuleChanMutationVariables = Types.Exact<{
  input: Types.InputRuleChan;
}>;


export type CreateRuleChanMutation = { __typename?: 'Mutation', createRuleChan: boolean };


export const CreateRuleChanDocument = gql`
    mutation createRuleChan($input: InputRuleChan!) {
  createRuleChan(input: $input)
}
    `;
export type CreateRuleChanMutationFn = Apollo.MutationFunction<CreateRuleChanMutation, CreateRuleChanMutationVariables>;

/**
 * __useCreateRuleChanMutation__
 *
 * To run a mutation, you first call `useCreateRuleChanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRuleChanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRuleChanMutation, { data, loading, error }] = useCreateRuleChanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRuleChanMutation(baseOptions?: Apollo.MutationHookOptions<CreateRuleChanMutation, CreateRuleChanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRuleChanMutation, CreateRuleChanMutationVariables>(CreateRuleChanDocument, options);
      }
export type CreateRuleChanMutationHookResult = ReturnType<typeof useCreateRuleChanMutation>;
export type CreateRuleChanMutationResult = Apollo.MutationResult<CreateRuleChanMutation>;
export type CreateRuleChanMutationOptions = Apollo.BaseMutationOptions<CreateRuleChanMutation, CreateRuleChanMutationVariables>;
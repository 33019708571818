import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, Modal, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Time from "src/components/time";
import { Model } from "src/types";
import { selectFilter, selectListModel } from "../slice";
import { getListPaginationModelThunkAction } from "../thunk";

const ListModel: React.FC<{
  onAction: (action: string, item: Model) => void;
  refLoading: (callback: () => void) => void;
}> = (props) => {
  const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);
  const [item, setSelectitem] = useState<any | null>(null);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const listModel = useAppSelector(selectListModel);

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  props.refLoading(() => {
    dispatch<any>(getListPaginationModelThunkAction(filter));
  });

  useEffect(() => {
    dispatch<any>(getListPaginationModelThunkAction(filter));
  }, [dispatch, filter]);

  const onMenu = (info: { key: string }, row: Model) => {
    setSelectitem(row);
    switch (info.key) {
      case "delete": {
        setIsModalVisibleDel(true);
        return;
      }
      default: {
        props.onAction(info.key, row);
      }
    }
  };

  const handleOkDelete = () => {
    if (item != null) {
      props.onAction("delete", item);
    }
    setIsModalVisibleDel(false);
  };

  return (
    <>
      <Card size="small">
        <Table
          dataSource={
            listModel != null && listModel?.data != null
              ? (listModel.data as any[])
              : []
          }
          onChange={onChange}
          size="middle"
          rowKey={"id"}
        >
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            render={(text: any, row: Model) => (
              <Button
                type="link"
                onClick={() => {
                  props.onAction("detail", row);
                }}
              >
                {text}
              </Button>
            )}
          />
          <Column
            width={300}
            title="Created by"
            key="created_by"
            render={(text: any, record: Model) =>
              `${record.created_by_user?.name} <${record.created_by_user?.email}>`
            }
          />
          <Column width={100} title="Status" dataIndex="status" key="status" />
          <Column
            width={200}
            title="Created At"
            dataIndex="created_at"
            key="created_at"
            render={(text: any, record: Model) => <Time data={record.created_at} />}
          />
          <Column
            width={200}
            title="Updated At"
            dataIndex="updated_at"
            key="updated_at"
            render={(text: any, record: Model) => <Time data={record.updated_at} />}
          />
          <Column
            title=""
            key="action"
            width={10}
            render={(text: any, record: any) => (
              <Dropdown
                overlay={
                  <Menu
                    onClick={(info) => {
                      onMenu(info, record);
                    }}
                  >
                    <Menu.Item key="detail" icon={<BarsOutlined />}>
                      Detail
                    </Menu.Item>
                    <Menu.Item key="edit" icon={<EditOutlined />}>
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>
            )}
          />
        </Table>
      </Card>
      <Modal
        title="Delete Model"
        visible={isModalVisibleDel}
        onOk={handleOkDelete}
        onCancel={() => {
          setIsModalVisibleDel(false);
        }}
      >
        <p>
          You do want to delete policy <Tag color={"blue"}>{item?.name}</Tag>!
        </p>
      </Modal>
    </>
  );
};

export default ListModel;

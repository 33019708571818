import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import Column from "antd/lib/table/Column";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { PropertyDeviceDetail, TypeProperty } from "src/types";
import { selectListPropertyDevice } from "../slice";
import { deletePropertyDeviceThunkAction, getPropertyDeviceByIdThunkAction } from "../thunk";
import EditProperty from "./edit";
import ImageProperty from "./properties/image";
import NumberProperty from "./properties/number";
import StringProperty from "./properties/string";

const ListProperty: React.FC<{
  deviceId: any;
}> = (props) => {
  const { deviceId } = props;

  const [property, setProperty] = useState<PropertyDeviceDetail | null>(null);
  const dispatch = useAppDispatch();
  const list = useAppSelector(selectListPropertyDevice);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isModalVisibleDel, setIsModalVisibleDel] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const loadData = () => {
    dispatch<any>(getPropertyDeviceByIdThunkAction(deviceId));
  }

  const onMenu = (info: { key: string }, row: PropertyDeviceDetail) => {
    setProperty(row);
    switch (info.key) {
      case "edit": {
        setIsAdd(false);
        setIsModal(true);
        break;
      }
      case "delete": {
        setIsModalVisibleDel(true);
        break;
      }
    }
  };

  const handleOkDelete = async () => {
    await dispatch<any>(deletePropertyDeviceThunkAction(property?.id));
    loadData();
    setIsModalVisibleDel(false);
  };

  const onAdd = () => {
    setProperty(null)
    setIsAdd(true);
    setIsModal(true);
  };

  return (
    <Row>
      <Col span={24} style={{ textAlign: "right" }}>
        <Button type="primary" onClick={onAdd}>
          Add Property
        </Button>
      </Col>
      <Col span={24}>
        <Table pagination={false} dataSource={list} size="middle" rowKey={"id"}>
          <Column width={200} title="Key" dataIndex="key" key="key" />
          <Column width={200} title="Type" dataIndex="type" key="type" />
          <Column
            width={200}
            title="Value"
            dataIndex="value"
            key="value"
            render={(text: any, record: PropertyDeviceDetail) => {
              if (record.type === TypeProperty.Image) {
                return <ImageProperty view={true} property={record} />;
              }
              if (record.type === TypeProperty.String) {
                return <StringProperty view={true} property={record} />;
              }

              if (record.type === TypeProperty.Number) {
                return <NumberProperty view={true} property={record} />;
              }
            }}
          />
          <Column
            title=""
            key="action"
            width={10}
            render={(text: any, record: any) => (
              <Dropdown
                overlay={
                  <Menu
                    onClick={(info) => {
                      onMenu(info, record);
                    }}
                  >
                    <Menu.Item key="edit" icon={<EditOutlined />}>
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>
            )}
          />
        </Table>
      </Col>

      <Modal
        title="Delete Namespace"
        visible={isModalVisibleDel}
        onOk={handleOkDelete}
        onCancel={() => {
          setIsModalVisibleDel(false);
        }}
      >
        <p>
          You do want to delete property{" "}
          <Tag color={"blue"}>{property?.key}</Tag>!
        </p>
      </Modal>
      <Modal
        visible={isModal}
        title={isAdd ? "Add Property" : "Edit Property"}
        maskClosable={false}
        onCancel={() => {
          setIsModal(false);
        }}
        footer={null}
        closable={false}
      >
        <EditProperty
          onClose={(loading) => {
            if (loading) {
              loadData();
            }
            setIsModal(false);
          }}
          property={property}
          deviceId={props.deviceId}
        />
      </Modal>
    </Row>
  );
};

export default ListProperty;

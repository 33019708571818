import { configureStore } from '@reduxjs/toolkit'
import loginSlice from 'src/modules/login/login.slice'
import deviceSlice from 'src/modules/device/slice'
import namespaceSlice from 'src/modules/namespace/slice'
import modelSlice from 'src/modules/model/slice'
import deviceLogSlice from 'src/modules/device_log/slice'
import devicePropertySlice from 'src/modules/device_property/slice'
import deviceModelSlice from 'src/modules/device_model/slice'
import modelLogSlice from 'src/modules/model_log/slice'
import ruleChanSlice from 'src/modules/rule_chan/slice'
import alertSlice  from 'src/modules/alert/slice'
import uiSlice from 'src/modules/ui/slice'
import widgetSlice from 'src/modules/widget/slice'
import appSlice from './app.slice'
import thunk from 'redux-thunk';
import { createGQLClient, GraphQLClient } from './apollo';
import { createHTTPClient, HttpClient } from './http'

export const store = configureStore({
  reducer: {
    login: loginSlice,
    app: appSlice,
    device: deviceSlice,
    namespace: namespaceSlice,
    model: modelSlice,
    device_log: deviceLogSlice,
    property_device: devicePropertySlice,
    device_model: deviceModelSlice,
    model_log: modelLogSlice,
    ui: uiSlice,
    widget: widgetSlice,
    rule_chan: ruleChanSlice,
    alert: alertSlice,
  },
  devTools:
  /* istanbul ignore next line */
    process.env.NODE_ENV !== "production" || process.env.PUBLIC_URL.length > 0,
    middleware: [
      thunk.withExtraArgument({ 
        client: createGQLClient(),
        http: createHTTPClient()
      })
    ]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type MiddlewareType = { 
  extra: { 
    client: GraphQLClient,
    http: HttpClient,
  }
}